import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useSelector,useDispatch} from 'react-redux'

export default function DialogForm(props) {
    const directions=useSelector(state=> state.settingsData.directions);
    const MainTheme=useSelector(state=> state.settingsData.MainTheme);


  return (
    <Dialog
        open={props.open}
        onClose={() => props.close(false)}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
            color:MainTheme?.paperTextColor,
        }}
        PaperProps={{
            style: {
                width:props?.CustomeWidth?props?.CustomeWidth:'100%',
                height: props.CustomeHeight?props.CustomeHeight:'100%',
                borderRadius: 0,
                backgroundColor: MainTheme?.paperColor,
                color: MainTheme?.paperTextColor,
                boxShadow: '#00000055 0px 6px 12px',
                borderTopLeftRadius: '20px',
                padding:'10px 20px',
                display:"flex",
                // flexDirection:'column',
                // alignItems: 'start',
            },
        }}
        BackdropProps={{
            style: {
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(3px)'
            }
        }}
        
        bgColor={MainTheme?.paperColor} color={MainTheme?.paperTextColor} 
    
    >
      
      {props?.children}
    </Dialog>
  )
}
