import React ,{useState,useEffect} from 'react'

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LanguageIcon from '@mui/icons-material/Language';
import { useSelector, useDispatch } from 'react-redux'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import './Notifications.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import Iraq from '../../../assets/img/iraq.png'
import UnitedState from '../../../assets/img/united-states.png'
import unitedKingdom from '../../../assets/img/united-kingdom.png'


import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useTranslation} from "react-i18next";

import {setSideMenuMode,sideMenuMode,setDirections} from '../../reduxStore/SettingsReducer'


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme,ToolBarTheme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: ToolBarTheme?.paperColor,
    color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      '& .activelanguage':{
        backgroundColor: `${ToolBarTheme?.paperTextColor+'55'} !important`,
      },
      
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      
     
    },
    '& .itmlist:hover':{
      backgroundColor: ToolBarTheme?.paperTextColor+'55',
    }
   
  },
}));

export default function Language({ToolBarTheme}) {
   const directions__= useSelector(state=> state.settingsData.directions)
   const dispatch=useDispatch();
   const [directions,setDirectionNow]=useState(directions__)
   const [t, i18n] = useTranslation('common');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (direction) => {

    dispatch(setDirections(direction))
    if(direction=='ltr')
    i18n.changeLanguage('en')
    else if(direction=='rtl')
    i18n.changeLanguage('ar')

    setAnchorEl(null);
  };

  const handleCloseTwo=()=>{
    setAnchorEl(null);
      
  }
  
  useEffect(()=>{
    setDirectionNow(directions__)
  },[directions__])


  return (
    <div>
     
      <Tooltip  id="demo-customized-button" onClick={()=>handleClick()} arrow title={t('APPBAR.TOOLTIP_LANGUAGE')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom">
        <IconButton  className='Notification' 
        size="small"
        sx={{ borderRadius:2,height:'40px' }}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        >
          <div className="selectedLanguage">
            {
              directions=='rtl'?
               <div className="subSelected">
                  <img src={Iraq} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
                    <p style={{marginRight:directions=='rtl'?5:0}}>AR</p>
               </div>:
               <div className="subSelected">
                 <img src={unitedKingdom} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
                  <p style={{marginRight:directions=='rtl'?5:0}}>EN</p>
               </div>
            
            }
          </div>
          
            {/* <LanguageIcon className='colorNotificationLanguage' size={30}  style={{color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/> */}
          
            </IconButton>
      </Tooltip>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        ToolBarTheme={ToolBarTheme}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseTwo}
      >
        {
        directions!=='rtl'?
        <MenuItem onClick={()=>handleClose('ltr')} disableRipple className={`itmlist ${directions !=='rtl'?'activelanguage ':'languageNormal arabicDirection'}`}>
          <img src={unitedKingdom} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
          English
        </MenuItem>:
        <MenuItem sx={{display: 'flex',justifyContent: 'space-evenly',alignItems: 'center'}} onClick={()=>handleClose('ltr')} disableRipple className={`itmlist ${directions !=='rtl'?'activelanguage ':'languageNormal arabicDirection'}`}>
          <span style={{margin:'0 10px'}}>English</span>
          <img src={unitedKingdom} style={{width:'25px',height:'25px',margin:'0 0 0 0'}}/>
        </MenuItem>
        }

        {
        directions!=='rtl'?
        <MenuItem onClick={()=>handleClose('rtl')} disableRipple className={`itmlist ${directions=='rtl'?'activelanguage arabicDirection':'languageNormal'}`}>
           <img src={Iraq} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
           عربي
        </MenuItem>:
        <MenuItem onClick={()=>handleClose('rtl')} disableRipple className={`itmlist ${directions=='rtl'?'activelanguage arabicDirection':'languageNormal'}`}>
           <span style={{margin:'0 10px'}}>عربي</span>
           <img src={Iraq} style={{width:'25px',height:'25px',margin:'0 0 0 0'}}/>
        </MenuItem>
        }
      </StyledMenu>
    </div>
  )
}
