import React,{useState,useEffect,useMemo,useCallback} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useSelector,useDispatch} from 'react-redux'
import swal from 'sweetalert';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    setSideMenuMode,
    sideMenuMode,
    setDirections,
    setMainPaletteShow,
    setSideMenuPaletteShow,
    setToolBarPaletteShow,
    setGridPaletteShow,
    setMainTheme,
    setSideMenuTheme,
    setToolbarTheme,
    setGridTheme,
    setDisabledGlobalText
  } from '../../reduxStore/SettingsReducer'
import SettingsApplicationsRounded from '@mui/icons-material/SettingsApplicationsRounded';
import './ThemePalette.css'
import PaletteIcon from '@mui/icons-material/Palette';
import { SketchPicker,BlockPicker,HuePicker  } from 'react-color';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';

import {
    ContainerModal,
    HeaderModal,
    ContentModal,
    LeftSide,
    RightSide,
    PrimeryColorForText,
    LineForColor,
    SecondaryColorForText,
    BackgroundPaperColor,
    BackgroundColor,
    PreviewBox,
    LightOrDarkmode,
    LightMode,
    DarkMode,
    TopSectionPreview,
    BackgroundSectionPreivew,
    PaperSectionPreview,
    LeftPaperBox,
    RightPaperBox,
    FooterModal,
    SaveButton,
    CancelButton,
    PreviewSection,
    GloablTextColor,
    BoxOfControllers
    
} from './ThemeStyleComponent'
import { xyz } from '../Source';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme ,MainTheme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:MainTheme?.paperTextColor,
    backgroundColor:MainTheme?.paperColor,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      color:MainTheme?.paperTextColor,
    },
    '& .MuiTypography-root': {
    color:MainTheme?.paperTextColor,
     
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function ThemePalette() {
  const MainPaletteShow= useSelector(state=> state.settingsData.MainPaletteShow)
  const SideMenuPaletteShow= useSelector(state=> state.settingsData.SideMenuPaletteShow)
  const ToolBarPaletteShow= useSelector(state=> state.settingsData.ToolBarPaletteShow)
  const GridPaletteShow= useSelector(state=> state.settingsData.GridPaletteShow)
  const MainTheme_Store= useSelector(state=> state.settingsData.MainTheme)
  const SideMenuTheme_Store= useSelector(state=> state.settingsData.SideMenuTheme)
  const ToolbarTheme_store= useSelector(state=> state.settingsData.ToolbarTheme)
  const GridTheme_store= useSelector(state=> state.settingsData.GridTheme)


  const [changeTextColorWithBG,setChangeTextColorWithBG]=useState(false);
  const [changeTextColorWithPaper,setChangeTextColorWithPaper]=useState(false);

  // const [disabledGlobalTextColor,setDisabledGlobalTextColor]=useState(false);
  const directions= useSelector(state=> state.settingsData.directions)
  const [MainTheme,setMainTheme_settings]=useState({});
  const [darkOrLightColor,setDarkOrLightColor]=useState({});

  
  useMemo(()=>{
      if(MainPaletteShow==true){
        setMainTheme_settings(MainTheme_Store);
        setDarkOrLightColor(MainTheme_Store)
      }else if(SideMenuPaletteShow==true){
        setMainTheme_settings(SideMenuTheme_Store);
        setDarkOrLightColor(SideMenuTheme_Store)
      }else if(GridPaletteShow==true){
        setMainTheme_settings(GridTheme_store);
        setDarkOrLightColor(GridTheme_store)
        // setDisabledGlobalTextColor(GridTheme_store?.gloablTextColor)
      }else if(ToolBarPaletteShow==true){
        setMainTheme_settings(ToolbarTheme_store);
        setDarkOrLightColor(ToolbarTheme_store)
        // setDisabledGlobalTextColor(GridTheme_store?.gloablTextColor)
      }

  },[
    MainTheme_Store,
    SideMenuTheme_Store,
    MainPaletteShow,
    SideMenuPaletteShow,
    ToolbarTheme_store,
    ToolBarPaletteShow,
    GridPaletteShow,
    GridTheme_store,
  ])


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_settings = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose_settings = (dire) => {
    setAnchorEl(null);
  };
  // initiateSettingsFromStore();
  // useEffect()


  const [BgMessageColor,setBgMessageColor]=useState('')
  const [PaperMessageColor,setPaperMessageColor]=useState('')

 
  const [openPrimaryColor,setOpenPrimaryColor]=useState(false);

  const dispatch=useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);


  const handleClose = () => {
     if(MainPaletteShow){
        dispatch(setMainPaletteShow(false))
     }else if(SideMenuPaletteShow){
        dispatch(setSideMenuPaletteShow(false))
     }else if(ToolBarPaletteShow){
        dispatch(setToolBarPaletteShow(false))
     }else if(GridPaletteShow){
        dispatch(setGridPaletteShow(false))
     }
  };

  const OpenColorPicker=(e)=>{

    var x;
    var y;

    x = e.pageX;
    y = e.pageY;


    console.log(e);
    e.target.parentElement.querySelector('.colorPicker').style.position = "absolute";
    e.target.parentElement.querySelector('.colorPicker').setAttribute('left',`left:${30}px;`);
    e.target.parentElement.querySelector('.colorPicker').top= e.target.style.top;

    e.target.parentElement.querySelector('.colorPicker').click();
     
  }

  const checkDarknessOrLightnessForColor=(color)=>{
      // Variables for red, green, blue values
      var r, g, b, hsp;
    
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
  
          // If RGB --> store the red, green, blue values in separate variables
          color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
          
          r = color[1];
          g = color[2];
          b = color[3];
      } 
      else {
          
          // If hex --> Convert it to RGB: http://gist.github.com/983661
          color = +("0x" + color.slice(1).replace( 
          color.length < 5 && /./g, '$&$&'));
  
          r = color >> 16;
          g = color >> 8 & 255;
          b = color & 255;
      }
      
      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
      );
  
      // Using the HSP value, determine whether the color is light or dark
      if (hsp>127.5) {
  
          return false;//light
      } 
      else {
  
          return true;//dark
      }

  };
  
  const setThemeOfSelectedPalette=(e,name)=>{
      let oppositePrimaryColor=MainTheme?.oppositePrimaryColor;
      let oppositeSecondaryColor=MainTheme?.oppositeSecondaryColor;
      let bgTextColor=MainTheme?.bgTextColor;
      let paperTextColor=MainTheme?.paperTextColor;
      let gloablTextColor=MainTheme?.gloablTextColor;

      // let gloablTextColor=MainTheme?.gloablTextColor;
      let check=checkDarknessOrLightnessForColor(e.target.value);
      setBgMessageColor('')
      setPaperMessageColor('');

      if(name ==='primaryColor'){
          oppositePrimaryColor=check?'#ffffff':'#000000';
      }else if(name=== 'secondaryColor'){
          oppositeSecondaryColor=check?'#ffffff':'#000000';
      }else if(name=== 'paperColor'){
          if(check&&MainTheme?.light){
            setPaperMessageColor(directions=='rtl'?'يجب ان يكون اللون ابيض':'must be light  color');
          }else if(!check&&MainTheme?.dark){
            setPaperMessageColor(directions=='rtl'?'يجب ان يكون اللون اسود':'must be dark color');
          }
      }else if(name=== 'bgColor'){
        if(check&&MainTheme?.light){
          setBgMessageColor(directions=='rtl'?'يجب ان يكون اللون ابيض':'must be light  color');
        }else if(!check&&MainTheme?.dark){
          setBgMessageColor(directions=='rtl'?'يجب ان يكون اللون اسود':'must be dark color');
        }
      }else if(name=='gloablTextColor'){
        gloablTextColor=e.target.value;
      }
      
      setMainTheme_settings({
        ...MainTheme,
        [name]:e.target.value,
        oppositePrimaryColor:oppositePrimaryColor,
        oppositeSecondaryColor:oppositeSecondaryColor,
        gloablTextColor:gloablTextColor,
      })
  }

  const choiceDarkOrLightTheme=(Boolean,name1,name2)=>{
    let bgTextColor=MainTheme?.bgTextColor;
    let paperTextColor=MainTheme?.paperTextColor;
    let paperColor=MainTheme?.paperColor;
    let bgColor=MainTheme?.bgColor;

     if(name1=='light'){
      bgTextColor="#000000";
      paperTextColor="#000000";
      paperColor="#ffffff";
      bgColor="#ffffff";
     }else{
      bgTextColor="#ffffff";
      paperTextColor="#ffffff";
      paperColor="#000000";
      bgColor="#000000";
     }

    // setMainTheme_settings({
    //   ...MainTheme,
    //   [name1]:Boolean,
    //   [name2]:false,
    //   bgTextColor:bgTextColor,
    //   paperTextColor:paperTextColor,
    //   paperColor:paperColor,
    //   bgColor:bgColor,

    // })
  if(MainPaletteShow){
    dispatch(setMainTheme({
      ...MainTheme,
      [name1]:Boolean,
      [name2]:false,
      bgTextColor:bgTextColor,
      paperTextColor:paperTextColor,
      paperColor:paperColor,
      bgColor:bgColor,
    }))
  }else if(SideMenuPaletteShow){
    dispatch(setSideMenuTheme({
      ...MainTheme,
      [name1]:Boolean,
      [name2]:false,
      bgTextColor:bgTextColor,
      paperTextColor:paperTextColor,
      paperColor:paperColor,
      bgColor:bgColor,
    }))
  }else if(GridPaletteShow){
    dispatch(setGridTheme({
      ...MainTheme,
      [name1]:Boolean,
      [name2]:false,
      bgTextColor:bgTextColor,
      paperTextColor:paperTextColor,
      paperColor:paperColor,
      bgColor:bgColor,
    }))
  }else if(ToolBarPaletteShow){
    dispatch(setToolbarTheme({
      ...MainTheme,
      [name1]:Boolean,
      [name2]:false,
      bgTextColor:bgTextColor,
      paperTextColor:paperTextColor,
      paperColor:paperColor,
      bgColor:bgColor,
    }))
  }
    
  }

  const saveSettingsTheme=()=>{
    console.log('done',MainTheme);
    if(!PaperMessageColor&&!BgMessageColor){
      if(MainPaletteShow){
        dispatch(setMainTheme(MainTheme))
      }else if(SideMenuPaletteShow){
        dispatch(setSideMenuTheme(MainTheme))
      }else if(GridPaletteShow){
        dispatch(setGridTheme(MainTheme))
      }else if(ToolBarPaletteShow){
        dispatch(setToolbarTheme(MainTheme))
      }
    }
    handleClose()
  }

  const disabledGlobalTextColor_set=(e)=>{
    // setDisabledGlobalTextColor(e.target.checked)
    if(GridPaletteShow){
      dispatch(setGridTheme({
        ...MainTheme,
        disabledGlobalText:e.target.checked
      }))
    }else if(ToolBarPaletteShow){
      dispatch(setToolbarTheme({
        ...MainTheme,
        disabledGlobalText:e.target.checked
      }))
    }
  }

 

  return (
    <div>
        <Modal
            open={MainPaletteShow||SideMenuPaletteShow||ToolBarPaletteShow||GridPaletteShow}
            onClose={()=>handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{display: "flex",justifyContent: "center",alignItems: "center",border:'none',backgroundColor:'transparent'}}
        >
            <ContainerModal bgColor={MainTheme_Store?.paperColor} color={MainTheme_Store?.paperTextColor}>
                <HeaderModal bgColor={MainTheme_Store?.primaryColor} color={MainTheme_Store?.oppositePrimaryColor}>
                        <div>
                          <PaletteIcon className="iconePlate" bgColor={MainTheme_Store?.primaryColor} color={MainTheme_Store?.oppositePrimaryColor}/>
                          <span>Edit Palette</span>
                        </div>
                        {
                         GridPaletteShow||ToolBarPaletteShow?
                         <SettingsIcon className="settings" onClick={handleClick}/>
                         :null
                        }
                        {
                          GridPaletteShow||ToolBarPaletteShow?
                          <StyledMenu
                          MainTheme={MainTheme_Store}
                          id="demo-customized-menu"
                          MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                          }}
                          anchorEl={anchorEl}
                          open={open_settings}
                          onClose={handleClose_settings}
                        >
                        
                          <MenuItem sx={{color: MainTheme_Store?.paperTextColor}} onClick={()=>handleClose_settings()} disableRipple className={`${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
                          
                            <FormControlLabel
                              style={{color: MainTheme_Store?.paperTextColor}}
                              labelStyle={{color: MainTheme_Store?.paperTextColor}}
                              label="Allow Global Text Color"
                              control={
                                <Checkbox
                                  labelStyle={{color: MainTheme_Store?.paperTextColor}}
                                  checked={MainTheme?.disabledGlobalText}
                                  onChange={disabledGlobalTextColor_set}
                                  sx={{color: MainTheme_Store?.paperTextColor,}}
                                />
                              }
                            />
                          
                          </MenuItem>

                          </StyledMenu>
                        :null
                        }
                </HeaderModal>
                
                <LightOrDarkmode directions={directions}>
                    <LightMode directions={directions} bgColor={darkOrLightColor.light?MainTheme_Store?.secondaryColor:"#ffffff"} color={darkOrLightColor.light?MainTheme_Store?.oppositeSecondaryColor:"#4F46E5"} onClick={()=>choiceDarkOrLightTheme(true,'light','dark')} >Light</LightMode>
                    <DarkMode directions={directions}  bgColor={darkOrLightColor.dark?MainTheme_Store?.secondaryColor:"#ffffff"} color={darkOrLightColor.dark?MainTheme_Store?.oppositeSecondaryColor:"#4F46E5"} onClick={()=>choiceDarkOrLightTheme(true,'dark','light')}>Dark</DarkMode>
                </LightOrDarkmode>
                
                <ContentModal>
                         
                       <LeftSide>
                             {
                              GridPaletteShow||ToolBarPaletteShow?
                               <GloablTextColor
                                type="color" 
                                bgColor={MainTheme_Store?.paperColor}
                                color={MainTheme_Store?.paperTextColor}
                                value={MainTheme?.gloablTextColor||"#fff"}
                                onChange={(e)=>setThemeOfSelectedPalette(e,'gloablTextColor')}
                                disabled={!MainTheme?.disabledGlobalText}
                                disabeldSection={!MainTheme?.disabledGlobalText}
                               >

                               </GloablTextColor>
                              :null
                             }
                            <PrimeryColorForText 
                               type="color" 
                               bgColor={MainTheme_Store?.paperColor}
                               color={MainTheme_Store?.paperTextColor}
                               value={MainTheme?.primaryColor||"#fff"}
                               onChange={(e)=>setThemeOfSelectedPalette(e,'primaryColor')}
                            ></PrimeryColorForText>
                            <SecondaryColorForText
                               type="color" 
                               bgColor={MainTheme_Store?.paperColor}
                               color={MainTheme_Store?.paperTextColor}
                               value={MainTheme?.secondaryColor||"#fff"}
                               onChange={(e)=>setThemeOfSelectedPalette(e,'secondaryColor')}
                            ></SecondaryColorForText>
                            <div>
                                  <BackgroundPaperColor 
                                      type="color" 
                                      bgColor={MainTheme_Store?.paperColor}
                                      color={MainTheme_Store?.paperTextColor}
                                      value={MainTheme?.paperColor||"#fff"}
                                      borderColor={PaperMessageColor?"#f00":MainTheme_Store?.paperTextColor}
                                      messageError={PaperMessageColor}
                                    onChange={(e)=>setThemeOfSelectedPalette(e,'paperColor')}
                                  ></BackgroundPaperColor>
                                  <p style={{color:'#f00',position:'relative',top:'-5px',display:'flex',justifyContent:directions=='rtl'?'start':"end",left:'-10px'}}>{PaperMessageColor}</p>
                            </div>
                            <div>
                                  <BackgroundColor 
                                    type="color" 
                                    bgColor={MainTheme_Store?.paperColor}
                                    color={MainTheme_Store?.paperTextColor}
                                    value={MainTheme?.bgColor||"#fff"}
                                    borderColor={BgMessageColor?"#f00":MainTheme_Store?.paperTextColor}
                                    messageError={BgMessageColor}
                                    onChange={(e)=>setThemeOfSelectedPalette(e,'bgColor')}
                                  ></BackgroundColor>
                                  <p style={{color:'#f00',position:'relative',top:'-5px',justifyContent:directions=='rtl'?'start':"end",left:'-10px'}}>{BgMessageColor}</p>
                            </div>
                       </LeftSide>

                       <rightSide>
                            <PreviewSection  color={MainTheme_Store?.paperTextColor}>Preview</PreviewSection>
                           <PreviewBox bgColor={MainTheme_Store?.paperColor} color={MainTheme_Store?.paperTextColor}>
                                <TopSectionPreview bgColor={MainTheme?.primaryColor} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositePrimaryColor}>
                                      <p>Header (Primary)</p>
                                </TopSectionPreview>
                                <BackgroundSectionPreivew bgColor={MainTheme?.bgColor} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.bgTextColor} >
                                     <p style={{position: 'absolute',bottom:'1%',left:'3%'}}>background</p>
                                </BackgroundSectionPreivew>
                                <PaperSectionPreview >
                                       <LeftPaperBox bgColor={MainTheme?.paperColor} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor}>
                                          <p>paper</p>
                                       </LeftPaperBox >
                                       <RightPaperBox bgColor={MainTheme?.secondaryColor} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositeSecondaryColor}>s</RightPaperBox>
                                </PaperSectionPreview>
                           </PreviewBox>

                       </rightSide>
                </ContentModal>

                <FooterModal>
                    <CancelButton directions={directions} bgColor={MainTheme_Store.primaryColor} color={MainTheme_Store.oppositePrimaryColor} onClick={handleClose}>
                            <span>Cancel</span>
                    </CancelButton>
                    <SaveButton directions={directions} disabled={PaperMessageColor||BgMessageColor?true:false} bgColor={MainTheme_Store.primaryColor} color={MainTheme_Store.oppositePrimaryColor} onClick={()=>saveSettingsTheme()}>
                        <span>Save</span>
                    </SaveButton>
                    
                </FooterModal>

            </ContainerModal>
        </Modal>
      </div>
  );
}