
import React, { useEffect, useState } from 'react';
// import Pagination from "react-js-pagination";
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TelegramIcon from '@mui/icons-material/Telegram'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { toast } from 'react-toastify';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import Grid from '../Share/Grid'
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
function FollowupReport() {
    const {t, i18n} = useTranslation('common');

    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)


    const directions= useSelector(state => state.settingsData.directions)
    const GridTheme= useSelector(state => state.settingsData.GridTheme)
     
    const { loading, setLoading, fromToDate, exportToCSV, user, users, setUser, visitFollowupsReport, clearPointsAdvanceSearchFields, fetchData, exportFollowupToCSV } = useGlobalContext()

    const [accExpansion, setAccExpansion] = useState(false)
    const [accExpansionMeta, setAccExpansionMeta] = useState(false)

    const handleExpansion = (expansion) => setAccExpansion(expansion)
    const handleExpansionMeta = (expansionMeta) => setAccExpansionMeta(expansionMeta)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const advanceSearch = async (type = "BROWSING") => {
        console.log("tracking", 6);
        console.log("tracking user", user.id);
        console.log("user......");
        const fetchedData = await fetchData('VISIT_FOLLOWUPS_REPORT', 'visit_followups_report', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            created_by: user.id,
            creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
            creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        })
        return fetchedData
    }



    useEffect(() => {
        fetchData('USERS', 'users_list')
        advanceSearch()
    }, [pageSize, pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {

        if (visitFollowupsReport && visitFollowupsReport?.data && visitFollowupsReport?.data?.length > 0) {
            const reformatVisits = visitFollowupsReport?.data?.map((rowVisit) => {
                return {
                    ...rowVisit,
                }
            })
            setRows(reformatVisits);

            setPointsTotal(visitFollowupsReport.total)
            setPointsNumberOfPages(visitFollowupsReport.last_page)
        }
        else {
            setRows([]);

            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [visitFollowupsReport])
    
    const [rows, setRows] = useState([])
    const columns = [
        { field: 'id', headerName:t('FOLLOWUP_REPORT.ID'), width: 50 },
        {
            field: 'salesman', headerName: t('FOLLOWUP_REPORT.SALESMAN'), width: 200,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.visit?.created_by?.full_name}
                </div>
            )
        },
        {
            field: 'customer', headerName: t('FOLLOWUP_REPORT.CUSTOMER'), width: 150,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.visit?.point?.name}
                </div>
            )
        },
        {
            field: 'phone', headerName:t('FOLLOWUP_REPORT.PHONE'), width: 150,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.visit?.point?.phone}
                </div>)
        },
        {
            field: 'visit_total_time', headerName:t('FOLLOWUP_REPORT.START_DATE'), width: 150,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.visit?.start_date}
                </div>
            )
        },
        {
            field: 'status', headerName: t('FOLLOWUP_REPORT.STATUS'), width: 200,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >

                    {rows?.row?.followup_status?.name}
                </div>
            )
        },
        {
            field: 'note', headerName: t('FOLLOWUP_REPORT.NOTE'), width: 200,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.note}
                </div>
            )
        },


    ];




    return (
        <>
            <Box display="flex" >
                {rows?.length > 0 ?
                    <>
                        <Button display="flex"
                            startIcon={<FileDownloadIcon sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor}}/>}
                            variant="contained"
                            sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
                            onClick={async () => {
                                const fetchedData = await fetchData('VISIT_FOLLOWUPS_REPORT', 'visit_followups_report', {
                                    page_size: pointsTotal,
                                    created_by: user.id,
                                    creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
                                    creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
                                })
                                const visitFollowupsArray = fetchedData?.data?.map((followup, index) => {

                                    return {
                                        "Salesname": followup?.visit?.created_by?.full_name,
                                        "Customer": followup?.visit?.point?.name,
                                        "Phone": followup?.visit?.point?.phone,
                                        "Start Date": followup?.visit?.start_date,
                                        "Status": followup?.followup_status?.name,
                                        "Note": followup?.note,
                                        "Date": followup?.created_at,
                                        "By": followup?.created_by?.full_name
                                    }
                                })

                                exportToCSV(visitFollowupsArray, 'visit_followups_report')

                            }
                            }>
                            {t('GLOBAL.SEARCH_EXPORT_BUTTON')}
                        </Button>

                    </>
                    : null}
                <Accordion display="flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor}}
                    onChange={(event, expansion) => handleExpansion(expansion)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }} >
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >

                                <BasicDatePicker />

                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button
                                        variant="contained"
                                        spacing={2} sx={{ flexGrow: 1, margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            } }}
                                        onClick={() => advanceSearch("SEARCH")} >
                                    {t('GLOBAL.SEARCH_SEARCH_BUTTON')}

                                    </Button>
                                    <Button variant="contained"
                                        spacing={2}
                                        sx={{ flexGrow: 1, margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            } }}
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                    {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    fullWidth
                                    options={users}
                                    getOptionLabel={(option) => option.full_name || ""}
                                    id="disable-close-on-select"
                                    value={user}
                                    onChange={(e, v, r) => { setUser(v ? v : ''); }}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                         label={t('GLOBAL.SEARCH_USERS')}
                                          variant="outlined" />
                                    )}
                                />

                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={pointsTotal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={false}
              createObject={null}
              title_en=""
              title_ar=""
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />
        </>
    );
}

export default FollowupReport;


