import React, { useState, useRef, useEffect } from 'react';

import GoogleMapReact from 'google-map-react';


const AnyReactComponent = (props) => {
  return (
    <>
      <div style={{
        border: '5px solid ',
        borderColor: props?.marker?.specialColor ? props?.marker?.specialColor : '#f44336',
        borderRadius: 40,
        backgroundColor: 'white',
        textAlign: 'center',
        color: '#3f51b5',
        fontSize: 8,
        fontWeight: 'bold',
        padding: 4,

        display: 'inline-flex',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        ...(props?.marker?.hover && {
          border: '5px solid ',
          borderColor: "#3f51b5",
          color: '#f44336'
        })
      }} >
        {props?.marker?.label}
      </div>
      {props?.marker?.show && <InfoWindow deletable={props?.deletable} onDeleteClicked={()=>props?.onDeleteClicked(props?.marker)} marker={props?.marker} />}
    </>
  )
};
const InfoWindow = (props) => {

  const infoWindowStyle = {
    position: 'relative',
    bottom: '-5px',
    left: '-80px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        {props?.deletable && (
          <button type='button' onClick={() => {
            props?.onDeleteClicked()
          }}>Delete</button>

        )}
        {props?.marker?.label &&
          <div style={{ fontSize: 16 }}>
            {props?.marker?.label}
          </div>
        }
        <div style={{ fontSize: 16 }}>
          {props?.marker?.name}
        </div>
        <div style={{ fontSize: 14 }}>
          <span style={{ color: 'grey' }}>
            {props?.marker?.owner_name}
            {' '}
          </span>

        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          {props?.marker?.point_type?.name}
        </div>
      </div>


    </div>
  );
};

const GoogleMap = (props) => {

  const gMap = useRef();
  const gMaps = useRef();

  const [polygon, setPolygon] = useState(null)

  const [draggable, setDraggable] = useState(true)

  const MapRef = useRef(null);

  const [markers, setMarkers] = useState(props?.markers && props?.markers?.length > 0 ? props?.markers : [])
  const defaultProps = {
    center: { lat: 33.33, lng: 44.44 },
    zoom: 11
  };

  const onChildClick = (hoverKey, childProps) => {

    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.map(marker => {

        if (marker?.id === childProps?.marker?.id) {
          //console.log("childProps in", marker?.id);
          return {
            ...marker,
            show: !marker?.show
          }
        }
        else {
          return {
            ...marker,
            show: false
          }
        }
      })
    }

    setMarkers(tempMarkers)
  }
  const onChildMouseLeave = (hoverKey, childProps) => {
    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.map(marker => {


        return {
          ...marker,
          hover: false

        }

      })
    }

    setMarkers(tempMarkers)
  }
  const onChildMouseEnter = (hoverKey, childProps) => {
    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.map(marker => {

        if (marker?.id === childProps?.marker?.id) {

          return {
            ...marker,
            hover: true
          }
        }
        else {
          return {
            ...marker,
            hover: false
          }
        }
      })
    }
    setMarkers(tempMarkers)
  }
  const onChildMouseDown = (hoverKey, childProps) => {
    setDraggable(false)
  }
  const onChildMouseUp = (hoverKey, childProps) => {
    setDraggable(true)
  }
  const onChildMouseMove = (hoverKey, childProps, mouse) => {
    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.map(marker => {

        if (marker?.id === childProps?.marker?.id) {

          return {
            ...marker,
            latitude: mouse?.lat,
            longitude: mouse?.lng
          }
        }
        else {
          return marker

        }
      })
    }
    props?.setMarkers(tempMarkers)
    setMarkers(tempMarkers)
  }

  const onClick = (clickEventValue) => {
    const opendMarker = markers?.filter((marker) => marker?.show)
    console.log("clicked", opendMarker);

    if (opendMarker?.length > 0) {
      return
    }
    console.log("clicked 2");

    props?.onClick && props?.onClick(clickEventValue)
  }
  useEffect(() => {
    //console.log("polygon", props?.polygon);
    props?.markers?.length > 0 && setMarkers(props?.markers)

    return () => {

    }
  }, [props?.markers])


  useEffect(() => {
    if (polygon) {
      //console.log(polygon);
      polygon?.setMap(null);
      let poly = new window.google.maps.Polygon({
        paths: props?.polygon?.paths,
        strokeColor: props?.polygon?.strokeColor ? props?.polygon?.strokeColor : "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: props?.polygon?.fillColor ? props?.polygon?.fillColor : "#FF0000",
        fillOpacity: 0.35
      });
      poly.setMap(gMap?.current);
      setPolygon(poly)
    }

    return () => {

    }
  }, [props?.polygon?.paths])


  const onDeleteClicked = (currentMarker) => {
    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.filter(marker => marker?.id !== currentMarker?.id)
    }
    props?.setMarkers(tempMarkers)
  }

  return (

    <GoogleMapReact
      ref={MapRef}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      layerTypes={[
        // 'TrafficLayer',
        'TransitLayer'
      ]}
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_API_KEY,
        language: 'ar',
        region: 'iq',
        libraries: ['places'],
        // ...otherUrlParams,
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        gMap.current = map;
        gMaps.current = maps;
        //console.log("polygon object out", props);
        if (props?.polygon?.paths?.length > 0 || props?.createPoly) {
          // //console.log("polygon object in path", props?.polygon?.paths);
          // const triangleCoords = [
          //   { lat: 25.774, lng: -80.19 },
          //   { lat: 18.466, lng: -66.118 },
          //   { lat: 32.321, lng: -64.757 },
          //   { lat: 25.774, lng: -80.19 }
          // ];
          // //console.log("polygon object in triangle", triangleCoords);

          let poly = new window.google.maps.Polygon({
            paths: props?.polygon?.paths,
            strokeColor: props?.polygon?.strokeColor ? props?.polygon?.strokeColor : "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: props?.polygon?.fillColor ? props?.polygon?.fillColor : "#FF0000",
            fillOpacity: 0.35
          });
          poly.setMap(map);
          setPolygon(poly)
          if (props?.polygon?.paths?.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();

            props?.polygon?.paths?.map((item, index) => {
              const location = new window.google.maps.LatLng(
                item.lat,
                item.lng
              );
              bounds.extend(location);
            });
            map.fitBounds(bounds)
          }

        }

        if (markers && markers?.length > 0) {
          const bounds = new window.google.maps.LatLngBounds();

          markers?.map((marker, index) => {
            const location = new window.google.maps.LatLng(
              marker.latitude,
              marker.longitude
            );
            bounds.extend(location);
          });
          map.fitBounds(bounds)
        }


      }}
      onChildClick={onChildClick}
      onChildMouseEnter={onChildMouseEnter}
      onChildMouseLeave={onChildMouseLeave}
      onChildMouseDown={props?.draggable && onChildMouseDown}
      onChildMouseUp={props?.draggable && onChildMouseUp}
      onChildMouseMove={props?.draggable && onChildMouseMove}
      draggable={draggable && props?.draggable}
      onClick={onClick}
    >
      {markers && markers?.length > 0 && markers?.map((marker, index) => {

        return <AnyReactComponent
          key={index + "_" + marker?.name}
          lat={marker?.latitude}
          lng={marker?.longitude}
          marker={marker}
          deletable={props?.deletable}
          onDeleteClicked={onDeleteClicked}
          text={marker?.name}
        />
      })}

    </GoogleMapReact>
  );

}

export default GoogleMap;
