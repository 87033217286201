import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  translation_en: {
    GLOBAL: {
      CREATE_HEADER_BUTTON_TEXT: "Create New",
      PAGINATION_PAGE_NUMBER: "page",
      GRID_ROW_PER_PAGE: "Row Per Page",
      POPUP_SAVE_BUTTON: "save",
      POPUP_CANCEL_BUTTON: "cancel",
      SEARCH_TITLE: "Advanced Search",
      SEARCH_EXPORT_BUTTON: "Export",
      SEARCH_IMPORT_BUTTON: "Import",
      SEARCH_MAP_BUTTON: "Map",
      SEARCH_CREATE_BUTTON: "Create",

      SEARCH_SEARCH_BUTTON: "Search",
      SEARCH_CLEAR_BUTTON: "Clear",
      SEARCH_FROM_DATE: "From date",
      SEARCH_TO_DATE: "To datw",
      SEARCH_REGION: "Region ",
      SEARCH_DISTRICTS: "Districts",
      SEARCH_OWNER_NAME: "Owner Name",
      SEARCH_POINT_STATUS: "Point Status",
      SEARCH_MAX_DISTANCE: "Max Distance(meter)",
      SEARCH_MIN_DISTANCE: "Min Distance(meter)",
      SEARCH_SEND_BUTTON: "Sent",

      SEARCH_USERS: "Users",
      SEARCH_HAS_PHONE: "Has Phone",
      SEARCH_HAS_FOLLOWUP: "Has Followup",
      SEARCH_SHOPE_NAME: "Shop Name",
      SEARCH_TO_WORD: "To",
      SEARCH_REQUEST_STATUS: "Request Status",

      MAPS_VIEW_POINT: "View Point",
      MAPS_VIEW_POINT_REQUEST: "View Point Request",
      MAPS_VIEW_VISIT_FOR_POINT: "View Visit For Point",
      MAPS_VIEW_ALL_VISIT_AND_POINT: "View All Visit And Point",

      IMPORT_FORM_TITLE: "Import Point",
      IMPORT_FORM_TITLE_WITH_GEOFENCE: "Import Regions With Geofence",

      IMPORT_FORM_MESSAGES: "!This input supports drag and drop file.",
      IMPORT_FORM_UPLOAD_BUTTON: "Upload",
      IMPORT_FORM_CHOOSE_FILE_BUTTON: "Choose File",
      IMPORT_FORM_MESSAGE_NOT_SELECTED_FILE: "No File Selected",

      GRID_NO_DATA: "No Data Available",
      GRID_FOOTER_SAVE_BUTTON: "Save",


    },
    USERS: {
      UNIQUE_HEADER_TITLE:'Grid content',
      ID: "ID",
      FULL_NAME: "Full Name",
      USER_NAME: "User Name",
      PHONE: "Phone",
      DEPARTMENT: "Department",
      ACTIVE: "Active",
      ACTIONS: "Actions",

      UNIQUE_HEADER_TITLE:'form Add content',
      HEADER_TITLE: "User List",
      USER_ADD_FORM: "Add User",
      USER_EDIT_FORM: "Edit User Data",
      FORM_FULLNAME: "Full Name",
      FORM_PHONE: "Phone",
      FORM_USERNAME: "UserName",
      FORM_DEPARTMENT: "Department",
      FORM_PASSWORD: "Password",
      FORM_COMPANY:'Company',
      FORM_SUBMIT:'Submit',
      FORM_SELECT_DEPARTMENT:'Select Department',
      FORM_SELECT_COMPANY:'Select Company',
      USER_ROLES: "User Roles",

    },
    VISITS: {
      ID: "ID",
      NAME: "Point Name",
      PHONE: "Phone",
      USER: "User",
      START_END_DATE: "Start End Date",
      IS_CANCELL: "Is Cancell",
      REASON: "Cancelation Reason",
      NOTE: "Not",
      FOLLOWUPS: "Followups",
      ACTIONS: "Actions",
      VISITED_BY: "Visited By",
      FORMVIEW_TITLE: "Visit Info",
      FORMVIEW_TABLE_NAME: "Name",
      FORMVIEW_TABLE_VALUE: "Value",
      FORMVIEW_TABLE_THERES_NO_VALUES: "There aren't any survey values",
      FORMVIEW_ADD_VISIT_FOLLOWUP: "Add Visits Follow-UP",
      FORMVIEW_SEARCH_SAVE_BUTTON: "Save",
      FORMVIEW_SEARCH_FOLLOWUP_NOTE: "Follow-UP Note...",
      FORMVIEW_SEARCH_STATUS: "Status",
      FORMVIEW_SEARCH_LOADING_BUTTON: "loading...",

      FORMVIEW_GRID_ID: "form view ID",
      FORMVIEW_GRID_STATUS: "form view Name",
      FORMVIEW_GRID_NOTE: "form view Note",
    },
    POINTS: {
      ID: "ID",
      DISTRICT: "District",
      REGION: "Region",
      NAME: "Name",
      OWNER_DETAILS: "Owner Details",
      OWNER_TYPE: "Owner Type",
      OWNER_NAME: "Owner Name",
      ACTIONS: "Actions",
      PHONE: "Phone",
      FORMvIEW_FIELD_NAME: "Field Name",
      FORMVIEW_POINT_NAME: "Point Name",
      FORMVIEW_NEAR_BY: "Near By",
      FORMVIEW_LOCATION_CATEGORY: "Location Category",
      FORMVIEW_CLASSIFICATION: "Classification",
      FORMVIEW_CATEGORY: "Category",
      FORMVIEW_POINT_TYPE: "Point Type",
      FORMVIEW_REGION: "Region",
      FORMVIEW_LATITUDE: "Latitude",
      FORMVIEW_LONGITUDE: "Longitude",
      FORMVIEW_HAS_DELIVERY: "Has Delivery",
      FORMVIEW_IMAGE: "Image",
      FORMVIEW_MESSAGE_NO_IMAGE: "No Image Selected",

      FORMADD_TITLE: "Add Point",
      FORMADD_EXTERNAL_CUSTOMER_ID: "External Customer ID",
      FORMADD_SELECT_OWNER_TYPE: "Select Owner Type",
      FROMADD_SELECT_REGION: "Select Region",
      FROMADD_SELECT_CATEGORY: "Select Category",
      FROMADD_SELECT_LOCATION_CATEGORY: "Select Location Category",
      FORMADD_SELECT_CLASS: "Select Class",
      FROMADD_STATUS: "Type(Status)",
      FROMADD_SELECT_STATUS: "Select Type (status)",
      FROMADD_SUBTITLE_MESSAGE: "Select a point location on the map",
      FROMADD_SUBMIT_BUTTON: "Submit",
    },
    POINTS_REQUESTS: {
      ID: "ID",
      LOCATION: "Location",
      USER: "User",
      NAME: "Name",
      OWNER_DETAILS: "Owner details",
      REQUEST_DATA: "Request data",
      STATUS: "Status",
      ACTIONS: "Actions",
      FORMVIEW_FIELD_NAME: "Field name",
      FORMVIEW_REQUEST_CHANGES: "Request changes",
      FORMVIEW_ORIGINAL_POINT: "Original point",
      FORMVIEW_APPROVE_OR_REJECT: "Approve/reject",
      OWNER_TYPE: "Owner Type",
      PHONE: "Phone",
      FORMVIEW_NEAR_BY: "Near By",
      FORMVIEW_LOCATION_CATEGORY: "Location Category",
      FORMVIEW_CLASSIFICATION: "Classification",
      FORMVIEW_CATEGORY: "Category",
      FORMVIEW_IMAGE: "Image",
      FORMVIEW_MESSAGE_SELECT_REQUEST_STATUS: "Select Request Status",
      FORMVIEW_MESSAGE_APPROVE: "Approve",
      FORMVIEW_MESSAGE_REJECT: "Reject",
      FORMVIEW_MESSAGE_SELECT_ALL: "Select All",
    },
    REGION: {
      ID: "ID",
      DISTRICT: "District",
      REGION: "Region",
      ACTIONS: "Actions",
      FORMADD_TITLE: "Add Region",
      FORMUPDATE_TITLE: "Edit Region",
      FORM_NAME: "Name",
      FORM_DISTRICT: "District",
      FORM_MESSAGE: "Draw a geofence",
      FORM_SUB_MESSAGE:
        "Draw a geofence on the map by clicking on the map to create markers, then you can adjust the shape by dragging the markers",
      FORM_SUBMIT_FORM: "Submit",
      FORM_SELECT_DISTRICT: "Select District",
    },
    SURVEY: {
      HEADER_TITLE: "Surveys  list",
      ID: "ID",
      NAME: "Name",
      POINT_STATUS: "Point Status",
      POINT_NAME: "Point Name",
      ACTIONS: "Actions",

      FORMVIEW_TITLE: "View Survey",
      FORMVIEW_CONDITION: "Condition",
      FORMVIEW_TYPE: "Type",
      FORMVIEW_IS_MANDATORY: "Is Mandatory",
      FORMVIEW_COLLAPSED_BUTTON: "Collapse",
      FORMVIEW_EXPAND_BUTTON: "CollapsE",

      FORMADD_TITLE: "Create Survey",
      FORMUPDATE_TITLE: "Update Survey",
      FORMADD_NAME: "Name",
      FORMADD_SELECT_POINT: "Select Point",
      FORMADD_POINT_STATUS: "Point Status",

      BUILDSURVEY_TITLE: "Build Surveys",
      BUILDSURVEY_LIST_TITLE: "Survey Fields list",
      BUILDSURVEY_GRID_ID: "ID",
      BUILDSURVEY_GRID_NAME: "Name",
      BUILDSURVEY_GRID_TYPE: "Type",
      BUILDSURVEY_GRID_LIST_TYPE: "List Type",
      BUILDSURVEY_GRID_IS_MANDATORY: "Is Mandatory",
      BUILDSURVEY_GRID_HAS_CONDITION: "Has Condition",
      BUILDSURVEY_GRID_PARENT_FIELD_ID: "Parent Field ID",
      BUILDSURVEY_GRID_UNDER_CONDITIONS: "Ununder Conditions",
      BUILDSURVEY_GRID_DEFAULT_VALUE: "Default Value",
      BUILDSURVEY_GRID_ACTIONS: "Actions",

      BUILDSURVEY_FORMADD_TITLE: "Create Survey Field",
      BUILDSURVEY_FORMUPDATE_TITLE: "UPDATE Survey Field",
      BUILDSURVEY_FORMADD_NAME: "Name",
      BUILDSURVEY_FORMADD_DEFAULT_NAME: "Default Name",
      BUILDSURVEY_FORMADD_FIELD_TYPE: "Field Type",
      BUILDSURVEY_FORMADD_FIELD_LIST: "Field List",
      BUILDSURVEY_FORMADD_PARENT_SURVEY_FIELD: "Parent Survey Field",
      BUILDSURVEY_FORMADD_CONDITION_VALUE: "Condition Value",
      BUILDSURVEY_FORMADD_SELECT_CONDITION_VALUE: "Select Condition Value",
      BUILDSURVEY_FORMADD_IS_MADNDATORY: "Is Mandatory",
      BUILDSURVEY_FORMADD_HAS_CONDITION: "Has Condition",
      BUILDSURVEY_FORMADD_IS_MULTIPLE_SELECT: "Has Multiple Select",
    },
    JOURNEYS: {
      ID: "ID",
      USERNAME: "User Name",
      PHONE: "Phone",
      COUNT: "Count",
      TODAY_PLAN: "Day Plan",
      ACTIONS: "Actions",

      USERDAILYPLAN_TITLE: "User Daily Plans List",
      USERDAILYPLAN_ID: "ID",
      USERDAILYPLAN_PLAN_DATE: "Plan Date",
      USERDAILYPLAN_TOTAL: "Total",
      USERDAILYPLAN_ACTIONS: "Actions",

      USERDAILYPLAN_FORMADD_TITLE: "Select Daily Plan For",
      USERDAILYPLAN_FORMADD_SELECT_DATE: "Select Date",
      USERDAILYPLAN_FORMADD_DISTIRCTS: "DistrictS",
      USERDAILYPLAN_FORMADD_REGIONS: "Regions",
      USERDAILYPLAN_FORMADD_POINTS: "Points",

      USERDAILYPLAN_FORMADD_SELECTED_REGIONS: "Selected Regions",
      USERDAILYPLAN_FORMADD_TOTAL_SELECTED_POINTS: "Total selected points",
      USERDAILYPLAN_FORMADD_GRAND_TOTAL_POINT: "Grand Total Points",
    },
    ROLES: {
      TITLE: "Role List",
      ID: "ID",
      ROLE_NAME: "Role Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",
    },
    DEPARTMENTS: {
      TITLE: "Departments List",
      ID: "ID",
      DEPARTMENT_NAME: "Department Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",
    },
    DISTIRCTS: {
      TITLE: "Districts List",
      ID: "ID",
      DISTIRCT_NAME: "District Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",
    },
    FIELD_LISTS: {
      TITLE: "fieldList List",
      ID: "ID",
      FIELD_LISTS_NAME: "FieldList Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",

      FIELD_LIST_VALUE_TITLE: "FieldListValue List",
      FIELD_LIST_VALUE_ID: "ID",
      FIELD_LIST_VALUE_NAME: "FieldListValue Name",
      FIELD_LIST_VALUE_ACTIONS: "Actions",
      FIELD_LIST_VALUE_FORMADD_NAME: "Name",
    },
    POINT_STATUS: {
      TITLE: "Points status List",
      ID: "ID",
      POINT_STATUS_NAME: "PointType Name",
      ACTIONS: "Actions",
      FORMADD_NAME: "Name",
    },
    VISIT_REPORT: {
      ID: "ID",
      NAME: "Name",
      PHONE: "Phone",
      OWNER_NAME: "Owner Name",
      REGION: "Region",
      DISTRICT: "District",
      BY: "By",
      START_DATE: "Start Date",
      START_D: "Start D",
      END_DATE: "End Date",
      END_D: "End D",
      ROAD_TIME: "Road Time",
      VISIT_TIME: "Visit Time",
      ACTIONS: "Actions",
    },
    ATTENDANCE_REPORT:{
      ID: "ID",
      ROAD_TIME: "Road Time",
      START_DATE: "Start Date",
      END_DATE: "End Date", 
      VISIT_TIME: "Visit Time",
      ROAD_CALC:'Road CaLC',
      VISIT_CALC:'Visit CaLC',
      OVERRIDE_ROAD_TIME: "Override Road Time",
      OVERRIDE_VISIT_TIME: "Override Visit Time",

      ATTENDANCE_TOTAL_SUMMARY:'Total Summary',
      ATTENDANCE_ROAD_TIME:'Road Time',
      ATTENDANCE_VISIT_TIME:'Visit Time',
      ATTENDANCE_TOTAL_WORK_TIME:'Total Work Time',
      ATTENDANCE_USER_WORKING_HOURS_PER_DAY:'User Working Hours Per Day',
      ATTENDANCE_OVERRIDE:'Overridden',
      ATTENDANCE_NOT_OVERRIDE:'Not Overridden',

    },
    FOLLOWUP_REPORT:{
       ID: "ID",
       SALESMAN:'Salesman',
       CUSTOMER:'Customer',
       PHONE:'Phone',
       START_DATE:'Start Date',
       STATUS:'Status',
       NOTE:'Note',
    },
    SIDEMENU:{
        DASHBOARD:'Dashboard',
        USERS:'Users',
        VISITS:'Visits',
        POINTS:'Points',
        POINTS_REQUESTS:'Points Requests',
        REGIONS:'Regions',
        SURVEYS:'Surveys',
        JOURNEYS:'Journeys',
        MASTERS:'Masters',
        ROLES:'Roles',
        DEPARTMENTS:'Departments',
        DISTRICTS:'Districts',
        FIELD_LISTS:'Field Lists',
        POINT_STATUS:'Point Status',
        REPORT:'Report',
        VISITS_REPORT:'Visits Report',
        ATTENDANCE_REPORT:'Attendance Report',
        FLOWUP_REPORT:'Flows Report',
        COMPANIES:'Companies',
        SETTINGS:'Settings',

        SIGN_OUT:'Sign Out',
    },
    APPBAR:{
      MY_PROFILE:'My Profile',
      SIGN_OUT:'Sign Out',
      SIGN_IN:'Sign In',
      
      TOOLTIP_LANGUAGE:'Language',
      TOOLTIP_NOTIFICATION:'Notification',
      TOOLTIP_FULL_SCREEN:'Full Screen',
      TOOLTIP_USERS:'Users',
      TOOLTIP_ANALYTICS_DASHBOARD:'Analytics Dashboard',
      TOOLTIP_EXISTS_FULL_SCREEN:'Exists from  full screen',
      TOOLTIP_COMPANY:'Company',
      TOOLTIP_SELECT_A_COMPANY:"Select a Company"
    },
    NOTIFICATIONS:{
      TITLE:'Notifications',
      DISMISSION_ALL_NOTIFICATIONS: 'Dismission All',
      NO_NOTIFICATIONS: 'Thers Is No Notification Right Now',
    },
    SETTINGS:{
      TITLE:'Theme Settings',
      SIDEMENU_LAYOUT:'SideMenu Layout',
      SIDEMENU_ICONMODE:'Icon Mode',
      SIDEMENU_DIRECTION:'Directions Mode',
      THEME_LAYOUT:'Theme Layout',
      THEME_LAYOUT_MAIN_PALETTE:'Main Palette',
      THEME_LAYOUT_TOOLBAR_PALETTE:'Toolbar Palette',
      THEME_LAYOUT_SIDEMENU_PALETTE:'SideMenu Palette',
      THEME_LAYOUT_GRID_PALETTE:'Grid Palette',

      MANDOOBEEN_SETTINGS:'Mandoobean-Settings',
      SETTINGS_UPDATE_BUTTON:'Update'
    },
    SETTINGS_SECTIONS:{
      GLOBAL:'Global',
      GLOBAL_SETTINGS:'Global Settings',
      
      SIDEMENU:"Side Menu",
      SIDEMENU_SETTINGS:'SideMenu Settings',

      TOOLBAR:"Toolbar",
      TOOLBAR_SETTINGS:'Toolbar Settings',

      USERS:'Users',
      USERS_SETTINGS:'Users Settings',

      VISITS:'VisitS',
      VISITS_SETTINGS:'VisitS Settings',

      POINTS:'Points',
      POINTS_SETTINGS:'Points Settings',

      POINTS_REQUESTS:'Points Requests',
      POINTS_REQUESTS_SETTINGS:'Points Requests Settings',

      REGION:'Region',
      REGION_SETTINGS:'Region Settings',

      SURVEY:'Survey',
      SURVEY_SETTINGS:'Survey Settings',

      JOURNEYS:'Journeys',
      JOURNEYS_SETTINGS:'Journeys Settings',

      ROLES:'Roles',
      ROLES_SETTINGS:'Roles Settings',

      DEPARTMENTS:'Departments',
      DEPARTMENTS_SETTINGS:'Departments Settings',

      DISTIRCTS:'Districts',
      DISTIRCTS_SETTINGS:'Districts Settings',

      FIELD_LISTS:'Field  Lists',
      FIELD_LISTS_SETTINGS:'Field  Lists Settings',

      POINT_STATUS:'Points Status',
      POINT_STATUS_SETTINGS:'Points Status Settings',

      VISIT_REPORT:'Visits Report',
      VISIT_REPORT_SETTINGS:'Visits Report Settings',

      ATTENDANCE_REPORT:'Attendance Report',
      ATTENDANCE_REPORT_SETTINGS:'Attendance Report Settings',

      FOLLOWUP_REPORT:'Followup Report',
      FOLLOWUP_REPORT_SETTINGS:'Followup Report Settings',

      NOTIFICATIONS:'Notifications',
      NOTIFICATIONS_SETTINGS:'Notifications Settings',

      SETTINGS:'Settings',
      SETTINGS_SETTINGS:'Settings Settings',
    }


  },
  translation_ar: {
    GLOBAL: {
      CREATE_HEADER_BUTTON_TEXT: "أنشئ جديد",
      PAGINATION_PAGE_NUMBER: "الصفحة",
      GRID_ROW_PER_PAGE: "ٌالاسطر في كل صفحة",
      POPUP_SAVE_BUTTON: "حفظ",
      POPUP_CANCEL_BUTTON: "ألغاء",
      SEARCH_TITLE: "بحث متقدم",
      SEARCH_EXPORT_BUTTON: "تصدير",
      SEARCH_IMPORT_BUTTON: "أستيراد",
      SEARCH_MAP_BUTTON: "خريطة",
      SEARCH_CREATE_BUTTON: "انشاء",

      SEARCH_SEARCH_BUTTON: "بحث",
      SEARCH_CLEAR_BUTTON: "مسح",
      SEARCH_FROM_DATE: "من تاريخ",
      SEARCH_TO_DATE: "الى تاريخ",
      SEARCH_REGION: "منطقة",
      SEARCH_DISTRICTS: "قطاع",
      SEARCH_OWNER_NAME: "اسم المدير",
      SEARCH_POINT_STATUS: "حالة النقطة",
      SEARCH_MAX_DISTANCE: "اكبر مسافة (متر)",
      SEARCH_MIN_DISTANCE: "اقل مسافة (متر)",
      SEARCH_SEND_BUTTON: "ارسال",

      SEARCH_USERS: "المستخدمين",
      SEARCH_HAS_PHONE: "لديه هاتف",
      SEARCH_HAS_FOLLOWUP: "لديه متابعة",
      SEARCH_SHOPE_NAME: "اسم المحل",
      SEARCH_TO_WORD: "الى",
      SEARCH_REQUEST_STATUS: "حالة الطلب",

      MAPS_VIEW_POINT: "اظهار النقطة",
      MAPS_VIEW_POINT_REQUEST: "عرض طلب النقطة",
      MAPS_VIEW_VISIT_FOR_POINT: "عرض الزيارة لهذه النقطة",
      MAPS_VIEW_ALL_VISIT_AND_POINT: "اضهار جميع الزيارات والنقاط",

      IMPORT_FORM_TITLE: "استيراد نقطة",
      IMPORT_FORM_TITLE_WITH_GEOFENCE: "استيراد المناطق مع Geofence",
      IMPORT_FORM_MESSAGES: "!هذا المدخل يدعم سحب وافلات الملفات",
      IMPORT_FORM_UPLOAD_BUTTON: "تحميل",
      IMPORT_FORM_CHOOSE_FILE_BUTTON: "اختر ملف",
      IMPORT_FORM_MESSAGE_NOT_SELECTED_FILE: "لم يتم اختيار اي ملف",

      GRID_NO_DATA: "لاتوجد بيانات",
      GRID_FOOTER_SAVE_BUTTON: "حفظ",
    },
    USERS: {
      UNIQUE_HEADER_TITLE:'محتويات الجدول',
      ID: "ID",
      FULL_NAME: "الاسم كامل",
      USER_NAME: "أسم المستخدم",
      PHONE: "الهاتف",
      DEPARTMENT: "القسم",
      ACTIVE: "الحالة",
      ACTIONS: "الوظائف",

      UNIQUE_HEADER_TITLE:'محتويات قائمة الاضافة',
      HEADER_TITLE: "قائمة المستخدمين",
      USER_ADD_FORM: "اضافة مستخدم",
      USER_EDIT_FORM: "تعديل بيانات المستخدم",
      FORM_FULLNAME: "الاسم  كامل",
      FORM_PHONE: "الهاتف",
      FORM_USERNAME: "أسم المستخدم",
      FORM_DEPARTMENT: "القسم",
      FORM_PASSWORD: "الرقم السري",
      FORM_COMPANY:'شركة',
      FORM_SUBMIT:'ارسال',
      FORM_SELECT_DEPARTMENT:'اختر قسم',
      FORM_SELECT_COMPANY:'اختر شركة',

      USER_ROLES: "صلاحيات المستخدم",
    },
    VISITS: {
      ID: "ID",
      NAME: "اسم النقطة",
      PHONE: "الهاتف",
      USER: "المستخدم",
      START_END_DATE: "بداية ونهاية الوقت",
      IS_CANCELL: "هل أُلغيت",
      REASON: "سبب الالغاء",
      NOTE: "ملاحظة",
      FOLLOWUPS: "متابعة",
      ACTIONS: "الوظائف",
      VISITED_BY: "زيارة بواسطت",

      FORMVIEW_TITLE: "معلومات الزيارة",
      FORMVIEW_TABLE_NAME: "الاسم",
      FORMVIEW_TABLE_VALUE: "القيمة",
      FORMVIEW_TABLE_THERES_NO_VALUES: "لا توجد أي قيم للاستبيان",

      FORMVIEW_ADD_VISIT_FOLLOWUP: "اضافة متابعة للزوار",
      FORMVIEW_SEARCH_SAVE_BUTTON: "حفظ",
      FORMVIEW_SEARCH_FOLLOWUP_NOTE: "ملاحظات المتابعة...",
      FORMVIEW_SEARCH_STATUS: "الحالة",
      FORMVIEW_SEARCH_LOADING_BUTTON: "تحميل...",

      FORMVIEW_GRID_ID: "عرض ID",
      FORMVIEW_GRID_STATUS: "عرض الحالة",
      FORMVIEW_GRID_NOTE: "عرض الملاحظات",
    },
    POINTS: {
      ID: "ID",
      DISTRICT: "قطاع",
      REGION: "منطقة",
      NAME: "الاسم",
      OWNER_DETAILS: "تفاصيل المالك",
      OWNER_TYPE: "نوع المالك",
      OWNER_NAME: "اسم المالك",
      ACTIONS: "الوظائف",
      PHONE: "هاتف",
      FORMvIEW_FIELD_NAME: "اسم الحقل",
      FORMVIEW_POINT_NAME: "اسم النقطة",
      FORMVIEW_NEAR_BY: "قريب على",
      FORMVIEW_LOCATION_CATEGORY: "فئة الموقع",
      FORMVIEW_CLASSIFICATION: "التصنيف",
      FORMVIEW_CATEGORY: "فئة",
      FORMVIEW_POINT_TYPE: "نوع النقطة",
      FORMVIEW_REGION: "المنطقة",
      FORMVIEW_LATITUDE: "خط العرض",
      FORMVIEW_LONGITUDE: "خط الطول",
      FORMVIEW_HAS_DELIVERY: "لديه توصيل",
      FORMVIEW_IMAGE: "صورة",
      FORMVIEW_MESSAGE_NO_IMAGE: "لم يتم اختيار صورة",

      FORMADD_TITLE: "اضافة نقطة",
      FORMADD_EXTERNAL_CUSTOMER_ID: "معرف زبون خارجي",
      FORMADD_SELECT_OWNER_TYPE: "اختر نوع المالك",
      FROMADD_SELECT_REGION: "اختر المنطقة",
      FROMADD_SELECT_CATEGORY: "اختر الفئة",
      FROMADD_SELECT_LOCATION_CATEGORY: "اختر فئة الموقع",
      FORMADD_SELECT_CLASS: "ختر الصنف",
      FROMADD_STATUS: "النوع(الحالة)",
      FROMADD_SELECT_STATUS: "اختر النوع (الحالة)",
      FROMADD_SUBTITLE_MESSAGE: "اختر موقع النقطة على الخريطة",
      FROMADD_SUBMIT_BUTTON: "ارسال",
    },
    POINTS_REQUESTS: {
      ID: "ID",
      LOCATION: "الموقع",
      USER: "المستخدم",
      NAME: "الاسم",
      OWNER_DETAILS: "معلومات المالك",
      REQUEST_DATA: "طلب البيانات",
      STATUS: "الحالة",
      ACTIONS: "الوظائف",
      FORMVIEW_FIELD_NAME: "أسم الحقل",
      FORMVIEW_REQUEST_CHANGES: "طلب التغييرات",
      FORMVIEW_ORIGINAL_POINT: "النقطة الأصلية",
      FORMVIEW_APPROVE_OR_REJECT: "الموافقة / الرفض",
      OWNER_TYPE: "نوع المالك",
      PHONE: "هاتف",
      FORMVIEW_NEAR_BY: "قريب على",
      FORMVIEW_LOCATION_CATEGORY: "فئة الموقع",
      FORMVIEW_CLASSIFICATION: "تصنيف",
      FORMVIEW_CATEGORY: "فئة",
      FORMVIEW_IMAGE: "صورة",
      FORMVIEW_MESSAGE_SELECT_REQUEST_STATUS: "حدد حالة الطلب",
      FORMVIEW_MESSAGE_APPROVE: "موافقة",
      FORMVIEW_MESSAGE_REJECT: "رفض",
      FORMVIEW_MESSAGE_SELECT_ALL: "اختيار الكل",
    },
    REGION: {
      ID: "ID",
      DISTRICT: "قطاع",
      REGION: "منطقة",
      ACTIONS: "الوظائف",

      FORMADD_TITLE: "اضافة منطقة",
      FORMUPDATE_TITLE: "تعديل منطقة",
      FORM_NAME: "الاسم",
      FORM_DISTRICT: "مقاطعة",
      FORM_MESSAGE: "ارسم سياجًا جغرافيًا",
      FORM_SUB_MESSAGE:
        "ارسم سياجًا جغرافيًا على الخريطة من خلال النقر على الخريطة لإنشاء محددات ، ثم يمكنك ضبط الشكل عن طريق سحب العلامات",
      FORM_SUBMIT_FORM: "ارسال",
      FORM_SELECT_DISTRICT: "اختر مقاطعة",
    },
    SURVEY: {
      HEADER_TITLE: "قائمة الاستطلاعات",
      ID: "ID",
      NAME: "الاسم",
      POINT_STATUS: "حالة النقطة",
      POINT_NAME: "اسم النقطة",
      ACTIONS: "الوظائف",

      FORMVIEW_TITLE: "عرض الاستطلاع",
      FORMVIEW_CONDITION: "شرط",
      FORMVIEW_TYPE: "نوع",
      FORMVIEW_IS_MANDATORY: "إلزامي",
      FORMVIEW_COLLAPSED_BUTTON: "تصغير",
      FORMVIEW_EXPAND_BUTTON: "تكبير",

      FORMADD_TITLE: "انشاء استطلاع",
      FORMUPDATE_TITLE: "تحديث الاستطلاع",
      FORMADD_NAME: "الاسم",
      FORMADD_SELECT_POINT: "اختيار نقطة",
      FORMADD_POINT_STATUS: "حالة النقطة",

      BUILDSURVEY_TITLE: "بناء استطلاع",
      BUILDSURVEY_LIST_TITLE: "لائحة حقول الاستطلاع",
      BUILDSURVEY_GRID_ID: "ID",
      BUILDSURVEY_GRID_NAME: "الاسم",
      BUILDSURVEY_GRID_TYPE: "النوع",
      BUILDSURVEY_GRID_LIST_TYPE: "نوع اللأحة",
      BUILDSURVEY_GRID_IS_MANDATORY: "ألزامي",
      BUILDSURVEY_GRID_HAS_CONDITION: "لديه شرط",
      BUILDSURVEY_GRID_PARENT_FIELD_ID: "معرف حقل الاب ",
      BUILDSURVEY_GRID_UNDER_CONDITIONS: "شروط غير خاضعة",
      BUILDSURVEY_GRID_DEFAULT_VALUE: "القيمة الافتراضية",
      BUILDSURVEY_GRID_ACTIONS: "الوظائف",

      BUILDSURVEY_FORMADD_TITLE: "انشاء حقول استطلاع",
      BUILDSURVEY_FORMUPDATE_TITLE: "تحديث حقول الاستطلاع",
      BUILDSURVEY_FORMADD_NAME: "الاسم",
      BUILDSURVEY_FORMADD_DEFAULT_NAME: "الاسم الافتراضي",
      BUILDSURVEY_FORMADD_FIELD_TYPE: "نوع الحقل",
      BUILDSURVEY_FORMADD_FIELD_LIST: "لائحة الحقول",
      BUILDSURVEY_FORMADD_PARENT_SURVEY_FIELD: "حقل استطلاع الاب",
      BUILDSURVEY_FORMADD_CONDITION_VALUE: "قيمة الشرط",
      BUILDSURVEY_FORMADD_SELECT_CONDITION_VALUE: "اختيار قيم الشرط",
      BUILDSURVEY_FORMADD_IS_MADNDATORY: "ألزامي",
      BUILDSURVEY_FORMADD_HAS_CONDITION: "لديه شرط",
      BUILDSURVEY_FORMADD_IS_MULTIPLE_SELECT: "لديه خيارات متعددة",
    },
    JOURNEYS: {
      ID: "ID",
      USERNAME: "اسم المستخدم",
      PHONE: "الهاتف",
      COUNT: "عدد",
      TODAY_PLAN: "خطة اليوم",
      ACTIONS: "الوظائف",

      USERDAILYPLAN_TITLE: "لائحة خطط المستخدم اليومية",
      USERDAILYPLAN_ID: "ID",
      USERDAILYPLAN_PLAN_DATE: "تاريخ الخطة",
      USERDAILYPLAN_TOTAL: "المجموع",
      USERDAILYPLAN_ACTIONS: "الوظائف",

      USERDAILYPLAN_FORMADD_TITLE: "حدد الخطة اليومية لـ",
      USERDAILYPLAN_FORMADD_SELECT_DATE: "اختر التاريخ",
      USERDAILYPLAN_FORMADD_DISTIRCTS: "المقاطعات",
      USERDAILYPLAN_FORMADD_REGIONS: "المناطق",
      USERDAILYPLAN_FORMADD_POINTS: "النقاط",

      USERDAILYPLAN_FORMADD_SELECTED_REGIONS: "المناطق المحددة",
      USERDAILYPLAN_FORMADD_TOTAL_SELECTED_POINTS: "مجموع النقاط المحددة",
      USERDAILYPLAN_FORMADD_GRAND_TOTAL_POINT: "أجمالي النقاط",
    },
    ROLES: {
      TITLE: "لائحة الصلاحيات",
      ID: "ID",
      ROLE_NAME: "اسم الصلاحية",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",
    },
    DEPARTMENTS: {
      TITLE: "لائحة الاقسام",
      ID: "ID",
      DEPARTMENT_NAME: "اسم القسم",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",
    },
    DISTIRCTS: {
      TITLE: "لائحة المقاطعات ",
      ID: "ID",
      DISTIRCT_NAME: "اسم المقاطعة",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",
    },
    FIELD_LISTS: {
      TITLE: "لوائح الحقول",
      ID: "ID",
      FIELD_LISTS_NAME: "اسم لائحة الحقل",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",

      FIELD_LIST_VALUE_TITLE: "قيم قائنمة الحقول",
      FIELD_LIST_VALUE_ID: "ID",
      FIELD_LIST_VALUE_NAME: "اسم قيمة قائمة الحقول",
      FIELD_LIST_VALUE_ACTIONS: "الوظائف",
      FIELD_LIST_VALUE_FORMADD_NAME: "الاسم",
    },
    POINT_STATUS: {
      TITLE: "قائمة حالة النقاط",
      ID: "ID",
      POINT_STATUS_NAME: "اسم نوع النقطة",
      ACTIONS: "الوظائف",
      FORMADD_NAME: "الاسم",
    },
    VISIT_REPORT: {
      ID: "ID",
      NAME: "الاسم",
      PHONE: "الهاتف",
      OWNER_NAME: "اسم المالك",
      REGION: "المنطقة",
      DISTRICT: "المقاطعة",
      BY: "بواسطت",
      START_DATE: "تاريخ البدء",
      START_D: "ابدأ د",
      END_DATE: "تاريخ الانهاء",
      END_D: "انهاء د",
      ROAD_TIME: "وقت  الطريق",
      VISIT_TIME: "وقت الزيارة",
      ACTIONS: "الوظائف",
    },
    ATTENDANCE_REPORT:{
      ID: "ID",
      ROAD_TIME: "الوقت على الطريق",
      START_DATE: "تاريخ البدء",
      END_DATE: "تاريخ الانهاء", 
      VISIT_TIME: "وقت الزيارة",
      ROAD_CALC:'حساب الطريق',
      VISIT_CALC:'حساب الزيارة',
      OVERRIDE_ROAD_TIME: "تجاوز وقت الطريق",
      OVERRIDE_VISIT_TIME: "تجاوز وقت الزيارة",
      
      ATTENDANCE_TOTAL_SUMMARY:'إجمالي الملخص',
      ATTENDANCE_ROAD_TIME:'الوقت على الطريق',
      ATTENDANCE_VISIT_TIME:'وقت الزيارة',
      ATTENDANCE_TOTAL_WORK_TIME:'إجمالي وقت العمل',
      ATTENDANCE_USER_WORKING_HOURS_PER_DAY:'ساعات عمل المستخدم في اليوم',

      ATTENDANCE_OVERRIDE:'اهمل',
      ATTENDANCE_NOT_OVERRIDE:'لا تهمل',
    },
    FOLLOWUP_REPORT:{
      ID: "ID",
      SALESMAN:'بائع',
      CUSTOMER:'العميل',
      PHONE:'هاتف',
      START_DATE:'تاريخ البدء',
      STATUS:'الحالة',
      NOTE:'ملاحظة',
    },
    SIDEMENU:{
      DASHBOARD:'لوحة التحكم',
      USERS:'المستخدمين',
      VISITS:'الزيارات',
      POINTS:'النقاط',
      POINTS_REQUESTS:'طلبات النقاط',
      REGIONS:'المناطق',
      SURVEYS:'الاستطلاعات',
      JOURNEYS:'المسارات',
      MASTERS:'الرئيسية',
      ROLES:'الصلاحيات',
      DEPARTMENTS:'الاقسام',
      DISTRICTS:'المقاطعات',
      FIELD_LISTS:'قائمة الحقول',
      POINT_STATUS:'حالة النقاط',
      REPORT:'التقارير',
      VISITS_REPORT:'تقرير الزيارات',
      ATTENDANCE_REPORT:'تقرير الحضور',
      FLOWUP_REPORT:'تقرير المتابعة',
      COMPANIES:'الشركات',
      SETTINGS:'الاعدادات',
      SIGN_OUT:'تسجيل خروج',

    },
    APPBAR:{
      MY_PROFILE:'ملفي الشخصي',
      SIGN_OUT:'تسجيل الخروج',
      SIGN_IN:'تسجيل دخول',
      
      TOOLTIP_LANGUAGE:'اللغة',
      TOOLTIP_NOTIFICATION:'الاشعارات',
      TOOLTIP_FULL_SCREEN:'وضع الشاشة الكاملة',
      TOOLTIP_EXISTS_FULL_SCREEN:'خروج من وضع الشاشة الكاملة',

      TOOLTIP_USERS:'المستخدمين',
      TOOLTIP_ANALYTICS_DASHBOARD:'لوحة التحليلات',
      TOOLTIP_COMPANY:'الشركة',
      TOOLTIP_SELECT_A_COMPANY:"اختر شركة"
    },
    NOTIFICATIONS:{
      TITLE:'الاشعارات',
      DISMISSION_ALL_NOTIFICATIONS: 'حذف الكل',
      NO_NOTIFICATIONS: 'لا توجد اشعارات في الوقت حاليا',
    },
    SETTINGS:{
    TITLE:'اعدادات التنسيق',
    SIDEMENU_LAYOUT:'واجهة القائمة الجانبية',
    SIDEMENU_ICONMODE:'وضع الايقونات',
    SIDEMENU_DIRECTION:'وضع الاتجاهات',
    THEME_LAYOUT:'واجهة التنسيق',
    THEME_LAYOUT_MAIN_PALETTE:'اللوحة الرئيسية',
    THEME_LAYOUT_TOOLBAR_PALETTE:'لوحة شريط الادوات',
    THEME_LAYOUT_SIDEMENU_PALETTE:'لوحة القائمة الجانبية',
    THEME_LAYOUT_GRID_PALETTE:'لوحة الجداول',

    MANDOOBEEN_SETTINGS:'اعدادت-المندوبين',
    SETTINGS_UPDATE_BUTTON:'تحديث'


    },
    SETTINGS_SECTIONS:{
      GLOBAL:'عام',
      GLOBAL_SETTINGS:'اعدادات عامة',

      SIDEMENU:"القائمة الجانبية",
      SIDEMENU_SETTINGS:'اعدادات القائمة الجانبية',

      TOOLBAR:"شريط الادوات",
      TOOLBAR_SETTINGS:'اعدادات شريط الادوات',

      USERS:'المستخدمين',
      USERS_SETTINGS:'اعدادات المستخدمين',

      VISITS:'الزيارات',
      VISITS_SETTINGS:'اعدادات الزيارات',

      POINTS:'النقاط',
      POINTS_SETTINGS:'اعدادات النقاط',

      POINTS_REQUESTS:'طلب النقاط',
      POINTS_REQUESTS_SETTINGS:'اعدادات طلب النقاط',

      REGION:'المنطقة',
      REGION_SETTINGS:'اعدادات المنطقة',

      SURVEY:'الاستطلاع',
      SURVEY_SETTINGS:'اعدادات الاستطلاع',

      JOURNEYS:'المسارات',
      JOURNEYS_SETTINGS:'اعدادات المسارات',

      ROLES:'الصلاحيات',
      ROLES_SETTINGS:'اعدادات الصلاحيات',

      DEPARTMENTS:'الاقسام',
      DEPARTMENTS_SETTINGS:'اعدادات الاقسام',

      DISTIRCTS:'المقاطعات',
      DISTIRCTS_SETTINGS:'اعدادات المقاطعات',

      FIELD_LISTS:'لوائح الحقول',
      FIELD_LISTS_SETTINGS:'اعدادات لوائح الحقول',

      POINT_STATUS:'حالة النقاط',
      POINT_STATUS_SETTINGS:'اعدادات حالة النقاط',

      VISIT_REPORT:'نقرير الزيارات',
      VISIT_REPORT_SETTINGS:'اعدادات تقرير الزيارات',

      ATTENDANCE_REPORT:'تقرير الحضور',
      ATTENDANCE_REPORT_SETTINGS:'اعدادات تقرير الحضور',

      FOLLOWUP_REPORT:'تقرير المتابعات',
      FOLLOWUP_REPORT_SETTINGS:'اعدادات تقرير المتابعات',

      NOTIFICATIONS:'الاشعارات',
      NOTIFICATIONS_SETTINGS:'اعدادات الاشعارات',

      SETTINGS:'الاعدادات',
      SETTINGS_SETTINGS:'الاعدادات',

    }

  },
};
export const TranslateSlice = createSlice({
  name: "translateData",
  initialState: initialState,
  reducers: {
    setTranslateEnglish:(state,action)=>{
      state.translation_en=action.payload
    },
    setTranslateArabic:(state,action)=>{
      state.translation_ar=action.payload
    },
  },
});

export const {
  setTranslateEnglish,
  setTranslateArabic,
} = TranslateSlice.actions;

// export const sideMenuMode = state => state.settingsData.sideMenuMode;

export default TranslateSlice.reducer;
