import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomePaper from '../../Share/Components/CustomePaper';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import CustomeInput from './CustomeInput';
import {
    setTranslateEnglish,
    setTranslateArabic,
} from '../../../reduxStore/TranslateReducer';

const SideMenuSettings =({title,sections})=> {
    const {t, i18n} = useTranslation('common');
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);
    const EnglishValues=useSelector(state=>state.translateData.translation_en);
    const ArabicValues=useSelector(state=>state.translateData.translation_ar);
    const directions=useSelector(state=> state.settingsData.directions)
    const [fieldsValue,setFieldsValue]=useState({})
    const [fieldsValue_old,setFieldsValue_old]=useState({})

    const dispatch=useDispatch();

    useEffect(()=>{
        if(directions=='rtl'){
            setFieldsValue(ArabicValues)
            setFieldsValue_old(ArabicValues)
        }else{
            setFieldsValue(EnglishValues)
            setFieldsValue_old(EnglishValues)
        }
    },[EnglishValues,ArabicValues,directions])

   const updateName=(name,label)=>{

        setFieldsValue({
        ...fieldsValue,
        [sections]:{
         ...fieldsValue[sections],
        [label]:name,
        }
        
       })
   
   }

   const saveChange=()=>{
    if(directions=='rtl'){
        dispatch(setTranslateArabic(fieldsValue))
     }else{
        dispatch(setTranslateEnglish(fieldsValue))
     }
   }

   const renderFields=()=>{
    if(fieldsValue&&fieldsValue[sections]){
    console.log('sidemenu',fieldsValue[sections])
    const arraKeys = Object.keys(fieldsValue[sections]);
    const arrayValues = Object.values(fieldsValue[sections]);
    let mainArray=[];
    let result=[];
    for(let i=0;i<arraKeys?.length;i++){
        // if(arraKeys[i]==){
          
        // }else 
        result.push(<CustomeInput  fieldName={arraKeys[i]} value={fieldsValue[sections][arraKeys[i]]} setValue={updateName} label={fieldsValue_old[sections][arraKeys[i]]} />)
    }

    //<div style={{display:'flex',justifyContent: 'start',alignContent: 'center'}}>
    //</div>
//
//

    // const objectArray = Object.entries(fieldsValue?.SIDEMENU);
    // return objectArray.forEach(([key, value]) => (
    //   <CustomeInput  fieldName={key} value={value} setValue={updateName} label="Dashboard Name" />
    // ));
    return result;
   }
   return null
   }

    return (
        <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor:MainTheme?.paperColor,
          color:MainTheme?.disabledGlobalText?MainTheme.globalTextColor:MainTheme?.paperTextColor,
          '& > :not(style)': {
            // m: 1,
            width: '99%',
            height: '95%',
          },
        }}
      >
      
        {/* <CustomePaper> */}
            <CustomePaper>
                <Typography variant='h6' sx={{margin:'10px 20px',textAlign:'left'}}>
                    {
                        // t('SETTINGS.SIDEMENU_SETTINGS')
                        title
                    }
                </Typography>
                <Box sx={{display:'flex',flexWrap: 'wrap',justifyContent: 'center',msWrapFlow:'column',}}>
                    {
                        renderFields()?.length>0&&renderFields()?.map((data)=>(
                            data
                        ))
                    }
                </Box>
         

                <div style={{display:'flex',justifyContent:'end',alignItems: 'center'}}>
                  <Button sx={{margin:'10px 40px'}} variant="contained" onClick={saveChange}>{t('SETTINGS.SETTINGS_UPDATE_BUTTON')}</Button>
                </div>
              

            </CustomePaper>
        {/* </CustomePaper> */}
      </Box>
    )
}

export default SideMenuSettings;
