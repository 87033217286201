import styled from 'styled-components';

export const ContainerModal=styled.div`
    background-color: ${props=>props.bgColor} ;
    width: 690px;
    height: 610px;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    color:${props=>props.color};
    border: none;
    outline: none;
    position: relative;
`
export const HeaderModal=styled.div`
    background-color: ${props=>props.bgColor};
    height: 70px;
    width: 101% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props=>props.color};
    font-size: 20px;
    & .iconePlate{
        color: ${props=>props.color};
        font-size: 35px;
        margin: 0 10px;
    };
    & .settings{
        margin: 0 25px;
        font-size:30px;
        cursor:pointer;
    }
`

export const FooterModal=styled.div`
    position: absolute;
    left: 0 ;
    right:0;
    bottom: 0;
    display:flex;
    align-items: center;
    justify-content: space-between;
`
export const CancelButton=styled.div`
    color: rgb(58, 58, 58);
    border-radius: 20px;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    top: -15px;
    left: ${props=>props.directions=='rtl'?'-10px':'10px'};
    background-color: rgb(242, 241, 241);
    &:hover{
        background-color: ${props=>props.bgColor};
        color: ${props=>props.color};
    }
`
export const SaveButton=styled.div`
    color: rgb(58, 58, 58);
    border-radius: 20px;
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
    font-weight: 600;
    top: -15px;
    right: ${props=>props.directions=='rtl'?'-10px':'10px'};
    background-color: rgb(242, 241, 241);
    ${props=>props.disabled?(
       "pointer-events: none; opacity: 0.4;"
    ):''}
    
    &:hover{
        background-color: ${props=>props.bgColor};
        color: ${props=>props.color};
    }
`
export const PreviewSection=styled.p`
    color:${props=>props.color};
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -50px;
`;

export const ContentModal=styled.div`
    display: flex;
    align-items: center;
    height: 85%;
    justify-content: space-evenly;
`;

export const LeftSide=styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const RightSide=styled.div`
`;

export const PrimeryColorForText=styled.input`
    border-radius: 5px;
    margin: 10px 10px;
    outline: 1px solid ${props=>props.color};
    position: relative;
    width:200px;
    height:50px;
    padding:12px 4px;
    background-color:transparent;
    display:flex;
    justify-content: center;
    cursor:pointer;
    align-items: center;
    &:before{
        position: absolute;
        content: 'Primary Color';
        background-color:${props=>props.bgColor};
        color:${props=>props.color};
        top:-12px;
        left: 10px;
    }
`;

export const SecondaryColorForText=styled.input`
    border-radius: 5px;
    margin: 10px 10px;
    outline: 1px solid ${props=>props.color};
    position: relative;
    width:200px;
    height:50px;
    padding:12px 4px;
    background-color:transparent;
    display:flex;
    justify-content: center;
    cursor:pointer;
    align-items: center;
    &:before{
        position: absolute;
        content: 'secondary Color';
        background-color:${props=>props.bgColor};
        color:${props=>props.color};
        top:-12px;
        left: 10px;
    }
`;

export const BackgroundPaperColor=styled.input`
    border-radius: 5px;
    margin: 10px 10px;
    outline: 1px solid ${props=>props.borderColor};
    position: relative;
    width:200px;
    height:50px;
    padding:12px 4px;
    background-color:transparent;
    display:flex;
    justify-content: center;
    cursor:pointer;
    align-items: center;
    &:before{
        position: absolute;
        content: 'background paper color';
        background-color:${props=>props.bgColor};
        color:${props=>props.borderColor};
        top:-12px;
        left: 10px;
    }
`

export const BackgroundColor=styled.input`
    border-radius: 5px;
    margin: 10px 10px;
    outline: 1px solid ${props=>props.borderColor};
    position: relative;
    width:200px;
    height:50px;
    padding:12px 4px;
    background-color:transparent;
    display:flex;
    justify-content: center;
    cursor:pointer;
    align-items: center;
    &:before{
        position: absolute;
        content: 'background color';
        background-color:${props=>props.bgColor};
        color:${props=>props.borderColor};
        top:-12px;
        left: 10px;
    }
`

export const LineForColor=styled.input`
   width:90%;
   height:13px;
   cursor:pointer;
   background-color: ${(props)=>props.bgColor};
`;


export const LightOrDarkmode =styled.div`
   width:150px;
   height:50px;
   border-radius:30px;
   position:relative;
   top:57px;
   object-fit:cover;
   left:${props=>props.directions=='rtl'?'-120px':'120px'};
   overflow:hidden;
   display:flex;
   align-items:center;
   border:1px solid #4F46E5;
   padding:0;
`;
export const LightMode=styled.div`
    color:${(props)=>props.color};
    background-color:${(props)=>props.bgColor};
    flex:1;
    height:100%;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    object-fit:cover;
    font-weight:bold;

`
export const DarkMode=styled.div`
    color:${(props)=>props.color};
    background-color:${(props)=>props.bgColor};
    object-fit:contained;
    position:relative;
    flex:1;
    height:100%;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight:bold;
 
`

export const PreviewBox=styled.div`
   width:210px;
   height:200px;
   position: relative;
   border:1px solid ${props=>props.color};
   overflow: hidden;
   top:-53px;
   border-radius:10px;
   background-color: ${(props)=>props.bgColor};
`;

export const TopSectionPreview=styled.div`
      width:100%;
      height:30%;
      color:${props=>props.color};
      background-color:${props=>props.bgColor};
      padding:10px;
      font-weight:bold;
      font-size:13px;
      z-index:1 !important;
`;
export const BackgroundSectionPreivew=styled.div`
  width:100%;
  height:70%;
  color:${props=>props.color};
  background-color:${props=>props.bgColor};
  position:relative;
  font-weight:bold;
  font-size:13px;

`;

export const PaperSectionPreview=styled.div`
  position:absolute;
  top:19%;
  left:1%;
  display:flex;
  justify-content:space-evenly;
  align-items:center;
  width:98%;
  z-index:100 !important;

`;
export const LeftPaperBox=styled.div`
    color:${props=>props.color};
    background-color:${props=>props.bgColor};
    width:80%;
    border-radius:5px;
    display:flex;
    justify-content:start;
    height:100px;
    font-weight:400;
    font-size:13px;
    padding:4px 10px;
    -webkit-box-shadow: 6px 4px 15px -5px ${props=>props.color};
    -moz-box-shadow: 6px 4px 15px -5px ${props=>props.color};
    box-shadow: 6px 4px 15px -5px ${props=>props.color};
`;

export const RightPaperBox=styled.div`
    color:${props=>props.color};
    background-color:${props=>props.bgColor};
    width:30px;
    height:30px;
    border-radius:50%;
    position:relative;
    top:-25px;
    font-weight:400;
    display:flex;
    font-size:13px;
    justify-content:center;
    align-items:center;
    -webkit-box-shadow: 6px 4px 15px -5px ${props=>props.color};
    -moz-box-shadow: 6px 4px 15px -5px ${props=>props.color};
    box-shadow: 6px 4px 15px -5px ${props=>props.color};
`;

export const GloablTextColor=styled.input`
border-radius: 5px;
margin: 10px 10px;
outline: 1px solid ${props=>props.color};
position: relative;
width:200px;
height:50px;
padding:12px 4px;
background-color:transparent;
display:flex;
justify-content: center;
cursor:pointer;
align-items: center;
opacity:${props=>props.disabeldSection?0.5:1};
&:before{
    position: absolute;
    content: 'GloablText Color';
    background-color:${props=>props.bgColor};
    color:${props=>props.color};
    top:-12px;
    left: 10px;
};
`

export const BoxOfControllers=styled.div`
   width:60%;
   height:50px;
   outline:1px solid ${props=>props.color};
   position:relative;
   &:before{
    position: absolute;
    content: 'Controller Box';
    background-color:${props=>props.bgColor};
    color:${props=>props.color};
    top:-12px;
    left: 10px;
};
`

