
import React, {useEffect,useState} from 'react';
import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host, { FrontEndDomain } from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import FieldListForm from './FieldListForm';

import { useSelector,useDispatch } from 'react-redux';
import Grid from '../Share/Grid'
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language")?cookies.get("language"):"ar"
function FieldList() {
    const [t, i18n] = useTranslation('common');

    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        fieldLists: ''
    });
    const [showFieldListForm, setshowFieldListForm] = useState(false);
    const [selectedFieldList, setselectedFieldList] = useState(null);

    const [showDepartmentForm, setshowDepartmentForm] = useState(false);
    const [selectedDepartment, setselectedDepartment] = useState(null);
    const directions =useSelector(state=>state.settingsData.directions)
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const [rows, setRow] = useState([])
    const columns = [
        { field: 'id', headerName:t('FIELD_LISTS.ID'), flex: .1 },
        {
            field: 'fieldList_name', headerName: t('FIELD_LISTS.FIELD_LISTS_NAME'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.name}
                </div>
            )
        },

        {
            field: 'actions', flex: 1,
            headerName:  t('FIELD_LISTS.ACTIONS'),
            renderCell: (rows) => (
                <strong>
                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="view"
                        size="small"
                        onClick={() => {
                            window.open(FrontEndDomain + `field_list_values?id=${rows?.row?.id}`, '_blank')
                        }}>
                        <Tooltip title={directions == 'rtl'?'اضافة قيم':"add values"}>
                            <AddIcon style={{ fill: "#111827" }}  />
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="view"
                        size="small"
                        onClick={() => {
                            updateObject(rows?.row)
                        }}>
                        <Tooltip title={directions == 'rtl'?'تعديل':"edit"}>
                            <EditIcon style={{ fill: "#111827" }}  />
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="view"
                        size="small"
                        onClick={() => {
                            deleteObject(rows?.row);
                        }}>
                        <Tooltip title={directions == 'rtl'?'حذف':"delete"}>
                            <DeleteIcon style={{ fill: "#f00" }}  />
                        </Tooltip>
                    </IconButton>
                </strong>
            ),
        },
    ]
    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }
    useEffect(() => {        
        fetchData(pageNumber);
    }, [pageNumber])

    useEffect(() => {
        if (state && state.fieldLists&&state.fieldLists?.data?.length > 0) {
            setRow(state?.fieldLists?.data);
            setPointsTotal(state?.fieldLists?.total)
            setPointsNumberOfPages(state?.fieldLists?.last_page)
        }
    }, [state])

    const fetchData = async (pageNumber = 1) => {
        setloading(true)
        try {
            const result=await axios({
                url: Host + "field_lists?page="+pageNumber,
                method: "get",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                const fieldLists=result.data ;
                
                setData({
                    fieldLists:fieldLists 
                });
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
                   
    };
    const updateObject = async (object) => {
      
        
        setselectedFieldList(object)
        setshowFieldListForm(true)
    };
    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result=await axios({
                url: Host + "field_lists/"+object.id,
                method: "delete",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                toast.error(result.data.data)
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    
    const createObject = async (object) => {
        setselectedFieldList(null)
        setshowFieldListForm(true)
    };

    const handleSetshowFieldListForm = (openStatus,needsReload) => {
      if(needsReload)
      fetchData();
      setshowFieldListForm(openStatus)
    };
    
    
    useEffect(() => {
        fetchData();
    }, [])
    
    return (
        <>
            {showFieldListForm?
                <FieldListForm fieldList={selectedFieldList} showFieldListForm={showFieldListForm} setshowFieldListForm={handleSetshowFieldListForm} />
            :null}
         

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={pointsTotal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={true}
              createObject={createObject}
              title={t('FIELD_LISTS.TITLE')}
            //   title_ar="قائمة قوائم الحقول"
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}

            />
        </>
    );
}

export default FieldList;



// <div className="container">
// <div className="row justify-content-center">
//     <div className="col-md-12">
//         <div className="card">
//             <div className="card-header">FieldList List <Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
//             <div className="card-body">
//             <table>
//                 <thead>
//                     <tr>
//                         <th>1</th>
//                         <th>FieldList Name</th>
//                         <th>Add Values</th>
//                         <th>edit</th>
//                         <th>delete</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                 {   
//                     state?.fieldLists?.data ? 
//                         state?.fieldLists?.data?.map((object) => (
//                                 <tr key={object?.id}>
//                                     <td>{object?.id}</td>
//                                     <td>{object?.name}</td>
//                                     <td><Button onClick={()=>{
                                        
//                                         window.open(FrontEndDomain + `field_list_values?id=${object?.id}`, '_blank')
//                                     }}>Add Values</Button></td>
//                                     <td><Button onClick={()=>{updateObject(object)}}>update</Button></td>
//                                     <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                    
//                                 </tr>
//                         )) : "Loading..."
//                 }
//                 </tbody>
//             </table>
//                 <div>
//                     <Pagination
//                         activePage={state?.fieldLists?.current_page ? state?.fieldLists?.current_page : 0}
//                         itemsCountPerPage={state?.fieldLists?.per_page ? state?.fieldLists?.per_page : 0 }
//                         totalItemsCount={state?.fieldLists?.total ? state?.fieldLists?.total : 0}
//                         onChange={(pageNumber) => {
//                             fetchData(pageNumber)
//                         }}
//                         pageRangeDisplayed={8}
//                         itemClass="page-item"
//                         linkClass="page-link"
//                         firstPageText="First Page"
//                         lastPageText="Last Lage"
//                     />
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// </div>