
import React, {useEffect,useState} from 'react';
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host,{FrontEndDomain} from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import JourneyForm from './JourneyForm';
import JourneyMapDialog from './JourneyMapDialog';
import CircularIndeterminate from  '../GeneralComponents/CirularProgress'
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@material-ui/core/IconButton";
// import Pagination from '@mui/material/Pagination';
import Pagination from '../Share/Pagination'
import {useSelector,useDispatch} from 'react-redux'

import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import Box from '@mui/material/Box';
import { useGlobalContext } from '../../../context'
import Grid from '../Share/Grid'
import {useTranslation} from "react-i18next";

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
function Journeys() {
    const [t, i18n] = useTranslation('common');
    const [pageSize, setPageSize] = useState(5);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const {loading,journies,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObject} = useGlobalContext()
    // const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        journeys: ''
    });
    const directions = useSelector(state => state.settingsData.directions)

    const [showJourneyForm, setshowJourneyForm] = useState(false);
    const [showJourneyImportForm, setshowJourneyImportForm] = useState(false);
    const [showJourneyMapDialog,setshowJourneyMapDialog] = useState(false)
    const [selectedJourney, setselectedJourney] = useState(null);
    

    const advanceSearch = ()=>{         
        fetchData('JOURNIES','users_plans',{
            page:pageNumber,
            page_size:pageSize,
            // shop_name:shopName,
            // owner_name:ownerName,
            // district:district.id,
            // region:region.id,
            // point_type_id:pointStatus.map((status)=>status.id),
        });                        
    }

    // const fetchData = async (pageNumber = 1) => {
    //     setloading(true)
    //     try {
            
    //         const result=await axios({
    //             url: Host + "users_plans",
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             params:{
    //                 page:pageNumber
    //             }
        
    //         })
    //         if(result && result.status === 200)
    //         {
    //             const journeys=result.data ;
                
    //             console.log(journeys);
    //             setData({
    //                 journeys:journeys 
    //             });
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
                   
    // };
    // const updateObject = async (object) => {
      
    //     setselectedJourney(object)
    //     setshowJourneyForm(true)
    // };
    
    
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "journeys/"+object.id,
    //             method: "delete",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    // const activateObject = async (object) => {
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "journeys/activate",
    //             method: "post",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             data:{
    //                 journey_id:object.id,
    //                 active:object.active==1?0:1
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    // const createObject = async (object) => {
    //     setselectedJourney(null)
    //     setshowJourneyForm(true)
    // };

    // const importObjects = async ()=>{
    //     setselectedJourney(null)
    //     setshowJourneyImportForm(true)
    // }
    // const handleSetshowJourneyForm = (openStatus,needsReload) => {
    //   if(needsReload)
    //   fetchData();
    //   setshowJourneyForm(openStatus)
    // };
    // const handleSetshowJourneyImportForm = (openStatus,needsReload) => {
    //     if(needsReload)
    //     fetchData();
    //     setshowJourneyImportForm(openStatus)
    //   };

    // const mapObject= async (object)=>{
    //     setselectedJourney(object)
    //     setshowJourneyMapDialog(true)
    // }
    // const handleSetshowJourneyMapDialog = (openStatus,needsReload)=>{
    //     if(needsReload)
    //         fetchData();
    //     setshowJourneyMapDialog(openStatus)
    // }
    
    
    useEffect(() => {
        advanceSearch();
    }, [pageSize,pageNumber]) 
    
    useEffect(() => {        
        if (journies && journies.data) {                                    
            setRow(journies.data); 
            setPointsTotal(journies.total) 
            setPointsNumberOfPages(journies.last_page)                    
        }
    }, [journies])

    const columns = [
        { field: 'id', headerName: t('JOURNEYS.ID') , flex: .08},
        { field: 'full_name', headerName:  t('JOURNEYS.USERNAME') , flex: 1.5},
        { field: 'phone', headerName:  t('JOURNEYS.PHONE') , flex: 1.5},
        { field: 'daily_journey_plans_count', headerName:  t('JOURNEYS.COUNT') , flex: 1.5},
        { field: 'today_plan', headerName:  t('JOURNEYS.TODAY_PLAN') , flex: 1.5},                
        { field: 'actions',flex: .8,
            headerName:  t('JOURNEYS.ACTIONS'),                
            renderCell: (param) => (
              <strong>                
 
                <IconButton  
                    style={{ marginLeft: 15 }}
                    aria-label="action" 
                    size="small" 
                    onClick={() => {     
                        // console.log(param.row)                    
                        // updateObject(param.row)
                        window.open(FrontEndDomain + `user_daily_plans?id=${param.row.id}`, '_blank')
                    }}
                >
                    <Tooltip title="View">                    
                        <RemoveRedEyeRoundedIcon color="primary" />
                    </Tooltip>
                </IconButton>               
              </strong>
            ),
        },          
    ]; 
    
    const [rows,setRow] = useState([])    
 
    const handlePageChange = (newPage) => { 
        console.log(newPage+1)       
        setpageNumber(newPage+1)  
      }
    return (
        <>     

            

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={pointsTotal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={false}
              createObject={null}
              title_en=""
              title_ar=""
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />  


            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">Users daily journey plans List </div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>Name</th>
                                        <th>phone</th>
                                        <th>Total plans</th>
                                        <th>today plan</th>
                                        <th>view</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.journeys?.data ? 
                                        state?.journeys?.data?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>{object?.full_name}</td>
                                                    <td>{object?.phone}</td>
                                                    <td>{object?.daily_journey_plans_count}</td>
                                                    <td>{object?.today_plan}</td>
                                                    
                                                    <td><Button onClick={()=>{
                                                        
                                                        window.open(FrontEndDomain + `user_daily_plans?id=${object?.id}`, '_blank')
                                                    }}>View</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.journeys?.current_page ? state?.journeys?.current_page : 0}
                                        itemsCountPerPage={state?.journeys?.per_page ? state?.journeys?.per_page : 0 }
                                        totalItemsCount={state?.journeys?.total ? state?.journeys?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Journeys;


