import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog, Textarea,FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Table } from "react-bootstrap";
import {Avatar} from "evergreen-ui"
import helpers from "../../../assets/js/helper";
import { Divider } from "@material-ui/core";
import APP_CONSTANTS from "../../../assets/js/constants";
import { useGlobalContext } from '../../../context'
import { useSelector,useDispatch } from "react-redux";
import DialogForm from "../Share/DialogForm";
import FooterBottons from '../Share/FooterBottons'
import CustomeCheckBox from "../Share/CustomeCheckBox";
import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

export const PointRequestForm = (props) => {
    const {selectedPointRequestStatus,setSelectedPointRequestStatus} = useGlobalContext() 
    const directions =useSelector(state => state.settingsData.directions);
    const MainTheme =useSelector(state => state.settingsData.MainTheme);
    const [t, i18n] = useTranslation('common');

    const [selectedFields,setSelectedFields]=useState([
        {
            id:1,
            name:"name",
            selected:false,
            has_sub_name:false,
            value_name:"name",
            has_changes:false
        },
        {
            id:2,
            name:"owner_name",
            selected:false,
            has_sub_name:false,
            value_name:"owner_name",
            has_changes:false
        },
        {
            id:3,
            name:"owner_type",
            title:t('POINTS_REQUESTS.OWNER_TYPE'),
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"owner_type_id"
        },
        {
            id:4,
            name:"phone",
            title:t('POINTS_REQUESTS.PHONE'),
            has_sub_name:false,
            selected:false,
            value_name:"phone"
        },
        {
            id:5,
            name:"near_by",
            title:t('POINTS_REQUESTS.FORMVIEW_NEAR_BY'),
            has_sub_name:false,
            selected:false,
            value_name:"near_by"
        },
        {
            id:6,
            name:"location_category",
            title:t('POINTS_REQUESTS.FORMVIEW_LOCATION_CATEGORY'),
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"location_category_id"
        },
        {
            id:7,
            name:"classification",
            title:t('POINTS_REQUESTS.FORMVIEW_CLASSIFICATION'),
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"classification_id"
        },
        {
            id:8,
            name:"category",
            title:t('POINTS_REQUESTS.FORMVIEW_CATEGORY'),
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"category_id"
        },
        // {
        //     id:9,
        //     name:"point_type",
        //     sub_name:"name",
        //     has_sub_name:true,
        //     selected:false,
        //     value_name:"point_type_id"
        // },
        {
            id:10,
            name:"region",
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"region_id"
        },
        {
            id:11,
            name:"latitude",
            has_sub_name:false,
            selected:false,
            value_name:"latitude"
        },
        {
            id:11,
            name:"longitude",
            has_sub_name:false,
            selected:false,
            value_name:"longitude"
        },
        {
            id:12,
            name:"has_delivery",
            has_sub_name:false,
            selected:false,
            value_name:"has_delivery"
        },
        {
            id:13,
            name:"image",
            title:t('POINTS_REQUESTS.FORMVIEW_IMAGE'),
            has_sub_name:false,
            selected:false,
            value_name:"image"
        },
        {
            id:14,
            name:"phones",
            has_sub_name:false,
            selected:false,
            value_name:"phones"
        },
        {
            id:15,
            name:"images",
            has_sub_name:false,
            selected:false,
            value_name:"images"
        }
    ]);

    let selectedFields_list=[
        {
            id:1,
            name:"name",
            selected:false,
            has_sub_name:false,
            value_name:"name",
            has_changes:false
        },
        {
            id:2,
            name:"owner_name",
            selected:false,
            has_sub_name:false,
            value_name:"owner_name",
            has_changes:false
        },
        {
            id:3,
            name:"owner_type",
            title:t('POINTS_REQUESTS.OWNER_TYPE'),
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"owner_type_id"
        },
        {
            id:4,
            name:"phone",
            title:t('POINTS_REQUESTS.PHONE'),
            has_sub_name:false,
            selected:false,
            value_name:"phone"
        },
        {
            id:5,
            name:"near_by",
            title:t('POINTS_REQUESTS.FORMVIEW_NEAR_BY'),
            has_sub_name:false,
            selected:false,
            value_name:"near_by"
        },
        {
            id:6,
            name:"location_category",
            title:t('POINTS_REQUESTS.FORMVIEW_LOCATION_CATEGORY'),
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"location_category_id"
        },
        {
            id:7,
            name:"classification",
            title:t('POINTS_REQUESTS.FORMVIEW_CLASSIFICATION'),
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"classification_id"
        },
        {
            id:8,
            name:"category",
            title:t('POINTS_REQUESTS.FORMVIEW_CATEGORY'),
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"category_id"
        },
        // {
        //     id:9,
        //     name:"point_type",
        //     sub_name:"name",
        //     has_sub_name:true,
        //     selected:false,
        //     value_name:"point_type_id"
        // },
        {
            id:10,
            name:"region",
            sub_name:"name",
            has_sub_name:true,
            selected:false,
            value_name:"region_id"
        },
        {
            id:11,
            name:"latitude",
            has_sub_name:false,
            selected:false,
            value_name:"latitude"
        },
        {
            id:11,
            name:"longitude",
            has_sub_name:false,
            selected:false,
            value_name:"longitude"
        },
        {
            id:12,
            name:"has_delivery",
            has_sub_name:false,
            selected:false,
            value_name:"has_delivery"
        },
        {
            id:13,
            name:"image",
            title:t('POINTS_REQUESTS.FORMVIEW_IMAGE'),
            has_sub_name:false,
            selected:false,
            value_name:"image"
        },
        {
            id:14,
            name:"phones",
            has_sub_name:false,
            selected:false,
            value_name:"phones"
        },
        {
            id:15,
            name:"images",
            has_sub_name:false,
            selected:false,
            value_name:"images"
        }
    ]

    useEffect(()=>{
        // selectedFields_list=selectedFields
    },[selectedFields])

    const [loading, setloading] = useState(true);
    const [pointRequestStatuses,setPointRequestStatuses] = useState([])
    const [selectedStatus,setSelectedStatus] = useState(1)
    const [hasAnyChanges,sethasAnyChanges] = useState(false)
    

    const objectsEqual = (o1, o2) =>{
        return Object.keys(o1).length === Object.keys(o2).length 
        && Object.keys(o1).every(p => o1[p] === o2[p]);
    }

    const getResources = async () => {
        try {
            setloading(true)

            setSelectedFields(selectedFields.map((field)=>{
                
                if(field.has_sub_name)
                {
                    const pointField = props.point[field.name] && props.point[field.name][field.sub_name]?props.point[field.name][field.sub_name]:null;
                    const pointRequestField = props.pointRequest[field.name] && props.pointRequest[field.name][field.sub_name]?props.pointRequest[field.name][field.sub_name]:null;

                    if((!pointField && !pointRequestField)
                    || (pointField && pointRequestField && pointField==pointRequestField))
                    return {
                        ...field,
                        has_changes:false
                    }
                    
                    else{
                        sethasAnyChanges(true)
                        return {
                            ...field,
                            has_changes:true
                        }
                    }
                }
                else if (field.name==="images")
                {
                    var allElementsAreTheSame=true;
                    if(props 
                        && props.point 
                        && props.pointRequest 
                        && props.point.other_images 
                        && props.pointRequest.other_images
                        && props.point.other_images.length>0
                        && props.pointRequest.other_images.length>0
                        && props.pointRequest.other_images.length === props.point.other_images.length
                        )
                    {
                        console.log("in");
                        
                        for (let index = 0; index < props.pointRequest.other_images.length; index++) {
                            const element = props.pointRequest.other_images[index];
                            var hasMatch=false
                            for (let index2 = 0; index2 < props.point.other_images.length; index2++) {
                                const element2 = props.point.other_images[index2];
                                if(objectsEqual(element, element2))
                                    hasMatch=true
                                
                            }
                            if(!hasMatch)
                            {
                                allElementsAreTheSame=false
                                break
                            }
                            
                            
                        }
                        for (let index = 0; index < props.point.other_images.length; index++) {
                            const element = props.point.other_images[index];
                            var hasMatch=false
                            for (let index2 = 0; index2 < props.pointRequest.other_images.length; index2++) {
                                const element2 = props.pointRequest.other_images[index2];
                                if(objectsEqual(element, element2))
                                    hasMatch=true
                            }
                            if(!hasMatch)
                            {
                                allElementsAreTheSame=false
                                break
                            }
                        }
                    }
                    else if(props 
                        && props.point 
                        && props.pointRequest 
                        && props.point.other_images 
                        && props.pointRequest.other_images
                        && props.point.other_images.length==0
                        && props.pointRequest.other_images.length==0
                    )
                    {
                        
                        allElementsAreTheSame=true
                    }
                    else{
                        allElementsAreTheSame=false
                    }

                    if(allElementsAreTheSame)
                    {
                        return {
                            ...field,
                            has_changes:false
                        }
                    }
                    else{
                        sethasAnyChanges(true)
                        return {
                            ...field,
                            has_changes:true
                        }
                    }
                }
                else if (field.name==="phones")
                {
                    var allElementsAreTheSame=true;
                    if(props 
                        && props.point 
                        && props.pointRequest 
                        && props.point.phones 
                        && props.pointRequest.phones
                        && props.point.phones.length>0
                        && props.pointRequest.phones.length>0
                        && props.pointRequest.phones.length == props.point.phones.length
                        )
                    {
                        console.log("in");

                        
                        for (let index = 0; index < props.pointRequest.phones.length; index++) {
                            const element = props.pointRequest.phones[index];
                            var hasMatch=false
                            for (let index2 = 0; index2 < props.point.phones.length; index2++) {
                                const element2 = props.point.phones[index2];
                                if(objectsEqual(element, element2))
                                    hasMatch=true
                                
                            }
                            if(!hasMatch)
                            {
                                allElementsAreTheSame=false
                                break
                            }
                            
                            
                        }
                        for (let index = 0; index < props.point.phones.length; index++) {
                            const element = props.point.phones[index];
                            var hasMatch=false
                            for (let index2 = 0; index2 < props.pointRequest.phones.length; index2++) {
                                const element2 = props.pointRequest.phones[index2];
                                if(objectsEqual(element, element2))
                                    hasMatch=true
                            }
                            if(!hasMatch)
                            {
                                allElementsAreTheSame=false
                                break
                            }
                        }
                    }
                    else if(props 
                        && props.point 
                        && props.pointRequest 
                        && props.point.phones 
                        && props.pointRequest.phones
                        && props.point.phones.length==0
                        && props.pointRequest.phones.length==0
                    )
                    {
                        
                        allElementsAreTheSame=true
                    }
                    else{
                        allElementsAreTheSame=false
                    }

                    if(allElementsAreTheSame)
                    {
                        return {
                            ...field,
                            has_changes:false
                        }
                    }
                    else{
                        sethasAnyChanges(true)
                        return {
                            ...field,
                            has_changes:true
                        }
                    }
                }
                else if(field.name==="image"){
                    var pointProfileImageUrl=props.point?.profile_image && props.point?.profile_image.length>0?
                    props.point?.profile_image[0].image_url:null
                    var pointRquestProfileImageUrl=props.pointRequest?.profile_image && props.pointRequest?.profile_image.length>0?
                    props.pointRequest?.profile_image[0].image_url:null

                    if((!pointProfileImageUrl && !pointRquestProfileImageUrl) || (pointProfileImageUrl && pointRquestProfileImageUrl && pointProfileImageUrl==pointRquestProfileImageUrl))
                    return {
                        ...field,
                        has_changes:false
                    }
                    else{
                        sethasAnyChanges(true)
                        return {
                            ...field,
                            has_changes:true
                        }
                    }
                }
                else{
                    const pointField = props.point[field.name] ?props.point[field.name]:null;
                    const pointRequestField = props.pointRequest[field.name] ?props.pointRequest[field.name]:null;

                    // console.log("pointField",pointField);
                    // console.log("pointRequestField",pointRequestField);
                    // console.log("compare status",(!pointField && !pointRequestField) || (pointField && pointRequestField && pointField===pointRequestField));
                    if((!pointField && !pointRequestField) || (pointField && pointRequestField && pointField===pointRequestField))
                    return {
                        ...field,
                        has_changes:false
                    }
                    else{
                        sethasAnyChanges(true)
                        return {
                            ...field,
                            has_changes:true
                        }
                    }
                }
            }))


            var resourcesResult = await axios({
                url: Host + "point_request_statuses_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    setPointRequestStatuses(resources.filter((resource=>resource.id>2)).map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error owner")
            }
            

            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {
        
        try {
            setloading(true)
            var okContinue=true;
            
            var tempStatus=selectedStatus;
            if(selectedStatus===APP_CONSTANTS.POINT_REQUEST_STATUS_APPROVED)
            {

                let formdata = new FormData();

                var partiallyApproved=false;
                var anythingSelected=false;
                selectedFields.map((field)=>{
                    
                    if(field.selected && field.name==="image")
                    {                        
                        if(props?.pointRequest?.profile_image && props?.pointRequest?.profile_image?.length>0 && props?.pointRequest?.profile_image[0].image_url)
                        {
                            anythingSelected=true;
                            formdata.append(field.value_name,props?.pointRequest?.profile_image[0].image_url)
                        }
                        
                    }
                    else if(field.selected && field.name==="phones")
                    {
                        
                        if(props?.pointRequest?.phones && props?.pointRequest?.phones?.length>0 )
                        {
                            props?.pointRequest?.phones.map((phone,index)=>{
                                formdata.append("phones["+index+"][phone]",phone.phone)
                                formdata.append("phones["+index+"][name]",phone.name)
                                formdata.append("phones["+index+"][job_title]",phone.job_title)
                            })
                        }
                        anythingSelected=true;
                        
                    }
                    else if(field.selected && field.name==="images")
                    {
                        
                        if(props?.pointRequest?.other_images && props?.pointRequest?.other_images?.length>0 )
                        {
                            props?.pointRequest?.other_images.map((image,index)=>{
                                formdata.append("images["+index+"][image_url]",image.image_url)
                                formdata.append("images["+index+"][thumbnail_image_url]",image.thumbnail_image_url)
                            })
                        }
                        anythingSelected=true;
                        
                    }
                    else if(field.selected && field.name!=="image")
                    {
                        anythingSelected=true;
                        if(field.value_name==="phone" && !props.pointRequest[field.value_name])
                        {
                            formdata.append("remove_phone", true)
                        }
                        
                        else
                        formdata.append(field.value_name, props.pointRequest[field.value_name])
                    }
                    else if(field.has_changes && !field.selected)
                    {
                        partiallyApproved=true;
                    }
                    
                })
                if(!anythingSelected && hasAnyChanges)
                {
                    toast.error("nothing selected")
                    return;
                }
                
                
                if (props.pointRequest.point_id && hasAnyChanges) {
                    
                    formdata.append("_method", "PUT");
                    // console.log('formdata')
                    // console.log(formdata)
                    const resourcesResult = await axios({
                        url: Host + "request_update_points/"+props.pointRequest.point_id,
                        method: "post",
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Authorization": `Bearer ${token}`,
                            Accept: "application/json",
                            'X-localization': language,
                        },
                        data: formdata
                    })
                    
                    if (resourcesResult) {
                        if (resourcesResult.status === 200) {
                            okContinue=true;                            
                        } else if (resourcesResult.data.status === false) {
                            props.setshowPointRequestForm(true,false)
                        }
                    }
                    else {
                        toast.error("Error")
                        props.setshowPointRequestForm(true,false)
                    }
                }
                tempStatus=partiallyApproved?APP_CONSTANTS.POINT_REQUEST_STATUS_PARTIALLY_APPROVED:tempStatus
            }
            if(okContinue)
            {
                
                let formdata1 = new FormData();
                formdata1.append("point_request_status_id",tempStatus)
                
                formdata1.append("_method", "PUT");
                
                const resourcesResult1 = await axios({
                    url: Host + "point_requests/status/"+props.pointRequest.id,
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata1
                })
                if (resourcesResult1) {
                    if (resourcesResult1.status === 200) {
                        setSelectedPointRequestStatus(tempStatus)
                        // console.log('selectedPointRequestStatus')
                        // console.log(selectedPointRequestStatus)
                        toast.success("updated ")
                        props.setshowPointRequestForm(false,true)
                    
                    } else if (resourcesResult1.data.status === false) {
                        props.setshowPointRequestForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowPointRequestForm(true,false)
                }
            }
            setloading(false)
        } catch (error) {
            props.setshowPointRequestForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const handleInputChange = (event,field_name) => {
        const target = event.target;
        
        if(target.checked )
        {
            if(field_name==="select_all")
            {
                setSelectedFields(selectedFields.filter(item=>item.has_changes).map((item)=>{
                    return {
                        ...item,
                        selected:true
                    }
                }))
            }
            else{
                setSelectedFields(selectedFields.map((item)=>{
                    if(item.value_name===field_name)
                    {
                        return {
                            ...item,
                            selected:true
                        }
                    }
                    else{
                        return item
                    }
                }))
            }
        }
        else
        {
            if(field_name==="select_all")
            {
                setSelectedFields(selectedFields.map((item)=>{
                    return {
                        ...item,
                        selected:false
                    }
                }))
            }
            else{
                setSelectedFields(selectedFields.map((item)=>{
                    if(item.value_name===field_name)
                    {
                        return {
                            ...item,
                            selected:false
                        }
                    }
                    else{
                        return item
                    }                    
                }))
            }
        }        
    };

    const handleSubmit=(e)=>{
        e.preventDefault();
    }

    useEffect(() => {
        // console.log(props.pointRequest);
        // console.log("point"); 
        // console.log(props.point);
        if(loading)
            getResources()

        return () => {

        };
    }, [loading,props.showPointRequestForm]);

    const colorStyle={
        color:MainTheme?.paperTextColor,
        textAlign:directions=='rtl'?'right':'left',
    }

    return (
        loading ? <></> :
        <DialogForm
        open={props.showPointRequestForm}
        close={props.setshowPointRequestForm}
        >     
            <Table striped bordered >
                <thead style={{backgroundColor:MainTheme?.paperTextColor+'66'}}>
                    <tr >
                        <th style={{...colorStyle}} >{t('POINTS_REQUESTS.FORMVIEW_FIELD_NAME')}</th>
                        <th style={{...colorStyle}}>{t('POINTS_REQUESTS.FORMVIEW_REQUEST_CHANGES')}</th>
                        {!hasAnyChanges?null:
                            <>
                            <th  style={{visibility:hasAnyChanges?'visible':'hidden',...colorStyle}}>{t('POINTS_REQUESTS.FORMVIEW_ORIGINAL_POINT')}</th>
                            
                            <th   style={{visibility:hasAnyChanges?'visible':'hidden',...colorStyle}}>{t('POINTS_REQUESTS.FORMVIEW_APPROVE_OR_REJECT')}</th>
                            </>
                        }
                    </tr>
                </thead>
                <tbody style={{...colorStyle}}>
                {selectedFields.map((field)=>{
                    // var pointTitle=field.has_sub_name?props.point[field.name] && props.point[field.name][field.sub_name]?props.point[field.name][field.sub_name]:null:props.point[field.name]
                    // var pointRequestTitle=field.has_sub_name?props.pointRequest[field.name] && props.pointRequest[field.name][field.sub_name]?props.pointRequest[field.name][field.sub_name]:null:props.pointRequest[field.name]

                    if(field.value_name ==="image")
                    {                            
                        try
                        {
                            var pointProfileImageUrl=props.point?.profile_image && props.point?.profile_image.length>0?
                            props.point?.profile_image[0].image_url:null
                            var pointRquestProfileImageUrl=props.pointRequest?.profile_image && props.pointRequest?.profile_image.length>0?
                            props.pointRequest?.profile_image[0].image_url:null

                            field.has_changes=pointProfileImageUrl!=pointRquestProfileImageUrl

                        }
                        catch(error)
                        {
                            console.log(field);
                            console.log(error);
                        }
                        if(field.has_changes || !hasAnyChanges)
                        return (
                        <tr>
                        <td>{t('POINTS_REQUESTS.FORMVIEW_IMAGE')}</td>
                        <td style={{backgroundColor:"white"}}>
                            {pointRquestProfileImageUrl?                                
                                <Avatar
                                    src={helpers.getImageUrl(props.pointRequest?.profile_image[0]?.image_url)}
                                    name={props.pointRequest?.name}
                                    size={40}
                                />
                                
                            :
                            <Avatar                                        
                                    name={props.pointRequest?.name}
                                    size={40}
                            />}
                            
                        </td>
                        {!hasAnyChanges?null:
                            <>
                                <td style={{visibility:hasAnyChanges?'visible':'hidden'}}>
                                {pointProfileImageUrl?
                                    
                                        <Avatar
                                            src={helpers.getImageUrl(props.point?.profile_image[0]?.image_url)}
                                            name={props.point?.name}
                                            size={40}
                                        />
                                    :
                                    <Avatar
                                            
                                            name={props.point?.name}
                                            size={40}
                                    />}
                                </td>
                                <td style={{visibility:hasAnyChanges?'visible':'hidden'}}> 
                                        
                                        <CustomeCheckBox
                                            HandleChange={handleInputChange}
                                            data={field}
                                            IdOrName={false}
                                            selected={field.selected}
                                            labelName={t('POINTS_REQUESTS.FORMVIEW_MESSAGE_APPROVE')}
                                        />
                                </td>
                            </>
                        }
                    </tr>)
                    }
                    else if(field.value_name==="images")
                    {                                                       
                        if(field.has_changes || !hasAnyChanges)
                        return(
                            <tr>
                                <td>
                                {t('POINTS_REQUESTS.FORMVIEW_IMAGE')}

                                </td>
                                <td>
                                    {props.pointRequest && props.pointRequest.other_images && props.pointRequest?.other_images.length>0?
                                    props.pointRequest?.other_images.map((image)=>{
                                        
                                        return <Avatar
                                        src={helpers.getImageUrl(image?.image_url)}
                                        name={props.pointRequest?.name}
                                        size={40}
                                    />;
                                    })
                                    :null}
                                </td>
                                {!hasAnyChanges?null:
                                <>
                                <td>
                                    {props.point && props.point.other_images && props.point?.other_images.length>0?
                                    props.point?.other_images.map((image)=>{
                                        
                                        return <Avatar
                                        src={helpers.getImageUrl(image?.image_url)}
                                        name={props.point?.name}
                                        size={40}
                                    />;
                                    })
                                    :null}
                                </td>
                                <td style={{visibility:hasAnyChanges?'visible':'hidden'}}> 
                                   
                                    <CustomeCheckBox
                                            HandleChange={handleInputChange}
                                            data={field}
                                            IdOrName={false}
                                            selected={field.selected}
                                            labelName={t('POINTS_REQUESTS.FORMVIEW_MESSAGE_APPROVE')}
                                    />
                                </td>
                                </>
                                }
                            </tr>

                        )
                    
                    }
                    else if(field.value_name==="phones")
                    {
                        if(field.has_changes || !hasAnyChanges)
                        return(
                            <tr>
                                <td>
                                    {t('POINTS_REQUESTS.PHONE')}
                                </td>
                                <td>
                                    {props.pointRequest && props.pointRequest.phones && props.pointRequest?.phones.length>0?
                                    props.pointRequest?.phones.map((phone)=>{
                                        return <p>{"Name:"+phone.name+"- Phone:"+phone.phone+"- Job Title:"+phone.job_title}</p>;
                                    })
                                    :null}
                                </td>
                                {!hasAnyChanges?null:
                                <>
                                <td>
                                {props.point && props.point.phones && props.point?.phones.length>0?
                                    props.point?.phones.map((phone)=>{
                                        return <p>{"Name:"+phone.name+"- Phone:"+phone.phone+"- Job Title:"+phone.job_title}</p>;
                                    })
                                    :null}
                                </td>
                                <td style={{visibility:hasAnyChanges?'visible':'hidden'}}> 
                                    
                                    <CustomeCheckBox
                                            HandleChange={handleInputChange}
                                            data={field}
                                            IdOrName={false}
                                            selected={field.selected}
                                            labelName={t('POINTS_REQUESTS.FORMVIEW_MESSAGE_APPROVE')}
                                        />
                                </td>
                                </>}
                            </tr>

                        )
                    
                    }
                    else{
                        try
                        {
                            var pointTitle=field.has_sub_name && props.point[field.name]?props.point[field.name][field.sub_name]:props.point[field.name]
                            var pointRequestTitle=field.has_sub_name && props.pointRequest[field.name]?props.pointRequest[field.name][field.sub_name]:props.pointRequest[field.name]    
                        }
                        catch(error)
                        {
                            console.log(field);
                            console.log(error);
                        }
                        if(field.has_changes || !hasAnyChanges)
                        return (
                        <tr>
                            <td>{field.title} </td>
                            <td style={{backgroundColor:!field.has_changes?"white":"pink"}}>{pointRequestTitle}</td>
                            {!hasAnyChanges?null:
                            <>
                            <td style={{visibility:hasAnyChanges?'visible':'hidden'}}>{pointTitle}</td>
                            <td style={{visibility:hasAnyChanges?'visible':'hidden'}}> 
                                
                                <CustomeCheckBox
                                            HandleChange={handleInputChange}
                                            data={field}
                                            IdOrName={false}
                                            selected={field.selected}
                                            labelName={t('POINTS_REQUESTS.FORMVIEW_MESSAGE_APPROVE')}
                                        />
                            </td>
                            </>
                            }
                            
                        </tr>)
                    }
                    
                })}
                    
                    
                    <tr>
                        <td colSpan={hasAnyChanges?3:2}>
                        <label htmlFor="owner_type_id">{t('POINTS_REQUESTS.FORMVIEW_MESSAGE_SELECT_REQUEST_STATUS')}</label>
                                <Select onChange={e => { setSelectedStatus(e.value); }}
                                    defaultValue={pointRequestStatuses.filter(item=>item.value==selectedStatus)}
                                    value={pointRequestStatuses.filter(item=>item.value==selectedStatus)}
                                    styles={customStyles}
                                    options={pointRequestStatuses}
                                />
                        </td>
                        {!hasAnyChanges?null:
                            <>
                            <td style={{visibility:hasAnyChanges?'visible':'hidden'}}>
                              
                                <CustomeCheckBox
                                            HandleChange={handleInputChange}
                                            data={"select all"}
                                            IdOrName={"select_all"}
                                            selected={""}
                                            labelName={t('POINTS_REQUESTS.FORMVIEW_MESSAGE_SELECT_ALL')}
                                            SelectAll={true}
                                        />
                            </td>
                            </>
                        }
                    </tr>

                </tbody>
            </Table>
            
                
         
            <FooterBottons close={props.setshowPointRequestForm} submitForm={submitForm} />
        
            <br/>
            <br/>
            <br/>
            <br/>
     
        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '90%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default PointRequestForm;
