import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'
import { styled, useTheme } from '@mui/material/styles';

const StyledInput=styled(Box)(({theme,MainTheme})=>({
    width: '31%',
    maxWidth: '100%',
    color:MainTheme?.disabledGlobalText?MainTheme.globalTextColor:MainTheme?.paperTextColor,
    margin:'10px 5px'
}));
export default function CustomeInput({value,setValue,label,fieldName}) {
    const {t, i18n} = useTranslation('common');
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);
  
  return (
    <StyledInput MainTheme={MainTheme}>
      <TextField
       fullWidth 
       label={label}
       id="fullWidth" 
       value={value}
       onChange={(e)=>{
          setValue(e.target.value,fieldName)
       }}
      />
    </StyledInput>
  )
}
