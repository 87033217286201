import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import {useSelector,useDispatch} from 'react-redux'
import DialogForm from "../Share/DialogForm";
import FooterBottons from "../Share/FooterBottons";
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const FieldListValueForm = (props) => {
    const [loading, setloading] = useState(true);
    const [t, i18n] = useTranslation('common');

    const [object, setobject] = useState({
        id: null,
        value: null,
        field_list_id:props.fieldListId
        
    });
   

    const submitForm = async () => {
        
        try {
            
        
            if (object.id) {

                const resourcesResult = await axios({
                    url: Host + "field_list_values/"+object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowFieldListValueForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowFieldListValueForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowFieldListValueForm(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "field_list_values",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowFieldListValueForm(false,true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowFieldListValueForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowFieldListValueForm(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowFieldListValueForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };


    useEffect(() => {
        if(props&& props.fieldListValue&& props.fieldListValue.id>0)
        {
            setobject({...object,...props.fieldListValue});
        }
        else{
            setobject(
                {
                    id: null,
                    value: null,
                    field_list_id:props.fieldListId
                }
            )
        }

        
        return () => {

        };
    }, [props.showFieldListValueForm]);


    return (
        
        <DialogForm
            open={props.showFieldListValueForm}
            close={props.setshowFieldListValueForm}
            CustomeWidth="50%"
            CustomeHeight="60%"
        >
            <form >
                <div className="form-group">
                    <label htmlFor="value" style={{display:'flex',justifyContent:'start'}}>{t('FIELD_LISTS.FIELD_LIST_VALUE_FORMADD_NAME')}</label>
                    <input className="form-control" 
                        id="value"
                        value={object.value}
                        onChange={event => { setobject({ ...object, value: event.target.value }); }}
                     />
                </div>
               
            </form>
          <FooterBottons close={props.setshowFieldListValueForm} submitForm={submitForm} />
        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default FieldListValueForm;
