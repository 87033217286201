
import React, { useEffect, useState } from 'react';
// import Pagination from "react-js-pagination";
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TelegramIcon from '@mui/icons-material/Telegram'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { toast } from 'react-toastify';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import Grid from '../Share/Grid'
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
function AttendanceReport() {
    const {t, i18n} = useTranslation('common');

    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)

    const [metaData, setMetaData] = useState({
        road_time: "00:00:00",
        visits_time: "00:00:00",
        total_work_time: "00:00:00",
        working_hours_per_day: 0,
        working_hours_per_day_color: "#DC3545",
        telegram_channel_id: "",
        telegram_token: ""
    })
  const directions= useSelector(state => state.settingsData.directions)
  const GridTheme =useSelector(state=>state.settingsData.GridTheme)

    const { loading, setLoading, fromToDate, exportToCSV, user, users, setUser, attendanceCalculations, clearPointsAdvanceSearchFields, fetchData, exportAttendanceToCSV } = useGlobalContext()

    const [accExpansion, setAccExpansion] = useState(false)
    const [accExpansionMeta, setAccExpansionMeta] = useState(false)

    const handleExpansion = (expansion) => setAccExpansion(expansion)
    const handleExpansionMeta = (expansionMeta) => setAccExpansionMeta(expansionMeta)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const advanceSearch = async (type = "BROWSING") => {
        console.log("tracking", 6);
        console.log("tracking user", user.id);
        console.log("user......");
        if( user?.id){
        const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            created_by: user.id,
            creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
            creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        })
        return fetchedData
        }
    }



    useEffect(() => {
        fetchData('USERS', 'users_list')
        advanceSearch()
    }, [pageSize, pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {

        if (attendanceCalculations && attendanceCalculations?.data && attendanceCalculations?.data?.data?.length > 0) {
            const reformatVisits = attendanceCalculations?.data?.data.map((rowVisit) => {
                return {
                    ...rowVisit,
                }
            })
            setRows(reformatVisits);
            setMetaData({
                ...metaData,
                ...attendanceCalculations?.data?.meta_data
            })
            setPointsTotal(attendanceCalculations.total)
            setPointsNumberOfPages(attendanceCalculations.last_page)
        }
        else {
            setRows([]);
            setMetaData({
                road_time: "00:00:00",
                visits_time: "00:00:00",
                total_work_time: "00:00:00",
                working_hours_per_day: 0,
                working_hours_per_day_color: "#DC3545"
            })
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [attendanceCalculations])


    const [rows, setRows] = useState([])
    const columns = [
        { field: 'id',flex:0.1, headerName: t('ATTENDANCE_REPORT.ID') },
        {
            field: 'road_time', headerName: t('ATTENDANCE_REPORT.ROAD_TIME'), flex:0.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.attendance_calculation?.road_time}
                </div>
            )
        },
        {
            field: 'start_date',flex:0.1, headerName: t('ATTENDANCE_REPORT.START_DATE'), flex:0.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows.row.start_date}
                </div>
            )
        },
        {
            field: 'end_date', headerName: t('ATTENDANCE_REPORT.END_DATE'), flex:0.7,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows.row.end_date}
                </div>)
        },
        {
            field: 'visit_total_time', headerName: t('ATTENDANCE_REPORT.VISIT_TIME'), flex:0.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.attendance_calculation?.visit_total_time}
                </div>
            )
        },
        {
            field: 'road_time_calculated', headerName: t('ATTENDANCE_REPORT.ROAD_CALC'), flex:0.5,
            renderCell: (rows) => (
                rows?.row?.attendance_calculation?.override_road_time === 1 ?
                    <div style={{ width: '100%' }} >

                        {rows?.row?.attendance_calculation?.road_time}
                    </div>
                    :
                    <div style={{ width: '100%' }} >

                        {rows?.row?.attendance_calculation?.road_time_calculated}
                    </div>
            )
        },
        {
            field: 'visit_total_time_calculated', headerName: t('ATTENDANCE_REPORT.VISIT_CALC'), flex:0.5,
            renderCell: (rows) => (
                rows?.row?.attendance_calculation?.override_visit_time === 1 ?
                    <div style={{ width: '100%' }} >
                        {rows?.row?.attendance_calculation?.visit_total_time}
                    </div>
                    :
                    <div style={{ width: '100%' }} >
                        {rows?.row?.attendance_calculation?.visit_total_time_calculated}
                    </div>
            )
        },
        {
            field: 'override_road_time', headerName: t('ATTENDANCE_REPORT.OVERRIDE_ROAD_TIME'), flex:0.5,
            renderCell: (rows) => (

                rows?.row?.attendance_calculation?.override_road_time === 1 ?
                    <Button onClick={() => { toggleOverrideRoadTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.override_road_time) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#57A773" }}>{t('ATTENDANCE_REPORT.ATTENDANCE_OVERRIDE')}</Button>
                    :
                    <Button onClick={() => { toggleOverrideRoadTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.override_road_time) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#EE6352" }}>{t('ATTENDANCE_REPORT.ATTENDANCE_NOT_OVERRIDE')}</Button>


            )
        },
        {
            field: 'override_visit_time', headerName: t('ATTENDANCE_REPORT.OVERRIDE_VISIT_TIME'), flex: 1, flex:0.5,
            renderCell: (rows) => (

                rows?.row?.attendance_calculation?.override_visit_time === 1 ?
                    <Button onClick={() => { toggleOverrideVisitTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.override_visit_time) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#57A773" }}>{t('ATTENDANCE_REPORT.ATTENDANCE_OVERRIDE')}</Button>
                    :
                    <Button onClick={() => { toggleOverrideVisitTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.override_visit_time) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#EE6352" }}>{t('ATTENDANCE_REPORT.ATTENDANCE_NOT_OVERRIDE')}</Button>

            )
        },

    ];

    const toggleOverrideRoadTime = async (id, value) => {
        console.log("toggleOverrideRoadTime", id + value);
        await toggleObject(id, value == 1 ? 0 : 1, "road")
    }
    const toggleOverrideVisitTime = async (id, value) => {
        console.log("toggleOverrideVisitTime", id + value);
        await toggleObject(id, value == 1 ? 0 : 1, "visit")
    }
    const toggleObject = async (id, value, type) => {
        console.log("tracking", 1);
        setLoading(true)
        console.log("tracking", 2);
        try {
            console.log("tracking", 3);
            const result = await axios({

                url: Host + "attendance_calculations_report/toggle_override",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    attendance_calculation_id: id,
                    value: value,
                    type: type
                }

            })
            console.log("tracking", 4);
            if (result && result.status == 200) {
                console.log("tracking", 5);
                advanceSearch("SEARCH")
                console.log("tracking", 7);
            } else if (result.data.status === false) {
                toast.error("Error")
                console.log("tracking", 8);
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                toast.error("network error")
            }
        }
        setLoading(false)
    };

    const sendTelegramMessage = (channel_id, token, ws_data, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        var workbook = XLSX.utils.book_new()
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        var file = new Blob([excelBuffer], { type: fileType });
        file.lastModifiedDate = new Date();
        file.name = fileName + '_' + Math.floor(Date.now() / 1000) + fileExtension;
        var formData = new FormData();
        formData.append('document', file, file.name);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://api.telegram.org/bot' + token + '/sendDocument?chat_id=' + channel_id, true);

        xhr.send(formData);
       
    }
    return (
        <>
            <Box display="flex" >
                {rows?.length > 0 ?
                    <>
                        <Button display="flex"
                            startIcon={<FileDownloadIcon sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor}}/>}
                            variant="contained"
                            sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor }}
                            onClick={async () => {
                                const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
                                    page_size: pointsTotal,
                                    created_by: user.id,
                                    creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
                                    creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
                                })
                                var visitsArray = []
                                fetchedData?.data?.data?.map((visit, index) => {
                                    const road_time_calculated = visit?.attendance_calculation?.override_road_time === 1 ? visit?.attendance_calculation?.road_time : visit?.attendance_calculation?.road_time_calculated
                                    const visit_total_time_calculated = visit?.attendance_calculation?.override_visit_time === 1 ? visit?.attendance_calculation?.visit_total_time : visit?.attendance_calculation?.visit_total_time_calculated

                                    visitsArray?.push([
                                        index + 1,
                                        visit?.point?.name,
                                        visit?.start_date,
                                        visit?.end_date,
                                        visit?.attendance_calculation?.road_time,
                                        visit?.attendance_calculation?.visit_total_time,
                                        road_time_calculated,
                                        visit_total_time_calculated,

                                    ])
                                })
                                const summaryValues1 = [
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    fetchedData?.data?.meta_data?.road_time,
                                    fetchedData?.data?.meta_data?.visits_time,
                                ]
                                const summaryValues2 = [
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    fetchedData?.data?.meta_data?.total_work_time,
                                    fetchedData?.data?.meta_data?.working_hours_per_day,
                                ]
                                // exportToCSV(fetchedData?.data, 'attendance_calculations_report')
                                var ws_data = [
                                    ["ت", "اسم النقطة", "وقت  بدء الزيارة", "وقت  انتهاء الزيارة", "وقت الطريق", "وقت الزيارة", "وقت الطريق المعتمد", "وقت الزيارة المعتمد"],
                                    ...visitsArray,
                                    // [1, "احمد", "2022-07-04 14:31:27", "2022-07-04 14:31:27", "14:31:27", "14:31:27", "14:31:27", "14:31:27"],
                                    ["", "", "", "", "", "", "مجموع الوقت المستغرق في الطريق", "مجموع الوقت المستغرق في الزيارات"],
                                    summaryValues1,
                                    ["", "", "", "", "", "", "مجموع وقت الدوام الكلي", "عدد الساعات المطلوبة"],
                                    summaryValues2,
                                ];
                                exportAttendanceToCSV(ws_data, 'attendance_calculations_report')
                            }
                            }>
                            {t('GLOBAL.SEARCH_EXPORT_BUTTON')}
                        </Button>
                        <Button display="flex"
                            startIcon={<TelegramIcon sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor}}/>}
                            variant="contained"
                            sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor }}
                            onClick={async () => {
                                const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
                                    page_size: pointsTotal,
                                    created_by: user.id,
                                    creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
                                    creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
                                })
                                var visitsArray = []
                                fetchedData?.data?.data?.map((visit, index) => {
                                    const road_time_calculated = visit?.attendance_calculation?.override_road_time === 1 ? visit?.attendance_calculation?.road_time : visit?.attendance_calculation?.road_time_calculated
                                    const visit_total_time_calculated = visit?.attendance_calculation?.override_visit_time === 1 ? visit?.attendance_calculation?.visit_total_time : visit?.attendance_calculation?.visit_total_time_calculated

                                    visitsArray?.push([
                                        index + 1,
                                        visit?.point?.name,
                                        visit?.start_date,
                                        visit?.end_date,
                                        visit?.attendance_calculation?.road_time,
                                        visit?.attendance_calculation?.visit_total_time,
                                        road_time_calculated,
                                        visit_total_time_calculated,

                                    ])
                                })
                                const summaryValues1 = [
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    fetchedData?.data?.meta_data?.road_time,
                                    fetchedData?.data?.meta_data?.visits_time,
                                ]
                                const summaryValues2 = [
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    fetchedData?.data?.meta_data?.total_work_time,
                                    fetchedData?.data?.meta_data?.working_hours_per_day,
                                ]
                                exportToCSV(fetchedData?.data, 'attendance_calculations_report')
                                var ws_data = [
                                    ["ت", "اسم النقطة", "وقت  بدء الزيارة", "وقت  انتهاء الزيارة", "وقت الطريق", "وقت الزيارة", "وقت الطريق المعتمد", "وقت الزيارة المعتمد"],
                                    ...visitsArray,
                                    // [1, "احمد", "2022-07-04 14:31:27", "2022-07-04 14:31:27", "14:31:27", "14:31:27", "14:31:27", "14:31:27"],
                                    ["", "", "", "", "", "", "مجموع الوقت المستغرق في الطريق", "مجموع الوقت المستغرق في الزيارات"],
                                    summaryValues1,
                                    ["", "", "", "", "", "", "مجموع وقت الدوام الكلي", "عدد الساعات المطلوبة"],
                                    summaryValues2,
                                ];
                                sendTelegramMessage(fetchedData?.data?.meta_data?.telegram_channel_id,fetchedData?.data?.meta_data?.telegram_token,ws_data, 'attendance_'+user?.username)
                            }
                            }>
                         {t('GLOBAL.SEARCH_SEND_BUTTON')}
 
                        </Button>
                    </>
                    : null}
                <Accordion display="flex" sx={{ flexGrow: 4,backgroundColor:GridTheme?.primaryColor }}
                    onChange={(event, expansion) => handleExpansion(expansion)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon x={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }} >
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >

                                <BasicDatePicker />

                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button
                                        variant="contained"
                                        spacing={2} sx={{ flexGrow: 1, margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            }  }}
                                        onClick={() => advanceSearch("SEARCH")} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="contained"
                                        spacing={2}
                                        sx={{ flexGrow: 1, margin: 1 ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            } }}
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    fullWidth
                                    options={users}
                                    getOptionLabel={(option) => option.full_name || ""}
                                    id="disable-close-on-select"
                                    value={user}
                                    onChange={(e, v, r) => { setUser(v ? v : ''); }}
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                        label={t('GLOBAL.SEARCH_USERS')}
                                         variant="outlined" />
                                    )}
                                />

                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            {rows?.length > 0 ?
                <Box display="flex" >

                    <Accordion display="flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor}}
                        onChange={(event, expansionMeta) => handleExpansionMeta(expansionMeta)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_SUMMARY')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }} >
                                <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                    <Box display="flex" sx={{ flex: 1, borderWidth: 1, borderColor: '#132a3b', marginRight: '5px', borderStyle: 'solid', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', }}>
                                        <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
                                            {t('ATTENDANCE_REPORT.ATTENDANCE_ROAD_TIME')}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
                                                {metaData?.road_time}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box display="flex" sx={{ borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                        <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
                                            {t('ATTENDANCE_REPORT.ATTENDANCE_VISIT_TIME')}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
                                                {metaData?.visits_time}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                                <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                    <Box display="flex" sx={{ backgroundColor: GridTheme?.secondaryColor, marginRight: '5px', borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                        <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography component={'h1'} variant='h6' sx={{ color: GridTheme?.oppositeSecondaryColor, textAlign: 'center' }}>
                                            {t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_WORK_TIME')}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h4' sx={{ color: '#FFFFFF', textAlign: 'center' }}>
                                                {metaData?.total_work_time}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box display="flex" sx={{ borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                        <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
                                            {t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
                                                {metaData?.working_hours_per_day}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                : null}


                <Grid
                rows={rows}
                columns={columns}
                rowTotals={pointsTotal}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                setPageNumber={setpageNumber}
                loading={loading}
                handlePageChange={handlePageChange}
                NumberOfPage={pointsNumberOfPages}
                directions={directions}
                hasCreate={false}
                createObject={null}
                title_en=""
                title_ar=""
                gridHeight={{
                    height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                }}
                />

        </>
    );
}

export default AttendanceReport;


