import React ,{useState,useEffect} from 'react'

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LanguageIcon from '@mui/icons-material/Language';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect  } from "react-router";

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import './Notifications.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {setSideMenuMode,sideMenuMode,setDirections} from '../../reduxStore/SettingsReducer'
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from 'universal-cookie';
import LoginIcon from '@mui/icons-material/Login';
import {UserAppheader} from './StyledComponents/SideMenuStyleComponent'
import {useTranslation} from "react-i18next";

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme,ToolBarTheme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      backgroundColor: ToolBarTheme?.paperColor,
      color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      '& .itmlist:hover': {
        backgroundColor:ToolBarTheme?.paperTextColor+'55',
      },
    },
  }));
const cookies=new Cookies();

export default function UserProfileAppBar({ToolBarTheme}) {
  const {t, i18n} = useTranslation('common');


  const directions = useSelector(state=> state.settingsData.directions)
  const userName = useSelector(state => state.userData.userName)
  const userPhoneNumber = useSelector(state => state.userData.phone)
  const userRoleName = useSelector(state => state.userData.roleName)


  const dispatch=useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (dire) => {
    setAnchorEl(null);
  };

  const signOut=()=>{
    cookies.remove("token");
    window.location.href='/'
    
  }
  
  const signIn=()=>{
    window.location.href='/'
  }

  return (
    <React.Fragment>
        <UserAppheader bgColor={ToolBarTheme?.paperTextColor} lightOrDark={ToolBarTheme?.light?'light':"dark"} className="userAppheader" 
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <div className="textuserAppher">
            <p >{cookies.get('full_name')?cookies.get('full_name'):''}</p>
            <p >{cookies.get('username')?cookies.get('username'):''}</p>
            </div>
            <img className="imgUserAppheader" src="https://dev.chromeandwhyte.com/wp-content/uploads/2022/07/User-Profile-PNG.png" alt="" />
    
        </UserAppheader>

        <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        ToolBarTheme={ToolBarTheme}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
         cookies.get("token")?
        <MenuItem onClick={()=>handleClose()} disableRipple className={`itmlist ${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
        {
          
          directions=='rtl'?
            <>
              {t('APPBAR.MY_PROFILE')}
              <AccountCircleIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
            </>
            :
            <>
              <AccountCircleIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
              {t('APPBAR.MY_PROFILE')}
            </>
           
          }
        </MenuItem>
        :null
        }


        {
         cookies.get("token")?
           <Divider sx={{ my: 0.5 }} />
          :null
        }
        {
            cookies.get("token")?
            <MenuItem onClick={signOut} disableRipple className={`itmlist ${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
            {
            directions=='rtl'?
                <>
                {t('APPBAR.SIGN_OUT')}
                <LogoutIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                </>
                :
                <>
                <LogoutIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                {t('APPBAR.SIGN_OUT')}
                </>
            }
            </MenuItem>
            :
            <MenuItem onClick={signIn} disableRipple className={`itmlist ${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
            {
            directions=='rtl'?
                <>
                  {t('APPBAR.SIGN_IN')}
                <LoginIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                </>
                :
                <>
                <LoginIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                  {t('APPBAR.SIGN_IN')}
                </>
            }
            </MenuItem>
        }

        </StyledMenu>
    </React.Fragment>
  )
}
