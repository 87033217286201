
import React, { useEffect, useState } from 'react';
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import { Avatar } from "evergreen-ui"
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import VisitForm from './VisitForm';
import VisitMapDialog from './VisitMapDialog';
import host from '../../../assets/js/Host';
import VisitPreview from './VisitPreview';
// import VisitMapLocation from './VisitMapLocation';
import IconButton from "@material-ui/core/IconButton";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import BasicDateRangePicker from '../GeneralComponents/DateFromTo'
import moment from 'moment';
import {useSelector,useDispatch} from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
// import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'
import Pagination from '../Share/Pagination';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import SearchField1 from '../GeneralComponents/SearchField1'
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import { Checkbox, FormControlLabel } from '@mui/material';
import PointsMapDialog from '../Points/PointsMapDialog';
import {useTranslation} from "react-i18next";

import Grid from '../Share/Grid';
import * as GrIcone from 'react-icons/gr'
// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
function Visit() {
    const [t, i18n] = useTranslation('common');

    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const directions = useSelector(state => state.settingsData.directions)
    const GridTheme = useSelector(state => state.settingsData.GridTheme)


    const { loading, fromToDate, visits, pointsStatuses, pointStatus, setPointStatus, exportToCSV, allPoints, user, users, setUser, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()


    const [showVisitForm, setshowVisitForm] = useState(false);
    const [showVisitMapDialog, setshowVisitMapDialog] = useState(false)
    const [showVisitPreview, setshowVisitPreview] = useState(false)
    const [selectedVisit, setselectedVisit] = useState(null);
    const [accExpansion, setAccExpansion] = useState(false)
    const [hasFollowups, setHasFollowups] = React.useState(false);
    const [hasPhone, setHasPhone] = React.useState(false);
    const [mapMarkers, setMapMarkers] = useState([])

    const Language=React.useMemo(()=>{
        if(directions!=='rtl'){
          return require('../../Settings/Translate_en.json').VISITS
        }else{
          return require('../../Settings/Translate_ar.json').VISITS
        }
    },[directions])

    const handleChangeHasFollowups = (event) => {
        setHasFollowups(event.target.checked);
    };

    const handleChangeHasPhone = (event) => {
        setHasPhone(event.target.checked);
    };
    const handleExpansion = (expansion) => setAccExpansion(expansion)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }
    const advanceSearch = async (type = "BROWSING") => {

        const fetchedData = await fetchData('VISITS', 'visits', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            region: region.id,
            created_by: user.id,
            shop_name: shopName,
            has_phone: hasPhone ? 1 : 0,
            has_followups: hasFollowups ? 1 : 0,
            creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
            creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        })
        return fetchedData
    }

    const updateObject = async (object) => {
        setselectedVisit(object)
        setshowVisitForm(true)
    };
    const previewObject = async (object) => {
        setselectedVisit(object)
        setshowVisitPreview(true)
    }

    const createObject = async (object) => {
        setselectedVisit(null)
        setshowVisitForm(true)
    };

    const handleSetshowVisitForm = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch()
        setshowVisitForm(openStatus)
    };

    const handleSetshowVisitPreview = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch()
        setshowVisitPreview(openStatus)
    }

    const mapObject = async (object) => {
        console.log("visit",object);
        setselectedVisit(object)
        setMapMarkers([
            {
                id:-1,
                name:"End",
                latitude:object?.end_latitude,
                longitude:object?.end_longitude,
                owner_name:object?.created_by?.full_name,
                specialColor:"#FF00FF",
                point_type:{
                    name:""
                }
            },
            {
                id:-2,
                name:"Start",
                latitude:object?.start_latitude,
                longitude:object?.start_longitude,
                owner_name:object?.created_by?.full_name,
                specialColor:"#00FFFF",
                point_type:{
                    name:""
                }
            },
            {
                ...object?.point
            }
        ])
        setshowVisitMapDialog(true)
    }
    const handleSetshowVisitMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch()
        setshowVisitMapDialog(openStatus)
    }



    useEffect(() => {
        fetchData('REGIONS', 'regions_list')
        fetchData('USERS', 'users_list')
        advanceSearch()
    }, [pageSize, pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {

        if (visits && visits.data && visits.data.length > 0) {
            const reformatVisits = visits.data.map((rowVisit) => {
                // console.log('row visits')
                // console.log(rowVisit)
                const thumbnail_image_url = null //getImageUrl(rowVisit?.profile_image[0]?.thumbnail_image_url)
                const image_url = null //getImageUrl(rowVisit?.profile_image[0]?.image_url)
                return {
                    ...rowVisit,
                    // profile_image:[{...rowVisit?.profile_image[0],
                    //     thumbnail_image_url:thumbnail_image_url,
                    //     image_url:image_url}]                    
                }
            })
            setRow(reformatVisits);
            setPointsTotal(visits.total)
            setPointsNumberOfPages(visits.last_page)
        }
        else {
            setRow([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [visits])

    // useEffect(() => {
    //     fetchData();
    // }, [])
    const [rows, setRow] = useState([])
    const columns = [
        { field: 'id', headerName: t('VISITS.ID'), flex: .1 },
        {
            field: 'name', headerName: t('VISITS.NAME'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.point.name}
                </div>
            )
        },
        {
            field: 'phone', headerName: t('VISITS.PHONE'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.point?.phone}
                </div>
            )
        },
        {
            field: 'USER', headerName:t('VISITS.USER'), flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%', }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.created_by.full_name}
                </div>
            )
        },
        {
            field: 'start_date', headerName: t('VISITS.START_END_DATE'), flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.start_date}<br />
                    {rows.row.end_date}
                </div>)
        },

        {
            field: 'canceled', headerName: t('VISITS.IS_CANCELL'), flex: .8,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.canceled ?
                        <span style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "red" }}>canceled</span>
                        :
                        <span style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "green" }}>valid</span>
                    }
                </div>
            )
        },
        { field: 'cancelation_reason', headerName: t('VISITS.REASON'), flex: 1,renderCell:(rows)=>(
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.cancelation_reason}
                </div>
        ) },
        { field: 'note', headerName: t('VISITS.NOTE'), flex: 2,
            renderCell:(rows)=>(
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.note}
                </div>
            )
        },
        {
            field: 'followups', headerName: t('VISITS.FOLLOWUPS'), flex: .8,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.visit_followups?.length}
                </div>
            )
        },
        {
            field: 'actions', flex: 1,
            headerName:  t('VISITS.ACTIONS'),
            renderCell: (rows) => (
                <strong className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    <IconButton
                        size="small"
                        style={{ marginLeft: 0 }}
                        onClick={() => {
                            mapObject(rows.row)
                        }}>
                        <Tooltip title="Locate">
                            <LocationOnRoundedIcon style={{ fill: "#5cb85c" }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="view"
                        size="small"
                        onClick={() => {
                            previewObject(rows.row)
                            // previewObject(rows.row.object1)
                        }}>
                        <Tooltip title="View">
                            <RemoveRedEyeRoundedIcon style={{ fill: "#111827" }}  />
                        </Tooltip>
                    </IconButton>
                </strong>
            ),
        },
    ]


    return (
        <>
            {showVisitForm ?
                <VisitForm visit={selectedVisit} showVisitForm={showVisitForm} setshowVisitForm={handleSetshowVisitForm} />
                : null}
            {showVisitPreview ?
                <VisitPreview visit={selectedVisit} showVisitPreview={showVisitPreview} setshowVisitPreview={handleSetshowVisitPreview} />
                : null}
            {showVisitMapDialog ?
                
                <PointsMapDialog title={t('GLOBAL.MAPS_VIEW_POINT')} points={mapMarkers} showPointsMapDialog={showVisitMapDialog} setshowPointsMapDialog={handleSetshowVisitMapDialog} />
                : null}

            <Box display="flex"  sx={{
                margin:'10px 0'
                }}>
                <Button display="flex"
                    startIcon={<FileDownloadIcon  sx={{margin:'0 0 0 10px', color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
                    variant="contained"
                    sx={{ height:59,marginRight: 0, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor }}
                    onClick={async () => {

                        const fetchedData = await fetchData('VISITS', 'visits', {
                            page_size: pointsTotal,
                            region: region.id,
                            created_by: user.id,
                            shop_name: shopName,
                            creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
                            creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
                        })
                        exportToCSV(fetchedData.data, 'visits')

                    }
                    }>
                    {t('GLOBAL.SEARCH_EXPORT_BUTTON')}
                </Button>

                <Accordion display="flex"
                  sx={{ 
                    flexGrow: 4 ,
                    backgroundColor:GridTheme?.primaryColor,
                    // color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor
                }}
                    onChange={(event, expansion) => handleExpansion(expansion)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }} >
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                <Box display="flex" sx={{ flexGrow: 2 }}>
                                    <Autocomplete
                                        sx={{ ml: 1 }}
                                        fullWidth
                                        options={regions}
                                        getOptionLabel={(option) => option.name || ""}
                                        id="disable-close-on-select"
                                        value={region}
                                        onChange={(e, v, r) => { setRegion(v ? v : '') }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={t('GLOBAL.SEARCH_REGION')} variant="outlined" />
                                        )}
                                    />
                                </Box>



                                <BasicDatePicker Language={Language}/>


                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button
                                        variant="contained"
                                        spacing={2} sx={{ flexGrow: 1, margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            }
                                        }}
                                        onClick={() => advanceSearch("SEARCH")} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="contained"
                                        spacing={2}
                                        sx={{
                                             flexGrow: 1, margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+"88",
                                            }
                                            }}
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    fullWidth
                                    options={users}
                                    getOptionLabel={(option) => option.full_name || ""}
                                    id="disable-close-on-select"
                                    value={user}
                                    onChange={(e, v, r) => { setUser(v ? v : ''); }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('GLOBAL.SEARCH_USERS')} variant="outlined" />
                                    )}
                                />
                                <FormControlLabel
                                    sx={{ "& .MuiFormControlLabel-label":{ color: '#000000'},marginLeft:'10px' }}
                                    label={t('GLOBAL.SEARCH_HAS_PHONE')}
                                    control={
                                        <Checkbox
                                            checked={hasPhone}
                                            onChange={handleChangeHasPhone}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />} />

                                <FormControlLabel
                                    sx={{ "& .MuiFormControlLabel-label":{ color: '#000000'},marginLeft:'10px' }}
                                    label={t('GLOBAL.SEARCH_HAS_FOLLOWUP')}
                                    control={
                                        <Checkbox
                                            checked={hasFollowups}
                                            onChange={handleChangeHasFollowups}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    } />
                                <SearchField1 state={shopName} setState={setShopName} fieldLabel={t('GLOBAL.SEARCH_SHOPE_NAME')} />

                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={pointsTotal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={false}
              createObject={createObject}
              title_en="Roles List"
              title_ar="قائمة الصلاحيات"
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />

            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                           
                            <div className="card-header">Visit List </div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>Point name</th>
                                        <th>Visited by</th>
                                        <th>Visited started at</th>
                                        <th>Visited ended at</th>
                                        <th>is canceled</th>
                                        <th>Cancelation reason</th>
                                        <th>Note</th>
                                        <th>Preview</th>
                                        <th>Map it</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.visits?.data ? 
                                        state?.visits?.data?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>
                                                    {object?.point?.name}
                                                    </td>
                                                    <td>{object?.created_by?.full_name}</td>
                                                    <td>{object?.start_date}</td>
                                                    <td>{object?.end_date}</td>
                                                    <td>{object?.canceled?<span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"red"}}>canceled</span>:<span style={{padding:5,borderRadius:5,color:'white', backgroundColor:"green"}}>valid</span>}</td>
                                                    <td>{object.cancelation_reason}</td>
                                                    <td>{object.note}</td>
                                                    <td><Button onClick={()=>{previewObject(object)}}>Preview</Button></td>
                                                    <td><Button style={{backgroundColor:'green',color:'white'}} onClick={()=>{mapObject(object)}}>Map it</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.visits?.current_page ? state?.visits?.current_page : 0}
                                        itemsCountPerPage={state?.visits?.per_page ? state?.visits?.per_page : 0 }
                                        totalItemsCount={state?.visits?.total ? state?.visits?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Visit;


