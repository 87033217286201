import React, { useState, useEffect } from "react";
// import { Pane, Dialog, Textarea, FilePicker } from 'evergreen-ui';
// import Pagination from '@mui/material/Pagination';
import Pagination from '../Share/Pagination'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { AiFillCloseCircle } from 'react-icons/ai'
import { Box, IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { Table } from "react-bootstrap";
import StyledDataGrid from "../GeneralComponents/StyledDataGrid";
import { useGlobalContext } from "../../../context";
import AddVisitFollowup from "./AddVisitFollowup";
import CustomLoading from "../GeneralComponents/CustomLoading";
import {useSelector,useDispatch} from 'react-redux'
import Grid from '../Share/Grid'
import DialogForm from "../Share/DialogForm";
import {useTranslation} from "react-i18next";

export const VisitPreview = (props) => {
    const [t, i18n] = useTranslation('common');
    const directions =useSelector(state=>state.settingsData.directions)
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)

    // const [loading, setloading] = useState(true);
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const { loading, setLoading, fetchData, visitFollowups } = useGlobalContext()

    const [rows, setRow] = useState([])
    const columns = [
        { field: 'id', headerName: t('VISITS.FORMVIEW_GRID_ID'), flex: .1 },
        {
            field: 'status', headerName: t('VISITS.FORMVIEW_GRID_STATUS'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.followup_status?.name}
                </div>
            )
        },
        {
            field: 'note', headerName: t('VISITS.FORMVIEW_GRID_NOTE'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.note}
                </div>
            )
        },

        // {
        //     field: 'actions', flex: 1,
        //     headerName: 'Actions',
        //     renderCell: (rows) => (
        //         <strong>
        //             <IconButton
        //                 style={{ marginLeft: 15 }}
        //                 aria-label="view"
        //                 size="small"
        //                 onClick={() => {
        //                     // previewObject(rows.row)
        //                     // previewObject(rows.row.object1)
        //                 }}>
        //                 <Tooltip title="View">
        //                     <RemoveRedEyeRoundedIcon color="primary" />
        //                 </Tooltip>
        //             </IconButton>
        //         </strong>
        //     ),
        // },
    ]

    const descriptionElementRef = React.useRef(null);



    useEffect(() => {

        if (props.showVisitPreview) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        fetchData('FOLLOWUP_STATUSES_LIST', 'followup_statuses_list')
        advanceSearch()
        return () => {
            console.log("props.visit", props.visit);
        };
    }, [props.showVisitPreview]);

    useEffect(() => {

        if (visitFollowups && visitFollowups.data && visitFollowups.data.length > 0) {
            const reformatVisitFollowups = visitFollowups.data.map((rowVisit) => {

                return {
                    ...rowVisit,
                }
            })
            setRow(reformatVisitFollowups);
            setPointsTotal(visitFollowups.total)
            setPointsNumberOfPages(visitFollowups.last_page)
        }
        else {
            setRow([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [visitFollowups])

    useEffect(() => {
        advanceSearch()
    
      return () => {
        
      }
    }, [pageSize,pageNumber])
    

    const advanceSearch = async (type = "BROWSING") => {

        const fetchedData = await fetchData('VISIT_FOLLOWUPS', 'visit_followups', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            visit_id: props?.visit?.id
        })
        return fetchedData
    }

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    
    return (
        <DialogForm
            open={props.showVisitPreview}
            close={props.setshowVisitPreview}
        >
   
          

                <DialogContentText
                    id="scroll-dialog-description"
                    sx={{
                        height: '100%',
                        direction: 'ltr',
                    }}
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >

                    <IconButton aria-label={"Close"} onClick={() => { props.setshowVisitPreview(false) }} style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '50px', }}>
                        <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor, }} />

                    </IconButton>
                    <Box style={{
                        padding: '10px',
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        color: MainTheme?.paperTextColor,

                    }}>
                        <Typography component={'h1'} variant={'h4'} style={{ color: MainTheme?.paperTextColor, width: '100%', textAlign: 'center' }}>
                            {t('VISITS.FORMVIEW_TITLE')}
                        </Typography>
                        
                            
                                <Table striped bordered  style={{color: MainTheme?.paperTextColor,}}>

                                    <tbody>

                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>{t('VISITS.NAME')}</td>
                                            <td >{props?.visit?.point?.name}</td>
                                            <td style={{ fontWeight: 'bold' }}>{t('VISITS.VISITED_BY')}</td>
                                            <td >{props?.visit?.created_by?.full_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>{t('VISITS.PHONE')} </td>
                                            <td colSpan={3} >{props?.visit?.point?.phone}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>{t('VISITS.VISITED_BY')}</td>
                                            <td >{props?.visit?.end_date}</td>
                                            <td style={{ fontWeight: 'bold' }}>{t('VISITS.START_END_DATE')}</td>
                                            <td >{props?.visit?.start_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>{t('VISITS.IS_CANCELL')}</td>
                                            <td>{props?.visit?.canceled ? <span style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "red" }}>canceled</span> : <span style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "green" }}>valid</span>}</td>
                                            <td style={{ fontWeight: 'bold' }}>{t('VISITS.REASON')}</td>
                                            <td>{props?.visit?.cancelation_reason}</td>

                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>{t('VISITS.NOTE')}</td>
                                            <td colSpan={3}>{props?.visit?.note}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                                {props && props.visit && props.visit.visit_survey_values && props.visit.visit_survey_values.length > 0 ?
                                    <Table striped bordered  style={{color: MainTheme?.paperTextColor,}}>
                                        <thead style={{backgroundColor: MainTheme?.paperTextColor+'44',}}>
                                            <tr >
                                                <th style={{textAlign:directions=='rtl'?'right':'left'}}>{t('VISITS.FORMVIEW_TABLE_NAME')}</th>
                                                <th style={{textAlign:directions=='rtl'?'right':'left'}}>{t('VISITS.FORMVIEW_TABLE_VALUE')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            {props.visit.visit_survey_values.map((visitSurveyValue) => {

                                                return (
                                                    <tr>
                                                        <td style={{textAlign:directions=='rtl'?'right':'left'}}>{visitSurveyValue?.survey_field?.name} </td>
                                                        <td style={{textAlign:directions=='rtl'?'right':'left'}}>{visitSurveyValue?.value}</td>
                                                    </tr>)
                                            })}


                                        </tbody>
                                    </Table>
                                    : <p>{t('VISITS.FORMVIEW_TABLE_THERES_NO_VALUES')}</p>}

                                <AddVisitFollowup reload={() => { advanceSearch() }} visitId={props?.visit?.id} />
                                {/* {loading ? <><CustomLoading /></> : */}
                              
                                    
                                    <Grid
                                        rows={rows}
                                        columns={columns}
                                        rowTotals={pointsTotal}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        pageNumber={pageNumber}
                                        setPageNumber={setpageNumber}
                                        loading={loading}
                                        handlePageChange={handlePageChange}
                                        NumberOfPage={pointsNumberOfPages}
                                        directions={directions}
                                        hasCreate={false}
                                        createObject={null}
                                        title_en=""
                                        title_ar=""
                                        gridHeight={{
                                            height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                                        }}


                                        />

                        

                    </Box>




                </DialogContentText>
            

        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '90%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default VisitPreview;
