import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './App';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from './context';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
 
// import common_ar from "./translations/ar/common.json";
// import common_en from "./translations/en/common.json";
// const 

// i18next.init({
//   interpolation: { escapeValue: false },  // React already does escaping
//   lng: 'en',                              // language to use
//   resources: {
//       en: {
//           common: common_en               // 'common' is our custom namespace
//       },
//       ar: {
//           common: common_ar
//       },
//   },
// });


ReactDOM.render(
  <React.StrictMode>
        <Provider store={store}>
          <AppProvider> 
             <Main />
          </AppProvider>
        </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
