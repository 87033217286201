import React from 'react'
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useSelector, useDispatch } from 'react-redux'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import {setSideMenuMode,sideMenuMode,setDirections,setNotificationBox} from '../../reduxStore/SettingsReducer'
import './Notifications.css'
import {useTranslation} from "react-i18next";

function notificationsLabel(count) {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  }

export default function Notification({ToolBarTheme}) {
    const directions = useSelector(state=> state.settingsData.directions)
    const dispatch=useDispatch(true);
    const {t, i18n} = useTranslation('common');

    const openNotificationBox=()=>{
        dispatch(setNotificationBox(true));
    }

  return (
    <Tooltip arrow title={t('APPBAR.TOOLTIP_NOTIFICATION')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom">
        <IconButton aria-label={notificationsLabel(0)} className='Notification' onClick={()=>openNotificationBox()}>
            <Badge badgeContent={0}  className="badgeColor">
                <NotificationsNoneIcon className='colorNotification' style={{color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
            </Badge>
        </IconButton>
    </Tooltip>
  )
}
