import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { AiFillCloseCircle } from 'react-icons/ai'
import DialogContent from '@mui/material/DialogContent';
import { Divider, Switch, CardMedia, Box, DialogTitle, IconButton, TextField, Typography, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Button, Avatar } from '@mui/material';
import axios from "axios";
import { styled, useTheme } from '@mui/material/styles';

import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from './StyledComponents';
import FooterBottons from "../Share/FooterBottons";
import { flexbox } from "@mui/system";
import DialogForm from "../Share/DialogForm";
import helpers from "../../../assets/js/helper";

import {useTranslation} from "react-i18next";

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const CustomeSelect = styled(Select)(({ theme, open,MainTheme, }) => ({
     backgroundColor:MainTheme?.paperTextColor+'88',
     color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
     '& div ul':{
        color:`${MainTheme?.paperTextColor}`,
        backgroundColor:`${MainTheme?.paperColor+'33'}`,
      }
  }));

export const UserForm = (props) => {
    const [loading, setloading] = useState(true);
   
    const [companies, setCompanies] = useState([])
    const [departments, setDepartments] = useState([]);
    const directions =useSelector(state=> state.settingsData.directions);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);
    const {t, i18n} = useTranslation('common');



    const [object, setobject] = useState({
        id: null,
        full_name: null,
        phone: null,
        username: null,
        password: null,
        is_demo: null,
        company: {
            id: null,
            name: null
        },
        department: {
            id: null,
            name: null
        }
    });
    const getResources = async () => {
        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "departments",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    // const resources = resourcesResult.data;
                    const resources = resourcesResult?.data?.data;
                    setDepartments(resources);
                    if (props && props.user && props.user.id > 0) {
                        setobject({ ...object, ...props.user });
                    }
                    else {
                        console.log("hi");
                        setobject(
                            {
                                id: null,
                                full_name: null,
                                phone: null,
                                username: null,
                                password: null,
                                is_demo: null,
                                company: {
                                    id: null,
                                    name: null,
                                },
                                department: {
                                    id: null,
                                    name: null,
                                }
                            }
                        )
                    }
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            const resourcesResult1 = await axios({
                url: Host + "companies_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult1) {
                if (resourcesResult1.status === 200) {
                    
                    const resources = resourcesResult1?.data;
                    setCompanies(resources);
                    if (props && props.user && props.user.id > 0) {
                        setobject({ ...object, ...props.user });
                    }
                    else {
                        console.log("hi");
                        setobject(
                            {
                                id: null,
                                full_name: null,
                                phone: null,
                                username: null,
                                password: null,
                                is_demo: null,
                                company: {
                                    id: null,
                                    name: null,
                                },
                                department: {
                                    id: null,
                                    name: null,
                                }
                            }
                        )
                    }
                } else if (resourcesResult1.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            console.log("Network Error", error?.message);
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {

        try {


            if (object.id) {
                const resourcesResult = await axios({
                    url: Host + "users/" + object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: {
                        ...object,
                        department_id:object?.department?.id,
                        company_id:object?.company?.id
                    }
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setShowUserForm(false, true)
                    } else if (resourcesResult.data.status === false) {
                        props.setShowUserForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowUserForm(true, false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "register",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: {
                        ...object,
                        department_id:object?.department?.id,
                        company_id:object?.company?.id
                    }
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setShowUserForm(false, true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setShowUserForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowUserForm(true, false)
                }
                setloading(false)
            }
        } catch (error) {
            console.log("error",error);
            props.setShowUserForm(true, false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {

                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {

                    toast.error(resourcesResult.data.data)
                }


            }
            else if (resourcesResult && resourcesResult.status === 401) {

                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {

                toast.error("network error")
            }
        }

    };


    useEffect(() => {
        console.log(props);

        if (loading)
            getResources()


        return () => {

        };
    }, [loading, props.showUserForm]);

    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    }
    const check = helpers.checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)

    const styleSelect = {
        backgroundColor: !check ? 'rgb(255,255,255,0.7)' : 'rgb(0,0,0,0.4)',
        color: MainTheme?.paperTextColor,
    }
    
    const styleSpecificSelect={
       
    }
    

    return (

        <DialogForm
            open={props.showUserForm}
            close={props.setShowUserForm}
            CustomeWidth='60%'
            CustomeHeight="80%"


        >

            <DialogTitle

            >
                <IconButton aria-label={"Close"} onClick={() => { props.setShowUserForm(false) }} style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '50px', }}>
                    <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor }} />

                </IconButton>
                <Typography component={'h1'} variant={'h4'} style={{ color: MainTheme?.paperTextColor, width: '100%', textAlign: 'center' }}>
                    {props?.title ? props?.title : (object?.id ? t('USERS.USER_EDIT_FORM'): t('USERS.USER_ADD_FORM'))}
                </Typography>
            </DialogTitle>

            <DialogContent dividers={false} sx={{
                padding: 0,
                overflowX: 'hidden',
                backgroundColor: 'transparent',
                height: '100%',


            }}>

                {loading ? <>Loading...</> :

                    <Box style={{
                        padding: '10px',
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        color: MainTheme?.paperTextColor

                    }}>
                        <Box style={{

                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start',
                            

                        }}>
                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flex: 1

                            }}>
                                <TextField
                                    id="outlined-basic-full_name"
                                    label={t('USERS.FORM_FULLNAME')}
                                    variant="outlined"
                                    placeholder={t('USERS.FORM_FULLNAME')}
                                    sx={{ ...styleSelect }}
                                    value={object?.full_name}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, full_name: event.target.value });
                                    }}
                                />
                                <TextField
                                    id="outlined-basic-phone"
                                    label={t('USERS.FORM_PHONE')}
                                    variant="outlined"
                                    placeholder="+9647xxxxxxxxx"
                                    sx={{ ...styleSelect, marginTop: '10px' }}
                                    value={object?.phone}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, phone: event.target.value });
                                    }}
                                />
                                <FormControl fullWidth sx={{ ...styleSelect, marginTop: '10px',
                              }}>
                                    <InputLabel id="department-select-label" sx={{ color: MainTheme?.paperColor }}>{t('USERS.FORM_DEPARTMENT')}</InputLabel>
                                    <CustomeSelect
                                        MainTheme={MainTheme}
                                        labelId="department-select-label"
                                        id="department-select"
                                        sx={{ textAlign: 'left',...styleSpecificSelect }}
                                        value={object?.department}
                                        label={t('USERS.FORM_DEPARTMENT')}
                                        renderValue={(value) => {
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.name}`
                                            }
                                            else {

                                                return <Typography sx={{ color: MainTheme?.paperTextColor }}>{t('USERS.FORM_SELECT_DEPARTMENT')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, department: {
                                                    ...event?.target?.value
                                                },
                                            });
                                        }}
                                    >
                                        {departments?.length > 0 && departments?.map((department) => {
                                          

                                            return <MenuItem key={department?.id} sx={{ color: MainTheme?.paperTextColor }} value={department}>{department?.name}</MenuItem>
                                        })}


                                    </CustomeSelect>
                                </FormControl>

                               
                                {/* <FormControlLabel control={
                                    <Switch
                                        checked={object?.is_demo}
                                        onChange={(event) => {
                                            const target = event.target;

                                            if (target.checked) {
                                                setobject({ ...object, is_demo: 1 });
                                            }
                                            else {
                                                setobject({ ...object, is_demo: 0 });
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'Is Demo' }}

                                    />
                                } label={<Typography sx={{ color: MainTheme?.paperTextColor }}>Is Demo</Typography>} /> */}

                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                marginLeft: '10px',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flex: 1

                            }}>
                                <TextField
                                    id="outlined-basic-username"
                                     label={t('USERS.FORM_USERNAME')} variant="outlined"
                                    placeholder={t('USERS.FORM_USERNAME')}
                                    sx={{ ...styleSelect }}
                                    value={object?.username}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, username: event.target.value });
                                    }}
                                />
                                <TextField
                                    id="outlined-basic-password"
                                    label={t('USERS.FORM_PASSWORD')} variant="outlined"
                                    placeholder={t('USERS.FORM_PASSWORD')}
                                    sx={{ ...styleSelect, marginTop: '10px' }}
                                    value={object?.password}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, password: event.target.value });
                                    }}
                                />
                                <FormControl fullWidth sx={{ ...styleSelect, marginTop: '10px' }}>
                                    <InputLabel id="company-select-label" sx={{ color: MainTheme?.paperColor }}>{t('USERS.FORM_COMPANY')}</InputLabel>
                                    <Select
                                        labelId="company-select-label"
                                        id="company-select"
                                        sx={{ textAlign: 'left' }}
                                        value={object?.company}
                                        label={t('USERS.FORM_COMPANY')}
                                        renderValue={(value) => {
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.company_name}`
                                            }
                                            else {

                                                return <Typography sx={{ color: MainTheme?.paperTextColor }}>{t('USERS.FORM_SELECT_COMPANY')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, company: {
                                                    ...event?.target?.value
                                                },
                                            });
                                        }}
                                    >
                                        {companies?.length > 0 && companies?.map((company) => {
                                          

                                            return <MenuItem key={company?.id} sx={{ color: MainTheme?.paperTextColor }} value={company}>{company?.company_name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Divider sx={{ marginTop: '10px', width: '100%' }} />
                        <Box style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                           

                        }}>
                            <Button sx={{ color:MainTheme?.disabledGlobalText?MainTheme.globalTextColor:MainTheme?.paperTextColor,}} onClick={() => {
                                submitForm()
                            }}>{t('USERS.FORM_SUBMIT')}</Button>
                        </Box>
                    </Box>
                }
            </DialogContent>

        </DialogForm>
    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default UserForm;
