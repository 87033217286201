

import React, { useState } from "react";
import loading from '../../assets/js/loading.json';
import axios from "axios";
import Cookies from "universal-cookie";
import Lottie from 'lottie-react-web';
import Host from "../../assets/js/Host";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'

import {setUserName,setUserPhone,setUserData,setUserRoleName} from '../reduxStore/UserReducer';

const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar"

const Login = () => {
    const dispatch=useDispatch();

    const [state, setState] = useState({
        data: [],
        username: '',
        password: '',
        errors: false,

        spin:false
    })


    const onEnter = (event) => {
        if (event.keyCode === 13) {
            document.getElementById("sign_in_div").click();
        }
    }


    if (cookies.get("token") !== undefined) window.location.href = "/dashboard";
    return (
        <div id='main' >
            <div id="login_main">
                <img src={require("../../assets/img/icon_no_bg.png")} alt="img" style={{ height: "250px" }} />
                {/* <div
                        style={{
                            fontSize: 22,
                            lineHeight: "40px",
                            color: "#707070",
                            fontWeight: "bold"
                        }}
                    >
                        Ticket System
                        </div> */}
                <div
                    style={{
                        fontSize: 20,
                        lineHeight: "40px",
                        color: "#707070",
                        marginBottom: 30
                    }}
                >
                </div>
                <div className="Sign_container" style={{ position: 'relative' }} >
                    <div className="up_field">
                        <input
                            id="field1"
                            placeholder="Username"
                            value={state.username}
                            onKeyDown={(e) => onEnter(e)}
                            onChange={e => {
                                setState({
                                    ...state,
                                     username: e.target.value 
                                });
                            }}
                        />
                        <input
                            id="field12"
                            name="text-input-name"
                            type="password"
                            placeholder="Password "
                            required
                            value={state.password}
                            onKeyDown={(e) => onEnter(e)}
                            onChange={e => {
                                setState({
                                    ...state,
                                    password: e.target.value
                                });
                            }}
                        />
                    </div>
                    <div className="down_field">


                        <div
                            id="sign_in_div"
                            onClick={async (e) => {
                                if (state.username.length < 3) {
                                    return (
                                        toast.error(`Username is short`)
                                    );
                                }
                                else if (state.password.length < 3) {

                                    return (
                                        toast.error(`Password must be more than 3 char`)
                                    );
                                }
                                setState({
                                    ...state,
                                    spin: true 
                                });

                                try {
                                    const responseResult = await axios({
                                        url: Host + "login",
                                        method: "post",
                                        headers: {
                                            // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                                            Accept: "application/json",
                                            'X-localization': language,

                                        },
                                        data: {
                                            username: state.username.includes("+964") ? null : state.username,
                                            phone: state.username.includes("+964") ? state.username : null,
                                            password: state.password,
                                        }

                                    })

                                    if (responseResult.data.success === true) {

                                        localStorage.setItem("defaultSettings", JSON.stringify(responseResult.data.data));
                                        var responseJson = responseResult?.data

                                        let token = responseJson?.data?.token
                                        let status = responseJson?.status
                                       
                                        cookies.set("status", status)
                                        cookies.set("logged_in_company_id", responseJson?.data?.user?.logged_in_company_id)
                                        cookies.set("username", responseJson?.data?.user?.username)
                                        cookies.set("full_name", responseJson?.data?.user?.full_name)
                                        cookies.set("user_id", responseJson?.data?.user?.id)
                                        cookies.set("roles", responseJson?.data?.user?.roles?.length>0? responseJson?.data?.user?.roles?.map((role)=>role.id):[]);
                                        cookies.set("phone", JSON.stringify(responseJson?.data?.user?.phone));
                                        console.log('roles',responseJson?.data?.user?.roles)
                                        

                                        // dispatch(setUserName(responseJson?.data?.user?.full_name))
                                        // console.log('name from login',responseJson?.data?.user?.full_name);
                                        // dispatch(setUserPhone(responseJson?.data?.user?.phone))
                                        // dispatch(setUserData(responseJson?.data?.user))
                                        // dispatch(setUserRoleName(responseJson?.data?.user?.username))


                                        cookies.set("token", token, {
                                            path: "/",
                                            expires: new Date(Date.now() + 60480000)
                                        });
                                        setState({
                                            ...state,
                                            spin: false 
                                        })
                                        window.location.href = "/dashboard";
                                    } else if (responseResult?.data?.status === false) {

                                    }
                                } catch (error) {
                                    const responseResult = error?.response
                                    if (responseResult && responseResult?.status === 400) {
                                        if (typeof responseResult?.data?.data === "object") {
                                            const errorMessagesKeys = Object.keys(responseResult?.data?.data);
                                            errorMessagesKeys?.map((key) => {

                                                responseResult?.data?.data[key]?.map((message) => {
                                                    toast?.error(message)
                                                })
                                            })
                                        }
                                        else {
                                            toast.error(responseResult.data.data)
                                        }

                                    }
                                    else if (responseResult && responseResult.status === 401) {
                                        toast.error(responseResult.data.data)
                                        cookies.remove("token");
                                        
                                    }
                                    else {
                                        toast.error("network error")
                                    }
                                    setState({
                                        ...state,
                                        spin: false 
                                    })
                                }
                            }} >
                            {state.spin === false ? (
                                <div> Login  </div>
                            ) : (
                                <div>
                                    <div>loading ...</div>
                                    <div style={{ width: "100%", position: "absolute", top: '102px', right: '-7px' }}>
                                        <Lottie
                                            options={{
                                                animationData: loading
                                            }}
                                            width={300}
                                            height={150}
                                            position="absolute"
                                        />
                                    </div></div>
                            )}
                        </div>


                    </div>
                </div>
            </div>
            <div>
                <div style={{ textAlign: 'center', color: 'rgb(112, 112, 112)', marginTop: 5, padding: 5, fontSize: 18 }} >© 2022 Jahizni . All rights reserved </div>
            </div>
        </div>

    );



}


export default Login;
