import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useSelector,useDispatch} from 'react-redux'

export default function CustomeCheckBox({HandleChange,data,IdOrName,selected,labelName,SelectAll}) {
    const setDirections=useSelector(state=>state.settingsData.directions)
    const MainTheme=useSelector(state=>state.settingsData.MainTheme)

    const styles={
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        '& .form-check-input':{
            backgroundColor:MainTheme?.secondaryColor,
        }
    }

  return (
    
     !SelectAll?(
        <div className="form-check" style={{...styles,visibility:IdOrName?'visible':!data.has_changes?'hidden':'visible'}} >
            <FormControlLabel
                labelStyle={{color: MainTheme?.paperTextColor}}
                label={''}
                control={
                    <Checkbox
                        labelStyle={{color: MainTheme?.paperTextColor}}
                    checked={selected}
                    sx={{
                    color: MainTheme?.secondaryColor+'33',
                    '&.Mui-checked': {
                        color:  MainTheme?.secondaryColor,
                    },
                    }}
                    onChange={(e)=>HandleChange(e,IdOrName?data.id:data?.value_name)}
                                id={"role_"+(IdOrName?data.id:data?.value_name)}
                />
                }
            />
            <p style={{color:MainTheme?.paperTextColor,marginTop:10}}>{labelName}</p>
        </div>)
        :
       (<div className="form-check" style={{...styles}} >
            <FormControlLabel
                labelStyle={{color: MainTheme?.paperTextColor}}
                label={''}
                control={
                    <Checkbox
                        labelStyle={{color: MainTheme?.paperTextColor}}
                    sx={{
                    color: MainTheme?.secondaryColor+'33',
                    '&.Mui-checked': {
                        color:  MainTheme?.secondaryColor,
                    },
                    }}
                    onChange={(e)=>HandleChange(e,IdOrName)}
                                id={"role_"+(IdOrName)}
                />
                }
            />
            <p style={{color:MainTheme?.paperTextColor,marginTop:10}}>{labelName}</p>
        </div>
        )
     
  )
}
