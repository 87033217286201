
import React, { useEffect, useState } from 'react';
import PointForm from './PointForm';
import PointsMapDialog from './PointsMapDialog';
import host from '../../../assets/js/Host';
import PointPreview from '../PointRequests/PointPreview';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import IconButton from "@material-ui/core/IconButton";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import { toast } from 'react-toastify';

import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import SearchField1 from '../GeneralComponents/SearchField1'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import red from '@material-ui/core/colors/red';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import {useTranslation} from "react-i18next";

// import Pagination from '@mui/material/Pagination';
import Pagination from '../Share/Pagination';
import { useSelector, useDispatch } from 'react-redux'

import { useGlobalContext } from '../../../context'
import Grid from '../Share/Grid'
import PointImportForm from './PointImportForm';

function Point() {
    const [t, i18n] = useTranslation('common');

    const [showPointForm, setshowPointForm] = useState(false);
    const [showPointMapDialog,setshowPointMapDialog] = useState(false)
    const [showPointsMapDialog,setshowPointsMapDialog] = useState(false)
    const directions =useSelector(state => state.settingsData.directions);
    const MainTheme =useSelector(state => state.settingsData.MainTheme);
    const GridTheme =useSelector(state => state.settingsData.GridTheme);



    const [showPointPreview, setshowPointPreview] = useState(false)
    const [selectedPoint, setselectedPoint] = useState(null);
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const { loading, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()
    const [showPointImportForm, setshowPointImportForm] = useState(false);
    const [accExpansion, setAccExpansion] = useState(false)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const updateObject = async (object) => {
        setselectedPoint(object)
        setshowPointForm(true)
    };
    const previewObject = async (object) => {
        setselectedPoint(object)
        setshowPointPreview(true)
    }
    const handleExpansion = (expansion) => setAccExpansion(expansion)


    const createObject = async () => {
        setselectedPoint(null)
        setshowPointForm(true)
    };

    const handleSetshowPointForm = (openStatus, needsReload) => {
        if (needsReload)

            fetchData('POINTS', 'points', {
                page: pageNumber,
                page_size: pageSize,
                shop_name: shopName,
                owner_name: ownerName,
                district: district.id,
                region: region.id,
                point_type_id: pointStatus.map((status) => status.id),
            });
        setshowPointForm(openStatus)
    };

    const handleSetshowPointPreview = (openStatus, needsReload) => {
        if (needsReload)

            fetchData('POINTS', 'points', {
                page: pageNumber,
                page_size: pageSize,
                shop_name: shopName,
                owner_name: ownerName,
                district: district.id,
                region: region.id
            });
        setshowPointPreview(openStatus)
    }

    const mapObject = async (object) => {
        setselectedPoint(object)
        setshowPointMapDialog(true)
    }
    const [mapPoints, setMapPoints] = useState([])

    const mapObjects = async (object) => {

        if (object.data.length > 3000) {
            return (
                toast.error(`Too many points to display!`)
            );
        }
        setMapPoints(object?.data && object?.data?.length > 0 ? object?.data?.map(point => {
            return {
                ...point,
            }
        }) : [])
        setshowPointsMapDialog(true)
    }

    const handleSetshowPointMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            advanceSearch()
        setshowPointMapDialog(openStatus)
    }

    const handleSetshowPointsMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            advanceSearch()
        setshowPointsMapDialog(openStatus)
    }

    const advanceSearch = () => {

        fetchData('POINTS', 'points', {
            page: pageNumber,
            page_size: pageSize,
            shop_name: shopName,
            owner_name: ownerName,
            district: district.id,
            region: region.id,
            point_type_id: pointStatus.map((status) => status.id),
        });
    }

    //Initial fetching for data
    useEffect(() => {
        fetchData('DISTRICTS', 'districts_list')
        fetchData('REGIONS', 'regions_list')
        fetchData('POINT_STATUSES', 'point_types_list')
        //to fetch points
        advanceSearch()

    }, [pageSize, pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {

        if (points && points.data && points.data.length > 0) {
            const reformatPoints = points.data.map((rowPoint) => {
                return {
                    id: rowPoint.id,
                    name: rowPoint.name,
                    owner_name: rowPoint.owner_name,
                    owner_type: rowPoint?.owner_type?.name,
                    phone: rowPoint?.phone,
                    region: rowPoint?.region?.name,
                    district: rowPoint?.region?.district_id === 1 ? "كرخ" : "رصافة",
                    object1: rowPoint
                }
            })
            setRow(reformatPoints);
            setPointsTotal(points.total)
            setPointsNumberOfPages(points.last_page)
        }
    }, [points])

    const columns = [
        {
            field: 'id', headerName: t('POINTS.ID'), flex: .1, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows.row?.id}<br />
                </div>
            )
        },
        { field: 'district', headerName: t('POINTS.DISTRICT'), flex: .5 },
        { field: 'region', headerName: t('POINTS.REGION'), flex: 3 },
        { field: 'name', headerName: t('POINTS.NAME'), flex: 3 },
        {
            field: "object1",
            flex: 1.5,
            headerName: t('POINTS.OWNER_DETAILS'),
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows.value.owner_name}<br />
                    {rows.value.phone}
                </div>
            )
        },

        { field: 'owner_type', headerName: t('POINTS.OWNER_TYPE'), flex: 1.5 },
        {
            field: 'actions', flex: 2,
            headerName: t('POINTS.ACTIONS'),
            renderCell: (rows) => (
                <strong>
                    <IconButton
                        size="small"
                        style={{ marginLeft: 0 }}
                        onClick={() => {
                            mapObject(rows.row.object1)
                        }}>
                        <Tooltip title="Locate">
                            <LocationOnRoundedIcon style={{ fill: "#111827" }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="view"
                        size="small"
                        onClick={() => {
                            previewObject(rows.row.object1)
                        }}
                    >
                        <Tooltip title="View">
                            <RemoveRedEyeRoundedIcon style={{ fill: "#111827" }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="edit"
                        size="small"
                        onClick={() => {
                            updateObject(rows.row.object1)
                        }}
                    >
                        <Tooltip title="Edit">
                            <ModeEditOutlineRoundedIcon style={{ fill: "#111827" }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 20 }}
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                            deleteObject('POINTS', 'point', rows.row.object1)
                        }}
                    >
                        <Tooltip title="Delete">
                            <DeleteRoundedIcon sx={{ color: red[500] }} />
                        </Tooltip>
                    </IconButton>
                </strong>
            ),
        },
    ]

    const [rows, setRow] = useState([])

    const importObjects = async () => {
        setselectedPoint(null)
        setshowPointImportForm(true)
    }
    const handleSetshowPointImportForm = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch()
        setshowPointImportForm(openStatus)
    };

    return (

        <>
            {showPointForm ?
                <PointForm point={selectedPoint} showPointForm={showPointForm} setshowPointForm={handleSetshowPointForm} />
                : null}
            {showPointMapDialog ?
                <PointsMapDialog title={t('GLOBAL.MAPS_VIEW_POINT')+' ' + selectedPoint?.name} points={[selectedPoint]} showPointsMapDialog={showPointMapDialog} setshowPointsMapDialog={handleSetshowPointMapDialog} />
                : null}
            {showPointPreview ?
                <PointPreview pointRequest={selectedPoint} showPointPreview={showPointPreview} setshowPointPreview={handleSetshowPointPreview} />
                : null}
            {showPointsMapDialog ?
                <PointsMapDialog title={t('GLOBAL.MAPS_VIEW_POINT')} points={mapPoints} showPointsMapDialog={showPointsMapDialog} setshowPointsMapDialog={handleSetshowPointsMapDialog} />
                : null}
            {showPointImportForm ?
                <PointImportForm showPointImportForm={showPointImportForm} setshowPointImportForm={handleSetshowPointImportForm} />
                : null}

            <Box display="flex" >
                <Button display="flex"
                    startIcon={<FileDownloadIcon  sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
                    variant="contained"
                    sx={{ height:59,marginRight: 1, marginBottom: accExpansion ? "0" : "20px" ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor }}
                    onClick={async () => {
                        const fetchedData = await fetchData('ALL_POINTS', 'points', {
                            page_size: pointsTotal,
                            shop_name: shopName,
                            owner_name: ownerName,
                            district: district.id,
                            region: region.id
                        })
                        exportToCSV(fetchedData.data, 'points')
                    }
                    }>
                    {t('GLOBAL.SEARCH_EXPORT_BUTTON')}
                </Button>

                <Button display="flex"
                    startIcon={<AddCircleRoundedIcon   sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
                    variant="contained"
                    sx={{ height:59,marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
                    onClick={() => { createObject() }}>
                    {t('GLOBAL.SEARCH_CREATE_BUTTON')}

                </Button>

                <Button display="flex"
                    startIcon={<MapRoundedIcon  sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
                    variant="contained"
                    sx={{ height:59,marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
                    onClick={async () => {
                        const fetchedData = await fetchData('ALL_POINTS', 'points', {
                            page_size: pointsTotal,
                            shop_name: shopName,
                            owner_name: ownerName,
                            district: district.id,
                            region: region.id,
                            point_type_id: pointStatus.map((status) => status.id),
                        })
                        mapObjects(fetchedData)
                    }
                    }>
                    {t('GLOBAL.SEARCH_MAP_BUTTON')}

                </Button>
                <Button display="flex"
                    startIcon={<PublishRoundedIcon  sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
                    variant="contained"
                    sx={{ height:59,marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
                    onClick={async () => { importObjects() }}>
                    {t('GLOBAL.SEARCH_IMPORT_BUTTON')}
                </Button>

                {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
                <Accordion display="flex" sx={{ flexGrow: 4 , backgroundColor:GridTheme?.primaryColor,}}
                    onChange={(event, expansion) => handleExpansion(expansion)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon  sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }}>
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                <Box display="flex" sx={{ flexGrow: 2 }}>
                                    <Autocomplete
                                        sx={{ ml: 1 }}
                                        fullWidth
                                        options={districts}
                                        getOptionLabel={(option) => option.name || ""}
                                        id="disable-close-on-select"
                                        value={district}
                                        onChange={(e, v, r) => { setDistrict(v ? v : '') }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={t('GLOBAL.SEARCH_DISTRICTS')} variant="outlined" />
                                        )}
                                    />
                                    <Autocomplete
                                        sx={{ ml: 1 }}
                                        fullWidth
                                        options={regions}
                                        getOptionLabel={(option) => option.name || ""}
                                        id="disable-close-on-select"
                                        value={region}
                                        onChange={(e, v, r) => { setRegion(v ? v : '') }}

                                        renderInput={(params) => (
                                            <TextField {...params} label={t('GLOBAL.SEARCH_REGION')} variant="outlined" />
                                        )}
                                    />
                                </Box>
                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button
                                        variant="contained"
                                        spacing={2} 
                                        
                                        sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, height: 55 ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            }
                                        }}
                                        onClick={advanceSearch} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="contained"
                                        spacing={2}
                                        sx={{ flexGrow: 1, margin: 0, height: 55,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            }
                                        }}
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                                </Box>
                            </Box>

                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row">
                                <SearchField1 state={shopName} setState={setShopName} fieldLabel={t('GLOBAL.SEARCH_SHOPE_NAME')} />
                                <SearchField1 state={ownerName} setState={setOwnerName} fieldLabel={t('GLOBAL.SEARCH_OWNER_NAME')} />
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    multiple
                                    fullWidth
                                    options={pointsStatuses}
                                    getOptionLabel={(option) => option.name || ""}
                                    id="disable-close-on-select"
                                    value={pointStatus}
                                    onChange={(e, v, r) => { setPointStatus(v ? v : []); }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('GLOBAL.SEARCH_POINT_STATUS')} variant="outlined" />
                                    )}
                                />

                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={points?.total ? points?.total : 0}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={false}
              createObject={null}
              title_en=""
              title_ar=""
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />           
        </>
    );
}

export default Point;


