import styled from 'styled-components'

export const HeadingSection=styled.div`
display: flex;
justify-content: space-between;
align-items: center !important;
text-align: center;
margin: 10px 0px;
background-color: ${props=>props.bgColor};
border-radius: 4px;
-webkit-box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
box-shadow: 2px 5px 12px -5px rgba(0,0,0,0.75);
padding: 7px 10px;
& p{
    color: ${props=>props.color};
    font-size: 20px;
    font-weight: 600;
    position: relative;
    top: 7px;
  };

 & .createUser button{
    background-color: ${props=>props.buttonBgColor} !important;
    color: ${props=>props.buttonTxColor} !important;
    outline: none !important;
    border: none !important;
  };
 & .createUser button:hover{
    background-color: ${props=>props.buttonBgColor}66 !important;
    color: ${props=>props.buttonTxColor} !important;
  
  };
 & .createUser button:focus{
    background-color: ${props=>props.buttonBgColor}66 !important;
    color: ${props=>props.buttonTxColor} !important;
  };
`

export const ContainerPageNumber=styled.div`

& input{

    background-color:${props=>props.bgColor}
    color:${props=>props.color} !important;
};
& span{
    color:${props=>props.color} !important;
}
`