import styled from 'styled-components';

export const StyledNotificationBox=styled.div`
    background-color: ${props=>props.bgColor} !important;
    color:${props=>props.color};

`;
export const SettingsCloseIcons2=styled.div`
    width: 100%;
    height: 100px;
    background-color: ${props=>props.bgColor};
    color:${props=>props.color};
    position: relative;
    & .FloatingIcon{
        color:${props=>props.color} !important;
    }
`
export const NoNotification=styled.div`
    position: absolute;
    top: 30%;
    left: 30%;
    transform: translate(-30%, -30%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    & p{
        font-size: 26px;
        font-weight: 400;
        text-align: center;
        color: ${props=>props.color} !important;
    };
`
export const NotificationItem=styled.div`
    display: flex;
    align-items: center;
    flex-direction: ${props=>props?.directions?'row-reverse':'row'} !important;
    background-color: ${props=>props.bgColor};
    border-radius: 7px;
    -webkit-box-shadow: 2px 5px 5px -4px ${props=>props.color};
    -moz-box-shadow: 2px 5px 5px -4px ${props=>props.color};
    box-shadow: 2px 5px 5px -4px ${props=>props.color};
    margin: 5px 15px;
    position: relative;
    &:hover{
        -webkit-box-shadow: 2px 5px 5px -5px ${props=>props.color};
        -moz-box-shadow: 2px 5px 5px -5px ${props=>props.color};
        box-shadow: 2px 5px 5px -5px ${props=>props.color};
    };

`
export const LeftSide=styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    & .subLeftSide{
        color:${props=>props.iconColor} !important;
    };
    & div{
        padding: 10px;
        border-radius: 50%;
        background-color: ${props=>props.color} !important;;
    }
`
export const HeaderSection=styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 15px;
    & h3{
        font-size: 25px !important;
        font-weight: 700;
        color:${props=>props.color} !important;
    };
    & p{
        color:${props=>props.color} !important;
        font-size: 12px !important;
        text-decoration: underline;
        position: relative;
        cursor: pointer;
        top: 8px;
    }
`
export const RightSide=styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: ${props=>props.directions?'end':'start'};
    justify-content: center;
    padding: 8px 0;
    & p:nth-child(1){
        font-weight: 600;
        text-align:left;
        font-size: 18px;
        color: ${props=>props.color};
        position: relative;
        top: 13px;
    };
    & p:nth-child(2){
        font-size: 15px;
        text-align:left;
        color:  ${props=>props.color};
        position: relative;
        top: -5px;
    }
`
export const IconCloseNotificationItem=styled.div`
    position: absolute;
    top: 5px;
    right:5px;
    & div{
        background-color: ${props=>props.bgColor};
        border-radius: 50%;
        padding: 3px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    };
    & .icon{
        color:${props=>props.color}
    }
`