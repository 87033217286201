import React,{useState,useEffect,useMemo,useCallback} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {useSelector,useDispatch} from 'react-redux'
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import SourceSharpIcon from '@mui/icons-material/SourceSharp';
import {useTranslation} from "react-i18next";
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import * as IoIcons from 'react-icons/io5';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
import * as BiIcons from 'react-icons/bi';
import * as FaIcons from 'react-icons/fa';
import * as RiIcons from 'react-icons/ri';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';
import * as CgIcone from 'react-icons/cg';
import * as SiIcone from 'react-icons/si';
import * as RiIcone from 'react-icons/ri';
import * as VscIcone from 'react-icons/vsc';
import * as AiIcone from 'react-icons/ai';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import SideMenuSettings from '../Settings/Components/SideMenuSettings'
import {setCurrentSelectedSettingPages} from '../../reduxStore/SettingsReducer'
// import IconButton from '@mui/material/IconButton';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    zIndex:10,
    height:'100%',
    overflowY:'scroll',
     /* width */
     "& ::-webkit-scrollbar":{
        width: "5px !important"
    },
    
    /* Track */
    "& ::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px grey",
        borderRadius: "10px"
    },
    
    /* Handle */
    "& ::-webkit-scrollbar-thumb":{
        background:"#000",
        borderRadius: "5px"
    },
    
    /* Handle on hover */
    "& ::-webkit-scrollbar-thumb:hover": {
        background: "#538785"
    },
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open,SideMenuOpen,SideMenuDrwerWidth }) => ({
    zIndex:10,
//   position:'relative',
  marginTop:95,
  width:SideMenuOpen?`calc(100% - ${SideMenuDrwerWidth}px)`:'100%',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${SideMenuOpen?drawerWidth+SideMenuDrwerWidth:drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  zIndex:10,
  position:'relative',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const customeDrawer=styled(Drawer)(({theme})=>({
  position: 'relative',
}))

const CustomeAppBar=styled('div')(({theme})=>({
    width:'100%',
    display: 'flex',
    justifyContent:'space-between',
    alignItems:'center',
    textAlign: 'center',
    padding:'10px',

}))

const CustomeWrapper=styled('div')(({theme,MainTheme})=>({
    backgroundColor:MainTheme?.paperColor,
    color:MainTheme?.disabledGlobalText?MainTheme.globalTextColor:MainTheme?.paperTextColor,
    borderRadius:10,
    overflow:'hidden',
    display:'flex',
    height:"530px",
    flex:'1 1 auto',
}))


export default function Setting() {
    const {t, i18n} = useTranslation('common');
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const SideMenuDrwerWidth=useSelector(state=>state.settingsData.drawerWidth);
    const SideMenuOpen=useSelector(state=>state.settingsData.SideMenuOpen);
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);
    const directions = useSelector(state => state.settingsData.directions)
    const SideMenuTheme = useSelector(state => state.settingsData.SideMenuTheme)
    const ToolBarTheme = useSelector(state => state.settingsData.ToolbarTheme)
    const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)
    const currentSelectedSettingPages = useSelector(state=> state.settingsData.currentSelectedSettingPages)
    const [selected,setSelected]=useState(1)
    const dispatch=useDispatch();

    const pagesLinksSettings=[
        {
            id: 1,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.GLOBAL'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.GLOBAL_SETTINGS')} sections={'GLOBAL'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <AiIcone.AiOutlineGlobal color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        }
        ,
        {
            id: 2,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.SIDEMENU'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.SIDEMENU_SETTINGS')} sections={'SIDEMENU'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <RiIcone.RiMenu4Fill color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 3,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.TOOLBAR'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.TOOLBAR_SETTINGS')} sections={'APPBAR'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <FaIcons.FaToolbox color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 4,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.USERS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.USERS_SETTINGS')} sections={'USERS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <FaIcons.FaUsers color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 5,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.VISITS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.VISITS_SETTINGS')} sections={'VISITS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <FaIcons.FaHandshake color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 6,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.POINTS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.POINTS_SETTINGS')} sections={'POINTS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <CgIcone.CgEditBlackPoint color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 7,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.POINTS_REQUESTS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.POINTS_REQUESTS_SETTINGS')} sections={'POINTS_REQUESTS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <SiIcone.SiSitepoint color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 8,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.REGION'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.REGION_SETTINGS')} sections={'REGION'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <RiIcons.RiTreasureMapLine color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 9,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.SURVEY'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.SURVEY_SETTINGS')} sections={'SURVEY'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <RiIcons.RiSurveyLine color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 10,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.JOURNEYS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.JOURNEYS_SETTINGS')} sections={'JOURNEYS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <GiIcons.GiJourney color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 11,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.ROLES'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.ROLES_SETTINGS')} sections={'ROLES'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <RiIcons.RiShieldUserLine color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 12,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.DEPARTMENTS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.DEPARTMENTS_SETTINGS')} sections={'DEPARTMENTS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <MdIcons.MdOutlineCategory  color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 13,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.DISTIRCTS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.DISTIRCTS_SETTINGS')} sections={'DISTIRCTS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <RiIcone.RiPinDistanceFill color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 14,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.FIELD_LISTS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.FIELD_LISTS_SETTINGS')} sections={'FIELD_LISTS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <BiIcons.BiWorld color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 15,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.POINT_STATUS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.POINT_STATUS_SETTINGS')} sections={'POINT_STATUS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <AiIcons.AiOutlineTag color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 16,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.VISIT_REPORT'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.VISIT_REPORT_SETTINGS')} sections={'VISIT_REPORT'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <RiIcons.RiPinDistanceFill color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 17,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.ATTENDANCE_REPORT'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.ATTENDANCE_REPORT_SETTINGS')} sections={'ATTENDANCE_REPORT'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <ImIcons.ImAddressBook color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 18,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.FOLLOWUP_REPORT'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.FOLLOWUP_REPORT_SETTINGS')} sections={'FOLLOWUP_REPORT'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <RiIcons.RiChatFollowUpFill color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 19,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.NOTIFICATIONS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.NOTIFICATIONS_SETTINGS')} sections={'NOTIFICATIONS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <MdIcons.MdOutlineNotificationsActive color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
        {
            id: 20,
            subLinks: [],
            name:  t('SETTINGS_SECTIONS.SETTINGS'),
            component: <SideMenuSettings title={t('SETTINGS_SECTIONS.SETTINGS_SETTINGS')} sections={'SETTINGS'}/>,
            icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <MdIcons.MdSettings color={MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
            </Tooltip>

        },
    ];

    const renderPage=()=>{
        let page=null;
        pagesLinksSettings?.map((itm)=>{
            console.log('itm',itm)
            if(currentSelectedSettingPages===itm?.id){
                page= itm?.component
                
            }
        })
        return page;
    }
  
    const handleDrawerOpen = () => {
      setOpen(!open);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
  return (
    <Box >
      <CssBaseline />
      
      <CustomeAppBar>
        <IconButton
             color="inherit"
             aria-label="open drawer"
             onClick={handleDrawerOpen}
             edge="start"
             sx={{
                border: 'none !important',
                outline: 'none !important',
                '&:hover':{
                    // backgroundColor: MainTheme?.paperTextColor+'55',
                }
             }}
            >
           <MenuSharpIcon onClick={handleDrawerOpen}
            sx={{
             outline:'none',
             border:'none',
             fontSize:'30px',
             opacity:0.89
            }}
            />
        </IconButton>
        <div style={{display:'flex',alignItems:'center'}}>
        
          <SourceSharpIcon sx={{margin:'0 10px',fontSize:'30px'}}/>
          <span style={{fontSize:'23px'}}>{t('SETTINGS.MANDOOBEEN_SETTINGS')}</span>
        </div>
        <div className="div" style={{visibility:'hidden'}}>
            Lorem ipsum
        </div>
      </CustomeAppBar>
      <CustomeWrapper MainTheme={MainTheme}>
        <Drawer
            sx={{
            zIndex:1,
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                position:'relative',
                zIndex:1,
                height:'100% !important',
                overflowY:'scroll',
               /* width */
                "& ::-webkit-scrollbar":{
                    width: "5px"
                },
                
                /* Track */
                "& ::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 5px grey",
                    borderRadius: "10px"
                },
                
                /* Handle */
                "& ::-webkit-scrollbar-thumb":{
                    background:"#4c7c7a",
                    borderRadius: "5px"
                },
                
                /* Handle on hover */
                "& ::-webkit-scrollbar-thumb:hover": {
                    background: "#538785"
                },
                // left:SideMenuOpen?SideMenuDrwerWidth:0,
                // top:150,
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor:MainTheme?.paperColor,
                color:MainTheme?.disabledGlobalText?MainTheme.globalTextColor:MainTheme?.paperTextColor,
                // marginLeft:10,
            },
            }}
            drawerWidth={drawerWidth}
            variant="persistent"
            anchor="left"
            open={open}
        >
            {/* <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            </DrawerHeader> */}
            <List>
                {pagesLinksSettings.map((itmMenu, index) => (
                    <ListItem key={itmMenu?.id} disablePadding onClick={()=>dispatch(setCurrentSelectedSettingPages(itmMenu?.id))}>
                        <ListItemButton>
                            {
                                itmMenu?.icon
                            }
                            <ListItemText primary={itmMenu?.name} sx={{margin:'5px 10px'}} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
           
        </Drawer>
        <Main drawerWidth={drawerWidth} open={open} >
             {
                renderPage()
             }
        </Main>
      </CustomeWrapper>
    </Box>
  )
}
