import styled from 'styled-components';


export const UserSection=styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 15px 0px;
background-color: ${props=>props.checkCon?'#29303d00':props?.bgColor+'33'};
margin: 10px 15px !important;
border-radius: 10px;

& img{
    width: ${props=>props.checkCon?'55px':'90px'};
    height: ${props=>props.checkCon?'55px':'90px'};
    border-radius: 50%;
    object-fit: cover;
    background-color: ${props=>props?.imgBgColor};
};
& p{
    color: ${props=>props?.color};
    position: relative;
    font-size: ${props=>props.checkCon?"10px":'15px'}!important;

};

& .textUserName{
    margin-top: 10px;
};

& .textUserEmail{
    top: -16px;
    color:${props=>props?.color+'77'};
    font-size: 14px;
    display:  ${props=>props.checkCon?"none":'inline-block'} !important;

}

`

export const UserAppheader=styled.div`
height:50px !important;
margin-top:3px;
margin-bottom:3px;
background-color: ${props=>props.lightOrDark=='light'?(props.bgColor+22):props.bgColor+'aa'};
&:hover{
    background-color: ${props=>props.lightOrDark=='light'?(props.bgColor+44):props.bgColor+'88'};;
 };


`