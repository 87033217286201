import React, {useEffect,useState} from 'react';

import PointRequestForm from './PointRequestForm';
import moment from 'moment';
import PointPreview from './PointPreview';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@material-ui/core/IconButton";
// import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
// import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import Tooltip from '@mui/material/Tooltip';

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import red from '@material-ui/core/colors/red';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete';


import { useGlobalContext } from '../../../context'
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import PointsMapDialog from '../Points/PointsMapDialog';
import Grid from '../Share/Grid';
import {useTranslation} from "react-i18next";

function PointRequest() {
    const [t, i18n] = useTranslation('common');

    const [rows,setRow] = useState([])
    const [comparePoint,setcomparePoint] = useState(null)
    const directions =useSelector(state=>state.settingsData.directions)
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)



    const [showPointRequestForm, setshowPointRequestForm] = useState(false);
    const [showPointPreview, setshowPointPreview] = useState(false);
    const [showPointRequestMapDialog,setshowPointRequestMapDialog] = useState(false)
    const [selectedPointRequest, setselectedPointRequest] = useState(null);

    const [pageSize, setPageSize] = useState(5);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)

    const {loading,exportToCSV,setPointsRequestsUpdate,selectedPointRequestStatus,pointRequestStatus,pointsRequestsUpdate,fromToDate,user,district,region,users,pointsRequestsStatuses,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setSelectedPointRequestStatus,setFromDate,setToDate,setPointRequestStatus,setRegion,setUser,pointsRequests,fetchData,deleteObject} = useGlobalContext()        

    const [accExpansion,setAccExpansion] = useState(false)

    const updateObject = async (object) => {
      
        await fetchData('POINT_UPDATE',"points/" + object.point_id);
        setselectedPointRequest(object)
        setshowPointRequestForm(true)
    };
    
    
    const handleExpansion = (expansion) => setAccExpansion(expansion)
    const handleSetshowPointRequestForm = (openStatus,needsReload) => { 
                   if (!openStatus)
                   {
                    setPointsRequestsUpdate({})
                   }
      setshowPointRequestForm(openStatus)
    };
    const handleSetshowPointPreview = (openStatus,needsReload) => {
        if(needsReload)
        advanceSearch()
        setshowPointPreview(openStatus)
    };

    const mapObject= async (object)=>{        
        setselectedPointRequest(object)
        setshowPointRequestMapDialog(true)
    }
    const handleSetshowPointRequestMapDialog = (openStatus,needsReload)=>{
        if(needsReload)
            advanceSearch()
        setshowPointRequestMapDialog(openStatus)
    }
    const advanceSearch = (type = "BROWSING")=>{  
        // console.log(type)
        // console.log(pageNumber)
        fetchData('POINTS_REQUESTS','point_requests',{
            page:type==="SEARCH" ? 1: pageNumber,            
            page_size:pageSize,            
            region:region.id,
            created_by:user.id,
            status:pointRequestStatus.map((status)=>status.id),
            creation_date_from:moment(fromToDate[0])?.format('YYYY-MM-DD'),
            creation_date_to:moment(fromToDate[1])?.format('YYYY-MM-DD'),
        });
    }
    useEffect( () => {                                      
            fetchData('REGIONS','regions_list')
            fetchData('USERS','users_list')         
            fetchData('POINTS_REQUESTS_STATUSES','point_request_statuses_list')
            advanceSearch()
    }, [pageSize,pageNumber,selectedPointRequestStatus])
    
    useEffect(() => {   
        // console.log(pointsRequests.data)
        if (pointsRequests && pointsRequests.data) {                                    
            setRow(pointsRequests.data);         
            setPointsTotal(pointsRequests.total) 
            setPointsNumberOfPages(pointsRequests.last_page)            
        }        
    }, [pointsRequests])
    
    useEffect(() => {
        if (Object.keys(pointsRequestsUpdate).length > 0) {
            setcomparePoint(pointsRequestsUpdate)        
            setshowPointRequestForm(true)                    
        }        
    }, [pointsRequestsUpdate])
    
    const columns = [
        { field: 'id', headerName: t('POINTS_REQUESTS.ID') , flex: .08},
        { field: 'region', headerName: t('POINTS_REQUESTS.LOCATION') , flex: 1.5,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>                               
                  {rows.row.region.district_id === 1 ? "كرخ":"رصافة"}<br/>
                  {rows.row.region.name}
            </div>
        )},
        { field: 'full_name', headerName: t('POINTS_REQUESTS.USER') , flex: 1,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>                               
                  {rows.row.created_by.full_name}                  
            </div>
        )},
        { field: 'name', headerName:t('POINTS_REQUESTS.NAME') , flex: .75},
        {
            field: "owner_name",
            flex: .8,
            headerName: t('POINTS_REQUESTS.OWNER_DETAILS'),
            // width: 300,        
            renderCell: (rows) => (
              <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>                 
                {rows.row.owner_name}<br/>
                {rows.row.phone}
              </div>
            )
        },
        { field: 'created_at', headerName: t('POINTS_REQUESTS.REQUEST_DATA') , flex: 1,
            renderCell: (rows) => (
                <div className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.created_at &&  moment(rows.row.created_at).isValid()? moment(rows.row.created_at).format("DD/MM/YYYY HH:mm:ss"):rows.row.created_at}
                </div>
            )
        },
        { field: 'point_request_status', headerName: t('POINTS_REQUESTS.STATUS') , flex: .8,
            renderCell: (rows) => (
                <div style={{borderRadius: 5,padding:5,color:"white", backgroundColor:rows.row.point_request_status?.color?rows.row.point_request_status?.color:"gray"}} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.point_request_status?.name}
                </div>
            )
        },
        { field: 'actions',flex: 1.2,
            headerName: t('POINTS_REQUESTS.ACTIONS'),                
            renderCell: (param) => (
              <strong className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>                
                <IconButton                  
                  size="small"
                  style={{ marginLeft: 0 }}                  
                  onClick={() => {                               
                    mapObject(param.row)
                    }}>
                <Tooltip title="Locate">                    
                    <LocationOnRoundedIcon style={{fill: "#5cb85c"}}/>
                </Tooltip>
                </IconButton>                
                
                <IconButton  
                    style={{ marginLeft: 15 }}
                    aria-label="action" 
                    size="small" 
                    onClick={() => {                                                 
                        updateObject(param.row)
                    }}
                >
                    <Tooltip title="Action">                    
                        <SettingsApplicationsRoundedIcon style={{ fill: "#111827" }}  />
                    </Tooltip>
                </IconButton>

                <IconButton  
                    style={{ marginLeft: 20 }}
                    aria-label="delete" 
                    size="small" 
                    onClick={                         
                        async () => {                                   
                        setRow(rows.filter((request)=> request.id !== param.row.id ))
                        if (await deleteObject('POINTS_REQUESTS','point_requests',param.row) ===200){                            
                            setRow(rows.filter((request)=> request.id !== param.row.id ))
                        }}
                    }   
                >
                    <Tooltip title="Delete">                    
                        <DeleteRoundedIcon sx={{ color: red[500] }} />
                    </Tooltip>
                </IconButton>                
              </strong>
            ),
        },          
    ]; 
    
    const handlePageChange = (newPage) => {        
        setpageNumber(newPage+1)  
      }
    
    return (
        <>
            {showPointRequestForm?
                <PointRequestForm point={comparePoint} pointRequest={selectedPointRequest} showPointRequestForm={showPointRequestForm} setshowPointRequestForm={handleSetshowPointRequestForm} />
            :null}
            {showPointPreview?            
                <PointPreview pointRequest={selectedPointRequest} showPointPreview={showPointPreview} setshowPointPreview={handleSetshowPointPreview} />
            :null}
            {showPointRequestMapDialog?
            <PointsMapDialog title={t('GLOBAL.MAPS_VIEW_POINT_REQUEST')+" "+selectedPointRequest?.name} points={[selectedPointRequest]} showPointsMapDialog={showPointRequestMapDialog} setshowPointsMapDialog={handleSetshowPointRequestMapDialog} />
            :null}            

            <Box display="flex" >
                <Button display = "flex"
                    startIcon={<FileDownloadIcon  sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
                    variant="contained" 
                    sx={{height:59,marginRight: 1, marginBottom: accExpansion ? "0":"20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
                    onClick = { async () => {
                                        const fetchedData = await   fetchData('POINTS_REQUESTS','point_requests',{
                                            page:pageNumber,
                                            page_size:pointsTotal,
                                            shop_name:shopName,
                                            owner_name:ownerName,
                                            district:district.id,
                                            region:region.id
                                        });
                                        exportToCSV(fetchedData.data,'points_requests')
                                    } 
                             }>
                    {t('GLOBAL.SEARCH_EXPORT_BUTTON')}
                </Button>           
                {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
                <Accordion display = "flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor
                
            }} 
                    onChange={(event,expansion) => handleExpansion(expansion)}
                > 
                    <AccordionSummary 
                        expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography  sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Box component="span" display="flex" bgcolor = "white" flexDirection="column"  sx={{ borderRadius: 1 }} >
                            <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >
                                <Box display="flex" sx={{ flexGrow: 2}}>                                    
                                    <Autocomplete
                                        sx={{ml:1}}
                                        fullWidth                            
                                        options = {regions}                            
                                        getOptionLabel = {(option) => option.name || ""}
                                        id="disable-close-on-select"                        
                                        value = {region}
                                        onChange={(e,v,r) => {setRegion(v ? v:'')}}
                                        renderInput={(params) => (
                                        <TextField {...params} 
                                        label={t('GLOBAL.SEARCH_REGION')} 

                                        variant="outlined" />
                                        )}
                                    />
                                </Box>

                                
                                {/* <SearchField1 state={shopName} setState = {setShopName} fieldLabel='Shop Name'/>                    
                                <SearchField1 state={ownerName} setState = {setOwnerName} fieldLabel='Owner Name'/> */}
                                {/* <BasicDateRangePicker/> */}
                                <BasicDatePicker/>

                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button 
                                        variant="contained" 
                                        spacing={2} sx={{ flexGrow: 1,margin: 1 ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            }}}
                                        onClick = {() => advanceSearch("SEARCH")} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')} 
                                    </Button>
                                    <Button variant="contained" 
                                        spacing={2} 
                                        sx={{ flexGrow: 1,margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            } }} 
                                        color="secondary"
                                        onClick = {clearPointsAdvanceSearchFields} >
                                         {t('GLOBAL.SEARCH_CLEAR_BUTTON')} 

                                    </Button>                       
                                </Box>                
                            </Box>
                            <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >                
                                <Autocomplete                                                 
                                    sx={{ml:1}}
                                    fullWidth                            
                                    options = {users}                            
                                    getOptionLabel = {(option) => option.full_name || ""}
                                    id="disable-close-on-select"                        
                                    value = {user}
                                    onChange={(e,v,r) => {setUser(v ? v:'');}}
                                    renderInput={(params) => (
                                    <TextField {...params} 
                                    label={t('GLOBAL.SEARCH_USERS')}
                                     variant="outlined" />
                                    )}
                                />

                                <Autocomplete                                                 
                                    sx={{ml:1}}
                                    multiple
                                    fullWidth
                                    options = {pointsRequestsStatuses}
                                    getOptionLabel = {(option) => option.name || ""}
                                    id="disable-close-on-select"
                                    value = {pointRequestStatus}
                                    onChange={(e,v,r) => {setPointRequestStatus(v ? v:[]);}}
                                    renderInput={(params) => (
                                    <TextField {...params} 
                                     label={t('GLOBAL.SEARCH_REQUEST_STATUS')}
                                     variant="outlined" />
                                    )}
                                />

                                {/* <BasicDateRangePicker/> */}
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

          

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={pointsTotal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={false}
              createObject={null}
              title_en=""
              title_ar=""
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />  

            
        </>
    );
}

export default PointRequest;


