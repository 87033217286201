import React from 'react'
import Lottie from 'lottie-react-web';
import loading from '../../assets/js/loading.json';
import imgLogo from '../../assets/img/mandoob_img_big_v2.png'
export default function Loading(props) {
  return (
    <div style={{ backgroundColor:props?.hasImage?'#111827':'transparent',width:'100%',height:'100%',display: 'flex',flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}  >
        {
            props?.hasImage?
            <div style={{ width:'100%',height:'100%',display: 'flex', alignItems: 'center', justifyContent: 'center',position:'relative',top:30}}>
                <img src={imgLogo} style={{width:400,height:300,objectFit:'contain'}} alt="" />
            </div>
            :null
        }
    <Lottie
      options={{
        animationData: loading,
      }}
      style={{position:'relative',top:props?.hasImage?'-250px':'0'}}
      width={props?.width?props?.width:300}
      height={props?.height?props?.height:300}
    />
  </div>
  )
}
