import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { useGlobalContext } from '../../../context'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
function AddVisitFollowup(props) {

    const [accExpansion, setAccExpansion] = useState(false)
    const directions=useSelector(state=>state.settingsData.directions)
    const GridTheme=useSelector(state=>state.settingsData.GridTheme)
    const MainTheme=useSelector(state=>state.settingsData.MainTheme)
    const [t, i18n] = useTranslation('common');


    const handleExpansion = (expansion) => setAccExpansion(expansion)
    const [loading, setLoading] = useState(false)

    const {  fetchData, clearPointsAdvanceSearchFields, followupStatusesList, visitFollowupNote, setVisitFollowupNote, followupStatus, setFollowupStatus } = useGlobalContext()

    const submit = async () => {
        
        setLoading(true)
        try {
            console.log("tracking", 3);
            const result = await axios({

                url: Host + "visit_followups",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    followup_status_id: followupStatus?.id,
                    note: visitFollowupNote,
                    visit_id:props?.visitId
                }

            })
            if (result && result.status == 200) {
                props.reload()
                toast.success("Added successfully")
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                toast.error("network error")
            }
        }
        setLoading(false)
    }
    return (

        <Box display="flex" >
            <Accordion display="flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor}}
                onChange={(event, expansion) => handleExpansion(expansion)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('VISITS.FORMVIEW_ADD_VISIT_FOLLOWUP')}</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }} >
                        <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                            <Box display="flex" flex={3}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label={t('VISITS.FORMVIEW_SEARCH_FOLLOWUP_NOTE')}
                                    multiline
                                    fullWidth
                                    maxRows={4}
                                    value={visitFollowupNote}
                                    onChange={(event) => {
                                        setVisitFollowupNote(event.target.value);
                                    }}
                                />
                            </Box>
                            <Box display="flex" flex={2}>
                                <Autocomplete
                                    sx={{ ml: 1 }}
                                    fullWidth
                                    options={followupStatusesList}
                                    getOptionLabel={(option) => option.name || ""}
                                    id="disable-close-on-select"
                                    value={followupStatus}
                                    onChange={(e, v, r) => { setFollowupStatus(v ? v : '') }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('VISITS.FORMVIEW_SEARCH_STATUS')} variant="outlined" />
                                    )}
                                />
                            </Box>




                            <Box display="flex" flex={1}>
                                <Button
                                    variant="contained"
                                    disabled={loading}
                                    spacing={2} sx={{ flexGrow: 1, margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                        '&:hover':{
                                            backgroundColor:GridTheme?.secondaryColor+'88',
                                        } }}
                                    onClick={() => {

                                        submit()
                                    }} >
                                    {loading ? t('VISITS.FORMVIEW_SEARCH_LOADING_BUTTON') : t('VISITS.FORMVIEW_SEARCH_SAVE_BUTTON')}
                                </Button>
                                {/* <Button variant="contained"
                                    spacing={2}
                                    sx={{ flexGrow: 1, margin: 1 }}
                                    color="secondary"
                                    onClick={clearPointsAdvanceSearchFields} >
                                    Clear
                                </Button> */}
                            </Box>
                        </Box>

                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default AddVisitFollowup