import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Context from '../../assets/js/context';
import Cookies from 'universal-cookie';
import SideNavBar, { pagesLinks } from './Share/SideNavBar';




const cookies = new Cookies();



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: ' #fff !important',
  },
  
}))



export default function AppBarAndContainer(props) {

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      // console.log(getWindowDimensions())
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const props1 = {
    height: windowDimensions.height
  }
  const classes = useStyles(props1);


  var timer

  
  const renderPage = (props) => {
    let roles = []

    try {
      
      roles = cookies.get("roles")
      // roles=roles?.map(role=>role?.id)
    } catch (error) {
     
      
    } 
    let page = null
     pagesLinks.map((pageLink)=>{
      
      if(props?.match?.path === pageLink?.to && roles?.includes(pageLink?.id))
      {

        page =  pageLink?.component
      }
      else if(props?.match?.path === pageLink?.to )
      {
        
        page = null//TODO  not authorized page
      }
      

    })
    if(!page)
    {
        page = null//TODO  not found page
      
    }
    return page
    

  }



  useEffect(() => {

    return () => {
      clearTimeout(timer);
    }
  }, [])




  return (


    <Context.Consumer>{ctx => {


      if (ctx.value.che === "notlogin") {
        return (
          <Redirect to="/"></Redirect>
        )
      } else if (ctx.value.che === "login") {
        return (
          <div  className={classes.root}>
           
           

            <SideNavBar renderPage={renderPage} propsValue={props}/>
           

          </div>

        )
      } else if (ctx.value.che === "") {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}  >

           
          </div>
        )
      }

    }}

    </Context.Consumer>



  );

}


