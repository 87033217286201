import styled from 'styled-components';


export const SIdeMenuLaout=styled.div`
        width: 90%;
        height: auto;
        padding: 10px 1px ;
        border: 1px solid ${props=>props.color}60; 
        margin: 15px;
        position: relative;
        border-radius: 5px;
        &:before{
            content: '${(props) => props.titleSection }';
            position: absolute;
            top:-15px;
            left: ${(props) => props.leftProps }%;
            background-color: ${props=>props.bgColor};
            padding: 2px 5px;
            width:auto;
            font-weight: 600;
            color: ${props=>props.color};
        };
        & .listItem .ColorText span{
            color: ${props=>props.color} !important;
        };
        & .listItem .ColorText .MuiSwitch-thumb{
            color: ${props=>props.secondaryColor} !important;
        };
        & .listItem .MuiSwitch-track{
            background-color: ${props=>props.secondaryColor}66 !important;
        };
        & .listItem .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
            background-color: ${props=>props.secondaryColor}bb !important;
        };
`;
export const ThemeLayout=styled.div`
        width: 90%;
        height: auto;
        padding: 2% 1px ;
        border: 1px solid ${props=>props.color}60; 
        margin: 15px;
        position: relative;
        border-radius: 5px;
        &:before{
            content: '${(props) => props.titleSection}';
            position: absolute;
            top:-15px;
            left:${(props) => props.leftProps }%;
            background-color: ${props=>props.bgColor};
            padding: 2px 5px;
            width:auto;
            font-weight: 600;
            color: ${props=>props.color};
        };
        & .listItem .ColorText span{
            color: ${props=>props.color} !important;
        };
        & .listItemImgContainer p{
            color: ${props=>props.color}ca !important;
        }
`;
export const SettingHeader=styled.h3`
    font-size: 25px !important;
    padding:30px 10px 20px 10px;
    font-weight: 700;
`;
export const SettingsCloseIcons=styled.div`
    position: absolute;
    top: 3.7%;
    right:1%;
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    cursor: pointer;
    border-radius: 50px;
    color:${props=>props.color};
    &:hover{
      background-color: ${props=>props.color}30;
    };
    & .icon{
     color:${props=>props.color};
    }
`