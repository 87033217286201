import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useGlobalContext } from '../../../context'
import { Box, Typography } from '@mui/material';
import {useTranslation} from "react-i18next";

export default function BasicDatePicker({Language}) {
  const { fromToDate, setFromToDate } = useGlobalContext()
  const [value, setValue] = React.useState(null);
  const [t, i18n] = useTranslation('common');

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      paddingLeft: '5px',
      paddingRight: '5px'
    }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={t('GLOBAL.SEARCH_FROM_DATE')}
          value={fromToDate[0]}

          onChange={(newValue) => {
            // setValue(newValue);

            setFromToDate([newValue, fromToDate[1]])
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Typography sx={{
        color: '#000000',
        paddingLeft: '5px',
        paddingRight: '5px'
      }}>
        {t('GLOBAL.SEARCH_TO_WORD')}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={t('GLOBAL.SEARCH_TO_DATE')}
          value={fromToDate[1]}
          onChange={(newValue) => {
            // setValue(newValue);
            setFromToDate([fromToDate[0], newValue])
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>

  );
}
