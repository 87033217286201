import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { AiFillCloseCircle } from 'react-icons/ai'
import { Divider, Switch, CardMedia, Box, DialogTitle, IconButton, TextField, Typography, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Button } from '@mui/material';
import GoogleMap from "../GoogleMap/GoogleMap";
import {useSelector,useDispatch} from 'react-redux'
import classifyPoint from "robust-point-in-polygon"
import DialogForm from "../Share/DialogForm";
import { styled } from '@mui/material/styles';
import {useTranslation} from "react-i18next";


const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const PointForm = (props) => {
    const [imageSource, setImageSource] = useState(null)
    const [loading, setloading] = useState(true);
    const directions= useSelector(state=> state.settingsData.directions)
    const MainTheme= useSelector(state=> state.settingsData.MainTheme)
    const [t, i18n] = useTranslation('common');

    const [ownerTypes, setownerTypes] = useState([]);
    const [locationCategories, setlocationCategories] = useState([]);
    const [categories, setcategories] = useState([]);
    const [pointTypes, setpointTypes] = useState([]);
    const [regions, setregions] = useState([]);
    const [classifications, setclassifications] = useState([]);
    const [bgColor,setBgColor]=useState('');
    const styles = theme => ({
        select: {
          "& option": {
            background: theme.palette.background.paper
          }
        }
      });

    const [object, setobject] = useState({
        id: null,
        name: null,
        owner_name: null,
        owner_type_id: null,
        owner_type: {
            id: null,
            name: null,
        },
        phone: null,
        image: null,
        near_by: null,
        location_category_id: null,
        location_category: {
            id: null,
            name: null
        },
        classification_id: null,
        classification: {
            id: null,
            name: null
        },
        category_id: null,
        category: {
            id: null,
            name: null
        },
        has_delivery: false,
        external_customer_id: null,
        point_type_id: null,
        point_type: {
            id: null,
            name: null
        },
        longitude: null,
        latitude: null,
        region_id: null,
        region: {
            id: null,
            name: null,
            region_geofences:[]
        },

    });
    const getResources = async () => {
        try {
            setloading(true)
            var resourcesResult = await axios({
                url: Host + "owner_types_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    setownerTypes(resources);

                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error owner")
            }
            resourcesResult = await axios({
                url: Host + "location_categories_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // //console.log(resources);
                    setlocationCategories(resources);

                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error location category")
            }
            resourcesResult = await axios({
                url: Host + "classifications_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // //console.log(resources);
                    setclassifications(resources);

                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            resourcesResult = await axios({
                url: Host + "categories_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // //console.log(resources);
                    setcategories(resources);

                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            resourcesResult = await axios({
                url: Host + "point_types_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // //console.log(resources);
                    setpointTypes(resources);

                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            resourcesResult = await axios({
                url: Host + "regions_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // //console.log(resources);
                    setregions(resources);

                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }

            if (props && props.point && props.point.id > 0) {
                setobject({ ...object, ...props.point });
            }
            else {

                setobject(
                    {
                        id: null,
                        name: null,
                        owner_name: null,
                        owner_type_id: null,
                        owner_type: {
                            id: null,
                            name: null,
                        },
                        phone: null,
                        image: null,
                        near_by: null,
                        location_category_id: null,
                        location_category: {
                            id: null,
                            name: null
                        },
                        classification_id: null,
                        classification: {
                            id: null,
                            name: null
                        },
                        category_id: null,
                        category: {
                            id: null,
                            name: null
                        },
                        has_delivery: false,
                        external_customer_id: null,
                        point_type_id: null,
                        point_type: {
                            id: null,
                            name: null
                        },
                        longitude: null,
                        latitude: null,
                        region_id: null,
                        region: {
                            id: null,
                            name: null,
                        },

                    }
                )
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {

        try {
            if( !object?.name)
            {
                toast.error("please enter a name")
                return
            }
            if(!object?.region?.id )
            {
                toast.error("please select a region")
                return
            }

            if(!object?.latitude ||  !object?.latitude)
            {
                toast.error("please select a point location")
                return
            }
            
            const isPointInRegion = classifyPoint(object?.region?.region_geofences?.map((regionGeofence)=>{
                return [regionGeofence?.latitude,regionGeofence?.longitude]
            })
            ,
            [object?.latitude,object?.longitude])

            if(isPointInRegion>0)
            {
                toast.error("The point is outside the region's geofence")
                // return
            }
            else
            {
                toast.error("The point is insied the region's geofence")
                // return
            }
         
            


            let formdata = new FormData();

            //console.log(object);
            if (object.name)
                formdata.append("name", object.name)

            if (object.owner_name)
                formdata.append("owner_name", object.owner_name)
            if (object.owner_type_id) formdata.append("owner_type_id", object.owner_type_id)
            if (object.latitude) formdata.append("latitude", object.latitude)
            if (object.longitude) formdata.append("longitude", object.longitude)
            if (object.near_by) formdata.append("near_by", object.near_by)
            if (object.has_delivery) formdata.append("has_delivery", object.has_delivery)
            if (object.region_id) formdata.append("region_id", object.region_id)
            if (object.point_type_id) formdata.append("point_type_id", object.point_type_id)
            if (object.location_category_id) formdata.append("location_category_id", object.location_category_id)
            if (object.classification_id) formdata.append("classification_id", object.classification_id)
            if (object.category_id) formdata.append("category_id", object.category_id)
            if (object.external_customer_id) formdata.append("external_customer_id", object.external_customer_id)
            if (object.phone) formdata.append("phone", object.phone)
            if (object.image) formdata.append("image", object.image)



            if (object.id) {

                formdata.append("_method", "PUT");
                const resourcesResult = await axios({
                    url: Host + "points/" + object.id,
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowPointForm(false, true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowPointForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowPointForm(true, false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "points",
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowPointForm(false, true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowPointForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowPointForm(true, false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowPointForm(true, false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {

                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {

                    toast.error(resourcesResult.data.data)
                }


            }
            else if (resourcesResult && resourcesResult.status === 401) {

                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {

                toast.error("network error")
            }
        }

    };

    const handleInputChange = (event) => {
        const target = event.target;

        if (target.checked) {
            setobject({ ...object, has_delivery: 1 });
        }
        else {
            setobject({ ...object, has_delivery: 0 });
        }


    };

    const checkDarknessOrLightnessForColor=(color)=>{
        // Variables for red, green, blue values
        var r, g, b, hsp;
      
        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
    
            // If RGB --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            
            r = color[1];
            g = color[2];
            b = color[3];
        } 
        else {
            
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace( 
            color.length < 5 && /./g, '$&$&'));
    
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }
        
        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
        );
    
        // Using the HSP value, determine whether the color is light or dark
        if (hsp>127.5) {
    
            return false;//light
        } 
        else {
    
            return true;//dark
        }
  
    };

    const check=checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)
    

    const styleTextField={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }

    const styleSelect={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }

    useEffect(() => {
        if (loading)
            getResources()



        return () => {

        };
    }, [loading, props.showPointForm]);
    //console.log("object?.owner_type", object?.owner_type);
    return (
        <DialogForm
          open={props.showPointForm}
          close={props.setshowPointForm}
        >

        

          
                <IconButton aria-label={"Close"} onClick={() => { props.setshowPointForm(false) }} style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '50px', }}>
                    <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor }} />

                </IconButton>
                <Typography component={'h1'} variant={'h4'} style={{ color: MainTheme?.paperTextColor, width: '100%', textAlign: 'center' }}>
                    {props?.title ? props?.title : t('POINTS.FORMADD_TITLE')}
                </Typography>

         

                {loading ? <>Loading...</> :

                    <Box style={{
                        padding: '10px',
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start'

                    }}>
                        <Box style={{

                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',

                            width: '100%',

                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start'

                        }}>
                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flex: 1,
                                color:MainTheme?.paperTextColor,
                         
                            
                            
                            }}>
                                <TextField
                                    id="outlined-basic-name"
                                     label={t('POINTS.NAME')} 
                                     variant="outlined"
                                    placeholder={t('POINTS.NAME')}
                                    
                                    value={object?.name}
                                    fullWidth
                                    sx={{...styleTextField }}
                                    onChange={(event) => {
                                        //console.log("name", event);
                                        setobject({ ...object, name: event.target.value });
                                    }}
                                />
                                < TextField
                                    sx={{ marginTop: '10px',...styleTextField }}
                                    id="outlined-basic-owner-name"
                                     label={t('POINTS.OWNER_NAME')} 
                                     variant="outlined"
                                    placeholder={t('POINTS.OWNER_NAME')}
                                    
                                    fullWidth
                                    value={object?.owner_name
                                    }
                                    onChange={(event) => {
                                        setobject({ ...object, owner_name: event.target.value });
                                    }}
                                />
                                < TextField
                                    sx={{ marginTop: '10px',...styleTextField }}
                                    id="outlined-basic-phone"
                                     label={t('POINTS.PHONE')}
                                      variant="outlined"
                                    placeholder={t('POINTS.PHONE')}
                                    fullWidth
                                    value={object?.phone}
                                    onChange={(event) => {
                                        setobject({ ...object, phone: event.target.value });
                                    }}
                                />
                                < TextField
                                    sx={{ marginTop: '10px',...styleTextField }}
                                    id="outlined-basic-near-by" 
                                    label={t('POINTS.FORMVIEW_NEAR_BY')}
                                     variant="outlined"
                                    placeholder={t('POINTS.FORMVIEW_NEAR_BY')}
                                    fullWidth
                                    value={object?.near_by
                                    }
                                    onChange={(event) => {
                                        setobject({ ...object, near_by: event.target.value });
                                    }}
                                />
                                < TextField
                                    sx={{ marginTop: '10px',...styleTextField }}
                                    id="outlined-basic-external-customer-id"
                                     label={t('POINTS.FORMADD_EXTERNAL_CUSTOMER_ID')}
                                      variant="outlined"
                                    placeholder={t('POINTS.FORMADD_EXTERNAL_CUSTOMER_ID')}
                                    fullWidth
                                    value={object?.external_customer_id
                                    }
                                    onChange={(event) => {
                                        setobject({ ...object, external_customer_id: event.target.value });
                                    }}
                                />

                                <Box sx={{
                                    display: 'flex',
                                    height: '60px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    
                                    " & span":{
                                            color: MainTheme?.secondaryColor
                                        },
                                        "& .MuiSwitch-track":{
                                            backgroundColor:MainTheme?.secondaryColor+'66'
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":{
                                            backgroundColor:MainTheme?.secondaryColor+'ee'
                                        },
                                    
                                }}>
                                    <Button variant="contained" component="label" style={{backgroundColor:MainTheme?.secondaryColor,color:MainTheme?.oppositeSecondaryColor}}>
                                    {t('POINTS.FORMVIEW_IMAGE')}
                                        <input hidden accept="image/*" onChange={(event) => {
                                            let files = []
                                            for (let i = 0, numFiles = event?.target?.files.length; i < numFiles; i++) {
                                                const file = event?.target?.files[i];
                                                files.push(file)
                                                // …
                                            }
                                            if (event?.target?.files?.length > 0) {
                                                const reader = new FileReader()
                                                reader.onload = ev => {
                                                    setImageSource(ev.target.result)
                                                }
                                                reader.readAsDataURL(files[0])
                                                setobject({
                                                    ...object,
                                                    image: files[0]
                                                })
                                            }
                                        }} multiple={false} type="file" />
                                    </Button>
                                    {imageSource ?
                                        <CardMedia
                                            component="img"
                                            alt=""
                                            sx={{
                                                objectFit: 'contain',
                                                height: '50px',
                                            }}

                                            image={imageSource}
                                        />:
                                        <Typography sx={{ color: MainTheme?.paperTextColor }}>
                                            {t('POINTS.FORMVIEW_MESSAGE_NO_IMAGE')}
                                        </Typography>
                                    }
                                    <FormControlLabel control={
                                        <Switch
                                            checked={object?.has_delivery}
                                            onChange={(e) => handleInputChange(e)}
                                            inputProps={{ 'aria-label': 'Has delivery' }}
                                            
                                        />
                                    } label={<Typography sx={{ color:  MainTheme?.paperTextColor }}>{t('POINTS.FORMVIEW_HAS_DELIVERY')}</Typography>} />

                                </Box>

                            </Box>

                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                flex: 1,
                                justifyContent: 'flex-start',
                                marginLeft: '20px',
                                alignItems: 'center'

                            }}>

                                <FormControl fullWidth sx={{...styleSelect}}>
                                    <InputLabel id="owner-type-select-label" sx={{color:MainTheme?.paperTextColor}}>{t('POINTS.OWNER_TYPE')}</InputLabel>
                                    <Select
                                        labelId="owner-type-select-label"
                                        id="owner-type-select"
                                        value={object?.owner_type}
                                        label={t('POINTS.OWNER_TYPE')}
                                        sx={{textAlign:'left'}}
                                        renderValue={(value) => {
                                            //console.log("value", value);
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.name}`
                                            }
                                            else {

                                                return <Typography sx={{ color:  MainTheme?.paperTextColor }}>{t('POINTS.FORMADD_SELECT_OWNER_TYPE')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, owner_type: {
                                                    ...event?.target?.value
                                                }, owner_type_id: event.target.value?.id
                                            });
                                        }}
                                    >
                                        {ownerTypes?.length > 0 && ownerTypes?.map((ownerType) => {

                                            return <MenuItem key={ownerType?.id}    value={ownerType}>{ownerType?.name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ marginTop: '9px',...styleSelect }}>
                                    <InputLabel id="region-select-label" sx={{color:MainTheme?.paperTextColor}}>{t('POINTS.REGION')}</InputLabel>
                                    <Select
                                        labelId="region-select-label"
                                        id="region-select"
                                        sx={{textAlign:'left'}}
                                        value={object?.region}
                                        label={t('POINTS.REGION')}
                                        renderValue={(value) => {
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.name}`
                                            }
                                            else {

                                                return <Typography sx={{ color:  MainTheme?.paperTextColor }}>{t('POINTS.FROMADD_SELECT_REGION')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, region: {
                                                    ...event?.target?.value
                                                }, region_id: event.target.value?.id
                                            });
                                        }}
                                    >
                                        {regions?.length > 0 && regions?.map((region) => {

                                            return <MenuItem key={region?.id} value={region}>{region?.name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ marginTop: '9px',...styleSelect }}>
                                    <InputLabel id="category-select-label" sx={{color:MainTheme?.paperTextColor}}>{t('POINTS.FORMVIEW_CATEGORY')}</InputLabel>
                                    <Select
                                        labelId="category-select-label"
                                        id="category-select"
                                        sx={{textAlign:'left'}}
                                        value={object?.category}
                                        label={t('POINTS.FORMVIEW_CATEGORY')}
                                        renderValue={(value) => {

                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.name}`
                                            }
                                            else {

                                                return <Typography sx={{ color:  MainTheme?.paperTextColor }}>{t('POINTS.FROMADD_SELECT_CATEGORY')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, category: {
                                                    ...event?.target?.value
                                                }, category_id: event.target.value?.id
                                            });
                                        }}
                                    >
                                        {categories?.length > 0 && categories?.map((category) => {

                                            return <MenuItem key={category?.id} value={category}>{category?.name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ marginTop: '9px',...styleSelect }}>
                                    <InputLabel id="location-category-select-label" sx={{color:MainTheme?.paperTextColor}}>{t('POINTS.FROMADD_SELECT_LOCATION_CATEGORY')}</InputLabel>
                                    <Select
                                        labelId="location-category-select-label"
                                        id="location-category-select"
                                        sx={{textAlign:'left'}}
                                        value={object?.location_category}
                                        label={t('POINTS.FROMADD_SELECT_LOCATION_CATEGORY')}
                                        renderValue={(value) => {
                                            //console.log("value", value);
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.name}`
                                            }
                                            else {

                                                return <Typography sx={{ color:  MainTheme?.paperTextColor }}>{t('POINTS.FROMADD_SELECT_LOCATION_CATEGORY')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, location_category: {
                                                    ...event?.target?.value
                                                }, location_category_id: event.target.value?.id
                                            });
                                        }}
                                    >
                                        {locationCategories?.length > 0 && locationCategories?.map((locationCategory) => {

                                            return <MenuItem key={locationCategory?.id} value={locationCategory}>{locationCategory?.name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ marginTop: '9px',...styleSelect }}>
                                    <InputLabel id="classification-select-label" sx={{color:MainTheme?.paperTextColor}}>{t('POINTS.FORMADD_SELECT_CLASS')}</InputLabel>
                                    <Select
                                        labelId="classification-select-label"
                                        id="classification-select"
                                        sx={{textAlign:'left'}}
                                        value={object?.classification}
                                        label={t('POINTS.FORMADD_SELECT_CLASS')}
                                        renderValue={(value) => {
                                            //console.log("value", value);
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.name}`
                                            }
                                            else {

                                                return <Typography sx={{ color:  MainTheme?.paperTextColor}}>{t('POINTS.FORMADD_SELECT_CLASS')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, classification: {
                                                    ...event?.target?.value
                                                }, classification_id: event.target.value?.id
                                            });
                                        }}
                                    >
                                        {classifications?.length > 0 && classifications?.map((classification) => {

                                            return <MenuItem key={classification?.id} value={classification}>{classification?.name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ marginTop: '9px',...styleSelect }}>
                                    <InputLabel id="point-type-select-label" sx={{color:MainTheme?.paperTextColor}}>{t('POINTS.FROMADD_STATUS')}</InputLabel>
                                    <Select
                                        labelId="point-type-select-label"
                                        id="point-type-select"
                                        sx={{textAlign:'left'}}
                                        value={object?.point_type}
                                        label={t('POINTS.FROMADD_STATUS')}
                                        renderValue={(value) => {
                                            //console.log("value", value);
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.name}`
                                            }
                                            else {

                                                return <Typography sx={{ color:  MainTheme?.paperTextColor }}>{t('POINTS.FROMADD_SELECT_STATUS')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, point_type: {
                                                    ...event?.target?.value
                                                }, point_type_id: event.target.value?.id
                                            });
                                        }}
                                    >
                                        {pointTypes?.length > 0 && pointTypes?.map((pointType) => {

                                            return <MenuItem key={pointType?.id} value={pointType}>{pointType?.name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>

                            </Box>
                        </Box>
                        <Divider sx={{ marginTop: '10px', width: '100%' }} />
                        <Box style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}>


                            <Typography component={'h1'} variant={'h6'} sx={{ color:  MainTheme?.paperTextColor }}>
                                {t('POINTS.FROMADD_SUBTITLE_MESSAGE')}
                            </Typography>

                        </Box>
                        <Divider sx={{ marginBottom: '10px', width: '100%' }} />
                        <Box style={{

                            padding: '10px',
                            paddingTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '400px',
                            width: '100%',
                            justifyContent: 'center',

                            alignItems: 'center'

                        }}>

                            <GoogleMap
                            createPoly
                                markers={object?.latitude ? [object] : []}
                                polygon={
                                    
                                    object?.region?.region_geofences && object?.region?.region_geofences?.length>0  ? 
                                    {
                                    paths: object?.region?.region_geofences?.map((geoFence)=>{
                                        return {
                                             lat: geoFence?.latitude, 
                                             lng: geoFence?.longitude,
                                             id:geoFence?.id
                                             
                                        }
                                    }),
                                    strokeColor: "#FF0000",
                                    fillColor: "#FF0000"
                                    
                                } : null}
                                draggable={true}

                                setMarkers={(markers) => {
                                    try {
                                        const latitude = markers[0].latitude
                                        const longitude = markers[0].longitude
                                        setobject({
                                            ...object,
                                            latitude: latitude,
                                            longitude: longitude
                                        });
                                    } catch (error) {

                                    }

                                }}
                                onClick={(value) => {
                                   
                                    setobject({
                                        ...object,
                                        latitude: value.lat,
                                        longitude: value.lng
                                    });
                                }} />
                        </Box>
                        <Box style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}>
                            <Button sx={{color:MainTheme?.paperTextColor}} onClick={() => {
                                submitForm()
                            }}>{t('POINTS.FROMADD_SUBMIT_BUTTON')}</Button>
                        </Box>

                    </Box>
                }


    </DialogForm>

    );
};


export default PointForm;
