import { createSlice } from '@reduxjs/toolkit';


const initialState={
    data:{},
    userName:'',
    roleName:'',
    phone:null,
    image:'',
}
export const userSlice = createSlice({
  name: 'userData',
  initialState:initialState,
  reducers: {
    setUserName:(state,action)=>{
        state.userName=action.payload
    },
    setUserPhone:(state,action)=>{
       state.phone=action.payload
    },
    setUserRoleName:(state,action)=>{
       state.roleName=action.payload
    },
    setUserData:(state,action)=>{
        state.data=action.payload
    },
    
  },
});

export const {setUserName,setUserPhone,setUserData,setUserRoleName } = userSlice.actions;

export const userName = state => state.settingsData.userName;
export const phone = state => state.settingsData.phone;
export const roleName = state => state.settingsData.roleName;
export const data = state => state.settingsData.data;



export default userSlice.reducer;
