import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React,{useMemo,useEffect,useState} from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Context from './assets/js/context';
import Login from './component/main/Login';
import AppBarAndContainer from './component/main/AppBarAndContainer';
import { ToastContainer } from "react-toastify";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/test1.css';
import SideNavBar from './component/main/Share/SideNavBar';
import { Box, StyledEngineProvider } from '@mui/material';
import Cookies from "universal-cookie";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { StyleSheetManager } from 'styled-components';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useSelector, useDispatch } from 'react-redux'
import Loading from './component/Settings/Loading'

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
 
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";

// import { create } from 'jss';
// import rtl from 'jss-rtl';
// import { StylesProvider, jssPreset } from '@mui/styles';



// Configure JSS
// const jss = create({
//   plugins: [...jssPreset().plugins, rtl()],
// });
 const cookies=new Cookies();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ms: [],
      data1: [],
      Dash: [],
      check: '',
      che: 'login',
      new: '',
      inprogress: '',
      closed: '',
      approved: '',
      rejected: '',
      archived: '',
      noti: '',
      desc: '',
      commenter: '',
      body: '',
      title: "",
      SiFilter: [],
    };
 

  }

  componentDidMount() {

  }





  render() {
    return (
          <BrowserRouter>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            

            <Context.Provider value={{
              value: this.state,
              action: {
              }
            }} >

              <Switch>

                <Route exact path='/' component={Login} />
                
                <Route path='/Users' component={AppBarAndContainer} />
                <Route path='/Roles' component={AppBarAndContainer} />
                <Route path='/Dashboard' component={AppBarAndContainer} />
                <Route path='/Surveys' component={AppBarAndContainer} />
                <Route path='/build_survey' component={AppBarAndContainer} />
                <Route path='/PointStatus' component={AppBarAndContainer} />
                <Route path='/PointRequests' component={AppBarAndContainer} />
                <Route path='/Points' component={AppBarAndContainer} />
                <Route path='/Visits' component={AppBarAndContainer} />
                <Route path='/Departments' component={AppBarAndContainer} />
                <Route path='/Districts' component={AppBarAndContainer} />
                <Route path='/Regions' component={AppBarAndContainer} />
                <Route path='/Journeys' component={AppBarAndContainer} />
                <Route path='/user_daily_plans' component={AppBarAndContainer} />
                <Route path='/field_lists' component={AppBarAndContainer} />
                <Route path='/field_list_values' component={AppBarAndContainer} />
                <Route path='/visits_report' component={AppBarAndContainer} />
                <Route path='/attendance_report' component={AppBarAndContainer} />
                <Route path='/followup_report' component={AppBarAndContainer} />
                <Route path='/companies' component={AppBarAndContainer} />
                <Route path='/navbar' component={SideNavBar} />
                <Route path='/settings' component={AppBarAndContainer} />



                

              </Switch>
            </Context.Provider>
          </BrowserRouter>
    );
  }
}


// Create rtl cache
// const cacheRtl = createCache({
//   key: 'muirtl',
//   stylisPlugins: [prefixer, rtlPlugin],
// });

function Main(){
  const directions = useSelector(state=> state.settingsData.directions)
  const MainTheme = useSelector(state=> state.settingsData.MainTheme);
  const GridTheme = useSelector(state=> state.settingsData.GridTheme);

  const Translate_en = useSelector(state=> state.translateData.translation_en)
  const Translate_ar = useSelector(state=> state.translateData.translation_ar)


  const [cacheRtl,setCacheRtl]=useState(createCache({ key: 'css' }));
  const [loading,setLoading]=useState(false)
  const [theme,setTheme]=useState(createTheme({
    direction: directions, // Both here and <body dir="rtl">
  }))

  i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: directions=='rtl'?'ar':'en',                              // language to use
    resources: {
        en: {
            common: Translate_en               // 'common' is our custom namespace
        },
        ar: {
            common: Translate_ar
        },
    },
  });


 useEffect(() => {
    if (directions=='rtl' ) {
        setCacheRtl(
          createCache({
            key: 'muirtl',
            stylisPlugins: [rtlPlugin,prefixer],
           })
        )
        document.dir='rtl';
        cookies.set('language','ar')
    } else {
      setCacheRtl(createCache({ key: 'css' }))
      document.dir='ltr';
      cookies.set('language','en')
    }

    setTheme( createTheme({
      direction: directions, // Both here and <body dir="rtl">
    }))




}, [directions]);

const isrtl=React.useMemo(()=>{
  return createCache({
      key: directions=='rtl' ? 'muirtl' : 'muiltr',
      stylisPlugins: directions=='rtl' ? [rtlPlugin] : [],
    });
},[directions])

useEffect(()=>{
  if(MainTheme?.bgColor)
   document.querySelector('body').style.backgroundColor=MainTheme?.bgColor
},[MainTheme]);

useEffect(()=>{
  setTimeout(()=>{
    setLoading(false)
  },5000)
},[])
  


  return <Box >
           <CacheProvider value={isrtl}>
              <ThemeProvider theme={theme}>
                  <I18nextProvider i18n={i18next}>
                  {
                    loading?
                    <Loading width={600} height={600} hasImage={true}/>
                    :
                      <App/>
                  }
                  </I18nextProvider>
              </ThemeProvider>
           </CacheProvider>
      </Box>
}

export default Main;