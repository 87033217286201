import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import AutoComplete from "./AutoComplete";
import {useSelector,useDispatch} from 'react-redux'
import DialogForm from "../Share/DialogForm";
import {useTranslation} from "react-i18next";

import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import { MainTheme } from "../../reduxStore/SettingsReducer";
import FooterBottons from '../Share/FooterBottons'

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const SurveyForm = (props) => {
    const [loading, setloading] = useState(true);
    const [selectedPoint, setselectedPoint] = useState({
        id:null,
        name:''
    });
    const [t, i18n] = useTranslation('common');

    const directions =useSelector(state=> state.settingsData.directions);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);

    const [pointTypes, setpointTypes] = useState([]);
    const [object, setobject] = useState({
        id: null,
        name: null,
        point:{
            id:null,
            name:null
        },
        point_id:null,
        point_type_id: null,
        point_type:{
            id:null,
            name:null
        }
    });
    const getResources = async () => {
        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "point_types_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setpointTypes(resources.map((resource)=>{
                        return {
                            value:resource.id,
                            label:resource.name
                        }
                        
                    }));
                    if(props&& props.survey&& props.survey.id>0)
                    {
                        setselectedPoint({...selectedPoint,id:props?.survey?.point?.id,name:props?.survey?.point?.name})
                        setobject({...object,...props.survey});
                    }
                    else{
                        
                        setobject(
                            {
                                id: null,
                                name: null,
                                
                                point_type_id: null,
                                point_type:{
                                    id:null,
                                    name:null
                                }
                            }
                        )
                    }
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {
        
        try {
            
        
            var objectToSend=object
            if(!object.point_id)
            {
                objectToSend={
                    id:objectToSend.id,
                    name:objectToSend.name,
                    point_type_id:objectToSend.point_type_id
                }
            }
            if(!object.point_type_id)
            {
                objectToSend={
                    id:objectToSend.id,
                    name:objectToSend.name,
                    point_id:objectToSend.point_id
                }
            }
            if (object.id) {

                const resourcesResult = await axios({
                    url: Host + "surveys/"+object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: objectToSend
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowSurveyForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowSurveyForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowSurveyForm(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "surveys",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: objectToSend
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowSurveyForm(false,true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowSurveyForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowSurveyForm(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowSurveyForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const setselectedObject=(point)=>{
        setselectedPoint(point)
        setobject({
            ...object,
            point_id:point?.id,
            point:point
        })
    }

    const checkDarknessOrLightnessForColor=(color)=>{
        // Variables for red, green, blue values
        var r, g, b, hsp;
      
        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
    
            // If RGB --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            
            r = color[1];
            g = color[2];
            b = color[3];
        } 
        else {
            
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace( 
            color.length < 5 && /./g, '$&$&'));
    
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }
        
        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
        );
    
        // Using the HSP value, determine whether the color is light or dark
        if (hsp>127.5) {
    
            return false;//light
        } 
        else {
    
            return true;//dark
        }
  
    };

    const check=checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)
    

    const styleTextField={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }

    const styleSelect={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
    
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: '100%',
            border: '1px solid #ababab',
            borderRadius: 5,
            padding: 0,
            display: 'flex'
        }),
        container: () => ({
    
            width: '100%',
            position: 'relative',
            boxSizing: 'border-box',
            backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)'
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition };
        }
    }


    useEffect(() => {
        
        
        if(loading)
        getResources()

        
        return () => {

        };
    }, [loading,props.showSurveyForm]);


    return (
        loading ? <></> :
        <DialogForm
           open={props.showSurveyForm}
           close={props.setshowSurveyForm}
           CustomeWidth="50%"
           CustomeHeight="70%"
        >

            {object &&  object.id>0?
            <h4 style={{display: 'flex', justifyContent:'start'}}>{t('SURVEY.FORMUPDATE_TITLE')}</h4>:
            <h4 style={{display: 'flex', justifyContent:'start'}}>{t('SURVEY.FORMADD_TITLE')}</h4>}
            <form >
                <div className="form-group">
                    <label htmlFor="name" style={{display:'flex',justifyContent:'start'}} >{t('SURVEY.FORMADD_NAME')}</label>
                    <input className="form-control" 
                        id="name"
                        value={object.name}
                        onChange={event => { setobject({ ...object, name: event.target.value }); }}
                        style={{...styleTextField}}
                     />
                </div>
                
               
                
                <div className="form-group" >
                    <AutoComplete isFullWidth={true} styleAutoComplete={styleTextField} selectedPoint={selectedPoint} setselectedPoint={setselectedObject} />
                </div>
                <div className="form-group">
                    <label htmlFor="point_type"  style={{display:'flex',justifyContent:'start'}}>{t('SURVEY.FORMADD_POINT_STATUS')}</label>
                    <Select  onChange={e => {console.log(e); setobject({ ...object, point_type:{
                        name:e.label,
                        id:e.value
                    }, point_type_id: e.value }); }}
                        defaultValue={{value:object.point_type?.id,label:object.point_type?.name}}
                        value={{value:object.point_type?.id,label:object.point_type?.name}}
                        styles={customStyles}
                        options={pointTypes}
                    />
                </div>
                
                
                
            </form>
                   
          
            <FooterBottons close={props.setshowSurveyForm} submitForm={submitForm} />

        </DialogForm>

    );
};


export default SurveyForm;
