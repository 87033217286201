import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { AiFillCloseCircle } from 'react-icons/ai'
import { Divider, Switch, CardMedia, Box, DialogTitle, IconButton, TextField, Typography, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Button } from '@mui/material';
import GoogleMap from "../GoogleMap/GoogleMap";
import {useSelector,useDispatch} from  'react-redux'
import DialogForm from "../Share/DialogForm";
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const RegionForm = (props) => {
    const [loading, setloading] = useState(true);
    const [districts, setdistricts] = useState([]);
    const directions =useSelector(state=>state.settingsData.directions);
    const MainTheme =useSelector(state=>state.settingsData.MainTheme);
    const [t, i18n] = useTranslation('common');

    const [object, setobject] = useState({
        id: null,
        name: null,

        district: null,
        district: {
            id: null,
            name: null
        },
        region_geofences: []
    });
    const getResources = async () => {
        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "districts_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setdistricts(resources);
                    if (props && props.region && props.region.id > 0) {
                        setobject({ ...object, ...props.region });
                    }
                    else {

                        setobject(
                            {
                                id: null,
                                name: null,

                                district: null,
                                district: {
                                    id: null,
                                    name: null
                                },
                                region_geofences: []
                            }
                        )
                    }
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {

        try {


            if (object.id) {

                const resourcesResult = await axios({
                    url: Host + "regions/" + object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowRegionForm(false, true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowRegionForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowRegionForm(true, false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "regions",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowRegionForm(false, true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowRegionForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowRegionForm(true, false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowRegionForm(true, false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {

                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {

                    toast.error(resourcesResult.data.data)
                }


            }
            else if (resourcesResult && resourcesResult.status === 401) {

                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {

                toast.error("network error")
            }
        }

    };

    const checkDarknessOrLightnessForColor=(color)=>{
        // Variables for red, green, blue values
        var r, g, b, hsp;
      
        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
    
            // If RGB --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            
            r = color[1];
            g = color[2];
            b = color[3];
        } 
        else {
            
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace( 
            color.length < 5 && /./g, '$&$&'));
    
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }
        
        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
        );
    
        // Using the HSP value, determine whether the color is light or dark
        if (hsp>127.5) {
    
            return false;//light
        } 
        else {
    
            return true;//dark
        }
  
    };

    const check=checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)
    

    const styleTextField={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }

    const styleSelect={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }


    useEffect(() => {


        if (loading)
            getResources()


        return () => {

        };
    }, [loading, props.showRegionForm]);


    return (
        <DialogForm
          open={props.showRegionForm}
          close={props.setshowRegionForm}
        >
 
            <DialogTitle

            >
                <IconButton aria-label={"Close"} onClick={() => { props.setshowRegionForm(false) }} style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '50px', }}>
                    <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor }} />

                </IconButton>
                <Typography component={'h1'} variant={'h4'} style={{ color: MainTheme?.paperTextColor, width: '100%', textAlign: 'center' }}>
                    {props?.title ? props?.title : (object?.id?t('REGION.FORMUPDATE_TITLE'):t('REGION.FORMADD_TITLE'))}
                </Typography>
            </DialogTitle>

            <DialogContent dividers={false} sx={{
                padding: 0,
                overflowX: 'hidden',
                backgroundColor: 'transparent',
                height: '100%',


            }}>

                {loading ? <>Loading...</> :

                    <Box style={{
                        padding: '10px',
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        color:MainTheme?.paperTextColor

                    }}>
                        <Box style={{

                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',

                            width: '100%',

                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start'

                        }}>
                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flex: 1

                            }}>
                                <TextField
                                    id="outlined-basic-name"
                                     label={t('REGION.FORM_NAME')}
                                      variant="outlined"
                                    placeholder={t('REGION.FORM_NAME')}
                                    sx={{...styleSelect}}
                                    value={object?.name}
                                    fullWidth
                                    onChange={(event) => {
                                        //console.log("name", event);
                                        setobject({ ...object, name: event.target.value });
                                    }}
                                />
                            </Box>
                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                marginLeft: '10px',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flex: 1

                            }}>
                                <FormControl fullWidth sx={{...styleSelect}}>
                                    <InputLabel id="district-select-label" sx={{color:MainTheme?.paperTextColor}}>{t('REGION.FORM_DISTRICT')}</InputLabel>
                                    <Select
                                        labelId="district-select-label"
                                        id="district-select"
                                        sx={{textAlign:'left'}}
                                        value={object?.district}
                                        label={t('REGION.FORM_DISTRICT')}
                                        renderValue={(value) => {
                                            //console.log("value", value);
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.name}`
                                            }
                                            else {

                                                return <Typography sx={{ color:MainTheme?.paperTextColor }}>{t('REGION.FORM_SELECT_DISTRICT')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, district: {
                                                    ...event?.target?.value
                                                }, district_id: event.target.value?.id
                                            });
                                        }}
                                    >
                                        {districts?.length > 0 && districts?.map((district) => {

                                            return <MenuItem key={district?.id} sx={{color:MainTheme?.paperTextColor}} value={district}>{district?.name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>


                            </Box>
                        </Box>
                        <Divider sx={{ marginTop: '10px', width: '100%' }} />
                        <Box style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}>

                            <Typography component={'h1'} variant={'h6'} sx={{ color: MainTheme?.paperTextColor }}>
                            {t('REGION.FORM_MESSAGE')}
                            </Typography>

                            <Typography component={'p'} variant={'p'} sx={{ color: MainTheme?.paperTextColor }}>
                            {t('REGION.FORM_SUB_MESSAGE')}
                            </Typography>

                        </Box>
                        <Divider sx={{ marginBottom: '10px', width: '100%' }} />
                        <Box style={{

                            padding: '10px',
                            paddingTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '400px',
                            width: '100%',
                            justifyContent: 'center',

                            alignItems: 'center'

                        }}>

                            <GoogleMap
                                createPoly={true}
                                deletable={true}
                                markers={object?.region_geofences && object?.region_geofences?.length > 0 ? object?.region_geofences : []}
                                polygon={

                                    object?.region_geofences && object?.region_geofences?.length > 0 ?
                                        {
                                            paths: object?.region_geofences?.map((geoFence) => {
                                                return {
                                                    lat: geoFence?.latitude,
                                                    lng: geoFence?.longitude,
                                                    id: geoFence?.id

                                                }
                                            }),
                                            strokeColor: "#FF0000",
                                            fillColor: "#FF0000"

                                        } : null}
                                draggable={true}

                                setMarkers={(markers) => {
                                    setobject({
                                        ...object,
                                        region_geofences: markers
                                    });
                                }}
                                onClick={(value) => {
                                    let id = object?.region_geofences?.length > 0 ? Math.max(...object?.region_geofences?.map(geoFence => geoFence?.id)) + 1 : 1
                                    setobject({
                                        ...object,
                                        region_geofences: [...object?.region_geofences, {
                                            id: id,
                                            latitude: value?.lat,
                                            longitude: value.lng,
                                            label: 'g' + id
                                        }]
                                    });
                                }} />
                        </Box>
                        <Box style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}>
                            <Button onClick={() => {
                                submitForm()
                            }}>{t('REGION.FORM_SUBMIT_FORM')}</Button>
                        </Box>

                    </Box>
                }




            </DialogContent>

        </DialogForm>
    );
};

export default RegionForm;
