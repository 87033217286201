
import React, { useEffect, useState } from 'react';
// import Pagination from '@mui/material/Pagination';

import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import UserForm from './UserForm';
import UserRoleForm from './UserRoleForm';

import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@mui/material/Tooltip';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import { useSelector } from 'react-redux'
import red from '@material-ui/core/colors/red';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import * as GrIcone from 'react-icons/gr'
import { useTranslation } from "react-i18next";


import Grid from '../Share/Grid'

const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
let roles = []

try {

    roles = cookies.get("roles")
    roles = roles?.map(role => role?.id)
} catch (error) {


}
function Users() {
    const { t, i18n } = useTranslation('common');
    const directions = useSelector(state => state.settingsData.directions)
    const GridTheme = useSelector(state => state.settingsData.GridTheme)

    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        users: ''
    });

    const Language = React.useMemo(() => {
        if (directions !== 'rtl') {
            return require('../../Settings/Translate_en.json').USERS
        } else {
            return require('../../Settings/Translate_ar.json').USERS
        }
    }, [directions])


    const [userTotals, setUserTotals] = useState(0)
    const [userNumberOfPage, setUserNumberOfPage] = useState(0)

    const [showUserForm, setshowUserForm] = useState(false);
    const [showUserRoleForm, setshowUserRoleForm] = useState(false);
    const [selectedUser, setselectedUser] = useState(null);

    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [rows, setRow] = useState([])

    const columns = [
        {
            field: 'id', headerName: t("USERS.ID"), flex: .1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.id}
                </div>
            )
        },
        {
            field: 'full_name', headerName: t("USERS.FULL_NAME"), flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows.row.full_name}
                </div>
            )
        },
        {
            field: 'username', headerName: t('USERS.USER_NAME'), flex: 1.2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows.row.username}
                </div>
            )
        },
        {
            field: 'phone', headerName: t("USERS.PHONE"), flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows.row.phone}
                </div>
            )
        },
        {
            field: 'company', headerName: Language?.COMPANY, flex: 1.1, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.company?.company_name}
                </div>
            )
        },
        {
            field: 'departmentName', headerName: Language?.DEPARTMENT, flex: 1.1, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.department?.name}
                </div>
            )
        },
        {
            field: 'active', headerName: Language?.ACTIVE, flex: 1, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.active == 1 ? Language?.ACTIVE : Language?.INACTIVE}
                </div>
            )
        },
        {
            field: 'is_demo', headerName: Language?.IS_DEMO, flex: 1, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.is_demo == 1 ? Language?.YES : Language?.NO}
                </div>
            )
        },
        {
            field: 'departmentName', headerName: t("USERS.DEPARTMENT"), flex: 1.1, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.department?.name}
                </div>
            )
        },
        {
            field: 'active', headerName: t("USERS.ACTIVE"), flex: 1, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.active == 1 ? 'active' : 'disabled'}
                </div>
            )
        },



        {
            field: 'actions', flex: 3,
            headerName: t("USERS.ACTIONS"),
            renderCell: (rows) => (
                <strong className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    <IconButton
                        size="small"
                        style={{ marginLeft: 0 }}
                        onClick={() => { updateObject(rows?.row) }}
                        className="btnAction"
                        sx={{ outline: 'none', border: 'none' }}
                    >
                        <Tooltip title={directions != 'rtl' ? "edit" : 'تعديل'} >
                            <EditIcon style={{ fill: "#111827" }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="edit"
                        size="small"
                        onClick={() => { showRoles(rows?.row) }}
                        className="btnAction"
                        sx={{ outline: 'none', border: 'none', fill: GridTheme?.primaryColor }}
                    >
                        <Tooltip title={directions != 'rtl' ? "show roles" : 'عرض الصلاحيات'}>
                            <GrIcone.GrUserAdmin style={{ fill: GridTheme?.primaryColor, color: GridTheme?.primaryColor, }} />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 20 }}
                        aria-label="delete"
                        size="small"
                        onClick={() => { deleteObject(rows?.row) }}
                        className="btnAction"
                        sx={{ outline: 'none', border: 'none' }}
                    >
                        <Tooltip title={directions != 'rtl' ? "delete" : 'حذف'}>
                            <DeleteIcon sx={{ color: red[500] }} />
                        </Tooltip>
                    </IconButton>

                    {roles?.includes(parseInt(process.env.REACT_APP_ACTIVATE_USER)) &&
                        <IconButton
                            style={{ marginLeft: 20 }}
                            aria-label="Activate"
                            size="small"
                            onClick={() => { activateObject(rows?.row) }}
                            className="btnAction"
                            sx={{ outline: 'none', border: 'none' }}
                        >
                            <Tooltip title={directions != 'rtl' ? "active or disabled" : 'نشط او غير نشط'}>
                                {
                                    rows?.row?.active == 1 ?
                                        <CheckCircleIcon sx={{ color: '#111827' }} size={24} />
                                        :
                                        <UnpublishedIcon sx={{ color: red[500] }} size={24} />

                                }
                            </Tooltip>
                        </IconButton>
                    }
                    {roles?.includes(parseInt(process.env.REACT_APP_ACTIVATE_DEMO_USER)) &&
                        <IconButton
                            style={{ marginLeft: 20 }}
                            aria-label="Change Demo status"
                            size="small"
                            onClick={() => { activateDemoObject(rows?.row) }}
                            className="btnAction"
                            sx={{ outline: 'none', border: 'none' }}
                        >
                            <Tooltip title={directions != 'rtl' ? "active or demo" : 'فعال او تجريبي'}>
                                {
                                    rows?.row?.is_demo == 1 ?
                                        <CheckCircleIcon sx={{ color: '#111827' }} size={24} />
                                        :
                                        <UnpublishedIcon sx={{ color: red[500] }} size={24} />
                                }
                            </Tooltip>
                        </IconButton>
                    }


                </strong>
            ),
        },
    ]

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const fetchData = async (params) => {
        setloading(true)
        try {
            const result = await axios({
                url: Host + "users",
                params: {
                    page: pageNumber,
                    page_size: pageSize,
                    ...params
                },
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params: {
                    page_size: pageSize
                }


            })
            if (result && result.status == 200) {
                const users = result.data;
                setData({
                    users: users
                });



            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)

    };
    const updateObject = async (object) => {

        setselectedUser(object)
        setshowUserForm(true)
    };
    const showRoles = (object) => {
        setselectedUser(object)
        setshowUserRoleForm(true)
    };

    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result = await axios({
                url: Host + "users/" + object.id,
                method: "delete",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }

            })
            if (result && result.status == 200) {
                toast.error(result.data.data)
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    const activateObject = async (object) => {
        setloading(true)
        try {
            const result = await axios({
                url: Host + "users/activate",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    user_id: object.id,
                    active: object.active == 1 ? 0 : 1
                }

            })
            if (result && result.status == 200) {
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    const activateDemoObject = async (object) => {
        setloading(true)
        try {
            const result = await axios({
                url: Host + "users/activate_demo",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    user_id: object.id,
                    is_demo: object.is_demo == 1 ? 0 : 1
                }

            })
            if (result && result.status == 200) {
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    const createObject = async (object) => {
        setselectedUser(null)
        setshowUserForm(true)
    };

    const handleSetshowUserForm = (openStatus, needsReload) => {
        if (needsReload)
            fetchData();
        setshowUserForm(openStatus)
    };

    const handleSetshowUserRoleForm = (openStatus, needsReload) => {
        if (needsReload)
            fetchData();
        setshowUserRoleForm(openStatus)
    };


    useEffect(() => {
        fetchData();
    }, [pageNumber, pageSize])

    useEffect(() => {
        if (state && state.users && state.users && state.users?.data?.length > 0) {
            setRow(state?.users?.data);
            setUserTotals(state?.users?.total)
            setUserNumberOfPage(state?.users?.last_page)
        }
    }, [state.users])

    return (
        <>
            {showUserForm ?
                <UserForm user={selectedUser} showUserForm={showUserForm} setShowUserForm={handleSetshowUserForm} />
                : null}
            {showUserRoleForm ?
                <UserRoleForm user={selectedUser} showUserRoleForm={showUserRoleForm} setshowUserRoleForm={handleSetshowUserRoleForm} />
                : null}


            <Grid
                rows={rows}
                columns={columns}
                rowTotals={userTotals}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                setPageNumber={setpageNumber}
                loading={loading}
                handlePageChange={handlePageChange}
                NumberOfPage={userNumberOfPage}
                directions={directions}
                hasCreate={true}
                createObject={createObject}
                title={t('USERS.HEADER_TITLE')}
                //   title_ar="قائمة المستخدمين"
                hasFooter={false}
                gridHeight={{
                    height: rows?.length > 5 ? pageSize == 5 ? 400 : pageSize == 10 ? 650 : 950 : 400,
                }}
            //   paginationType="client"

            />
        </>
    );
}

export default Users;


