import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux'
import {
  setSideMenuMode,
  sideMenuMode,
  setDirections,
  setMainPaletteShow,
  setSideMenuPaletteShow,
  setToolBarPaletteShow,
  setGridPaletteShow

} from '../../reduxStore/SettingsReducer'
import './Settings.css'
import CloseIcon from '@mui/icons-material/Close';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import {
  SIdeMenuLaout,
  ThemeLayout,
  SettingHeader,
  SettingsCloseIcons
} from './StyledComponent'
import {useTranslation} from "react-i18next";

import SideMenuPalette from '../../../assets/img/SideMenu-Palette.PNG'
import ToolbarPalette from '../../../assets/img/Toolbar-Palette.PNG'
import MainPalette from '../../../assets/img/Main-Palette.PNG'
import GirdPalette from '../../../assets/img/grid-Palette.PNG'
import swal from 'sweetalert';
// import {useTranslation} from "react-i18next";

import ThemePalette from '../Share/ThemePalette'


const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: pink[600],
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: pink[600],
    },
}));
  
const label = { inputProps: { 'aria-label': 'Switch demo' } };

  
export default function SideSettings({setOpenSideSettings,openSideSettings}) {
    const {t, i18n} = useTranslation('common');

    const dispatch = useDispatch()
    const sideMenuMode = useSelector(state=> state.settingsData.sideMenuMode)
    const directions = useSelector(state=> state.settingsData.directions)
    const MainTheme=useSelector(state=> state.settingsData.MainTheme);
    const [settingsSideDirections,setSettingsSideDirections]=useState('right')

    useEffect(()=>{
      console.log('direction=>',directions)
      if(directions=='rtl'){
        setSettingsSideDirections('left')
      }else{
        setSettingsSideDirections('right')
      }
    },[directions])

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    
    const openMainPalette=()=>{
       dispatch(setMainPaletteShow(true))
        
    }

    const openSideMenuPalette=()=>{
      dispatch(setSideMenuPaletteShow(true))
    }

    const openToolbarPalette=()=>{
      dispatch(setToolBarPaletteShow(true))
    }

    const openGridPalette=()=>{
      dispatch(setGridPaletteShow(true))
    }
 
    const list = (anchor) => (
      <Box
        sx={{ backgroundColor:MainTheme?.paperColor,color:MainTheme?.paperTextColor, width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 380 }}
        className='SideSettings'
        role="presentation"
      //   onClick={()=>setOpenSideSettings(false)}
      //   onKeyDown={()=>setOpenSideSettings(false)}
      >
        {/* header of sideMenu settings  */}
        <SettingHeader>{t('SETTINGS.TITLE')}</SettingHeader>

        {/* first layout of sideMenu ...  */}
        <SIdeMenuLaout color={MainTheme?.paperTextColor} secondaryColor={MainTheme?.secondaryColor} bgColor={MainTheme?.paperColor} titleSection={t('SETTINGS.SIDEMENU_LAYOUT')} leftProps={directions !='rtl' ?3:50}>
          <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent',paddingTop:2,paddingBottom:2,color:MainTheme?.paperTextColor }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              
          >

              <ListItemButton className="listItem">
                  <ListItemText primary={t('SETTINGS.SIDEMENU_ICONMODE')} className="ColorText" />
                  
                  <FormControlLabel
                      control={
                          <Switch {...label}  color="warning" checked={sideMenuMode} className="switchColor" onChange={(e)=>{
                              dispatch(setSideMenuMode(e.target.checked))
                              if(e.target.checked)
                                 i18n.changeLanguage('ar')
                               else
                                 i18n.changeLanguage('en')
                               

                              // console.log(e.target.checked)
                          }} />
                      }
                      label="Icon"
                      sx={{position:'relative',top:4}}
                      className='ColorText'
                  />
              </ListItemButton>

              <ListItemButton className="listItem">
                  <ListItemText primary={t('SETTINGS.SIDEMENU_DIRECTION')} className="ColorText textSideMenuSetting" />
                  
                  <FormControlLabel
                      control={
                          <Switch {...label}  color="warning" checked={directions=='rtl'?true:false} className="switchColor" onChange={(e)=>{
                              dispatch(setDirections(e.target.checked?'rtl':'ltr'))
                              console.log(e.target.checked)
                              // if(e.target.checked)
                              //   document.dir = 'rtl';
                              // else 
                              //    document.dir = 'ltr';
                              
                            }} />
                      }
                      label="RTL"
                      sx={{position:'relative',top:4}}
                      className='ColorText'
                  />
              </ListItemButton>

          </List>
        </SIdeMenuLaout>

        {/* first layout of sideMenu ...  */}
        <ThemeLayout color={MainTheme?.paperTextColor} bgColor={MainTheme?.paperColor} titleSection={t('SETTINGS.THEME_LAYOUT')} leftProps={directions !='rtl' ?3:63}>
          <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent',paddingTop:2,paddingBottom:2,color:'black' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              className="ListOfThemes"
          >
              <ListItemButton className="listItemImgContainer" disableRipple onClick={()=>openMainPalette()}>
                    <img src={MainPalette} alt="" className="ListItemImage" />
                    <p>{t('SETTINGS.THEME_LAYOUT_MAIN_PALETTE')}</p>
              </ListItemButton>

              <ListItemButton className="listItemImgContainer" disableRipple onClick={()=>openToolbarPalette()}>
                    <img src={ToolbarPalette} alt="" className="ListItemImage" />
                    <p>{t('SETTINGS.THEME_LAYOUT_TOOLBAR_PALETTE')}</p>
              </ListItemButton>

              <ListItemButton className="listItemImgContainer" disableRipple onClick={()=>openSideMenuPalette()}>
                    <img src={SideMenuPalette} alt="" className="ListItemImage" />
                    <p>{t('SETTINGS.THEME_LAYOUT_SIDEMENU_PALETTE')}</p>
              </ListItemButton>

              <ListItemButton className="listItemImgContainer" disableRipple onClick={()=>openGridPalette()}>
                    <img src={GirdPalette} alt="" className="ListItemImage" />
                    <p>{t('SETTINGS.THEME_LAYOUT_GRID_PALETTE')}</p>
              </ListItemButton>


          </List>
        </ThemeLayout>





      </Box>
    );

  return (
    <div className='SideSettings2'>
        <SwipeableDrawer
          anchor={'right'}
          open={openSideSettings}
          onClose={()=>setOpenSideSettings(false)}
          // SlideProps={{
          //   direction: directions=='rtl'?'left':'left'
          // }}
        >
          <SettingsCloseIcons color={MainTheme?.paperTextColor} onClick={()=>setOpenSideSettings(false)}>
              <CloseIcon  className="icon"/>
          </SettingsCloseIcons>
          {list(settingsSideDirections)}
        </SwipeableDrawer>
        <ThemePalette/>
    </div>
  )
}
