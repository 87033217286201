import React, { useState, useEffect } from 'react'

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LanguageIcon from '@mui/icons-material/Language';
import { useSelector, useDispatch } from 'react-redux'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import './Notifications.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import Iraq from '../../../assets/img/iraq.png'
import UnitedState from '../../../assets/img/united-states.png'


import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";

import { setSideMenuMode, sideMenuMode, setDirections } from '../../reduxStore/SettingsReducer'
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
const cookies = new Cookies();
const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme, ToolBarTheme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: ToolBarTheme?.paperColor,
    color: ToolBarTheme?.disabledGlobalText ? ToolBarTheme?.gloablTextColor : ToolBarTheme?.paperTextColor,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      '& .activelanguage': {
        backgroundColor: `${ToolBarTheme?.paperTextColor + '55'} !important`,
      },

    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },


    },
    '& .itmlist:hover': {
      backgroundColor: ToolBarTheme?.paperTextColor + '55',
    }

  },
}));

export default function Company({ ToolBarTheme }) {
  const directions__ = useSelector(state => state.settingsData.directions)
  const dispatch = useDispatch();
  const [directions, setDirectionNow] = useState(directions__)
  const [t, i18n] = useTranslation('common');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY'),
  })


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (company) => {
    // try {
      setLoading(true)
      var resourcesResult = await axios({
        url: Host + "users/update_logged_in_company",
        method: "patch",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        },
        data: {
          updated_logged_in_company_id: company?.id
        }
      })
      if (resourcesResult) {
        if (resourcesResult.status === 200) {
          const resources = resourcesResult.data;

          let selectedCompanytemp = {
            id: "",
            name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
          }
          try {
            const filteredCompanies = companies?.filter((item) => item?.id === resources?.logged_in_company_id)
            if (filteredCompanies?.length > 0) {
              selectedCompanytemp = filteredCompanies[0]
            }
          } catch (error) {
            selectedCompanytemp = {
              id: "",
              name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
            }

          }
          setSelectedCompany({
            id: selectedCompanytemp?.id,
            name: selectedCompanytemp?.name
          })
          cookies.set("logged_in_company_id",selectedCompanytemp?.id)

          window?.location?.reload()


        } else if (resourcesResult.data.status === false) {
          setSelectedCompany({
            id: "",
            name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
          })
        }
      }
      else {
        setSelectedCompany({
          id: "",
          name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
        })
        toast.error("Error")
      }


      setLoading(false)
    // } catch (error) {
    //   console.log("error",error);
    //   setLoading(false)
    //   setSelectedCompany({
    //     id: "",
    //     name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
    //   })
    //   toast.error("Network Error")
    // }
    setAnchorEl(null);
  };

  const handleCloseTwo = () => {
    setAnchorEl(null);

  }

  useEffect(() => {
    setDirectionNow(directions__)
  }, [directions__])

  useEffect(() => {
    getResources()
    return () => {

    }
  }, [])

  const getResources = async () => {
    try {
      setLoading(true)
      var resourcesResult = await axios({
        url: Host + "allow_access_companies_ids",
        method: "get",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        }
      })
      if (resourcesResult) {
        if (resourcesResult.status === 200) {
          const resources = resourcesResult.data;
          let selectedCompanytemp = {
            id: "",
            name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
          }
          try {
            
            const filteredCompanies = resources?.filter((item) =>{
              if(item?.id == cookies.get("logged_in_company_id"))
              {
                return true
              }
              return false
            })
            if (filteredCompanies?.length > 0) {
              
              selectedCompanytemp = filteredCompanies[0]
            }
          } catch (error) {
            selectedCompanytemp = {
              id: "",
              name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
            }
          }
          setSelectedCompany({
            id: selectedCompanytemp?.id,
            name: selectedCompanytemp?.name
          })
          setCompanies(resources);

        } else if (resourcesResult.data.status === false) {
          setCompanies([]);
        }
      }
      else {
        setCompanies([]);
        toast.error("Error")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setCompanies([]);
      toast.error("Network Error")
    }
  };

  return (
    <div>

      <Tooltip id="demo-customized-button" onClick={() => handleClick()} arrow title={t('APPBAR.TOOLTIP_SELECT_A_COMPANY')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom">
        <IconButton className='Notification'
          size="small"
          sx={{ borderRadius: 2, height: '40px' }}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}>

              <p style={{
                whiteSpace: 'nowrap',
                fontSize: '14px !important',
                textAlign: 'center',
                marginTop: '15px',
                marginRight: directions == 'rtl' ? 5 : 0
              }}>
                {selectedCompany?.name}
              </p>
            </Box>
          </Box>


        </IconButton>
      </Tooltip>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        ToolBarTheme={ToolBarTheme}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseTwo}
      >
        {companies?.map((company, index) => {
          return (
            <MenuItem key={"company_" + company?.id + "_" + index} onClick={() => handleClose(company)} disableRipple className={`itmlist ${company?.id === selectedCompany?.id ? 'activelanguage ' : 'languageNormal arabicDirection'}`}>
              {company?.name}
            </MenuItem>
          )
        })}

      </StyledMenu>
    </div>
  )
}
