import React from 'react'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { AiFillCloseCircle } from 'react-icons/ai'
import { Box, IconButton, Typography } from '@mui/material';
import DialogForm from '../Share/DialogForm';
import GoogleMap from '../GoogleMap/GoogleMap';
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";


export default function PointsMapDialog(props) {
  const descriptionElementRef = React.useRef(null);
  const directions= useSelector(state=> state.settingsData.directions)
  const MainTheme= useSelector(state=> state.settingsData.MainTheme)
  const [t, i18n] = useTranslation('common');

  return (
    <DialogForm
      open={props.showPointsMapDialog}
      close={props.setshowPointsMapDialog}
    >


        <DialogContentText
          id="scroll-dialog-description"
          sx={{
            height: '100%',
            direction: 'ltr',
          }}
          ref={descriptionElementRef}
          tabIndex={-1}
        >

          <IconButton aria-label={"Close"} onClick={() => { props.setshowPointsMapDialog(false) }} style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '50px', }}>
            <AiFillCloseCircle style={{ color:MainTheme?.paperTextColor }} />

          </IconButton>
          <Box style={{
            padding: '10px',
            paddingTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'

          }}>
            <Typography component={'h1'} variant={'h4'} style={{ color: MainTheme?.paperTextColor, width: '100%', textAlign: 'center' }}>
              {props?.title}
            </Typography>
          

            {props.showPointsMapDialog &&
              <GoogleMap markers={props?.points} polygon={props?.polygon} />
            }
          

          </Box>




        </DialogContentText>

    </DialogForm>

  )
}
