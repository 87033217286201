import styled,{keyframes} from 'styled-components'


const ButtonPulse = keyframes`
    from {
        box-shadow: 0 0 0 0 var(--color-green-light);
    }
    to {
        box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
    }
`;

const FadeUpOut = keyframes`
    to {
        opacity: 0;
        transform: translateY(-40%);
      }
`;

const FadeUpIn = keyframes`
     to {
        opacity: 1;
        transform: translateY(0);
      }
`;

const LoadRight= keyframes`
  0% {
    left: -100%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 100%;
  }
`


export const Wrapper=styled.div`
    text-align: center;
    & .upload-wrapper {
    display: inline-block;
    margin: 2rem auto 0;
    }
    & .drop-area {
     border: 3px solid var(--color-black-softest);
    }
    & .drop-area.droppable {
        border: 10px dashed rgba(255, 255, 255, 0.6);
    }
    & .drop-area.highlight {
     border: 3px dashed var(--color-green);
    }

`
export const Upload =styled.div`
  --color-black-softest: #485461;
  --color-black-soft: #363F48;
  --color-black-hard: #2d353c;
  --color-black-hardest: #21282e;
  --color-green-light: #65cca9;
  --color-green: #29b586;
  --ease-in-out-quartic: cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  display: inline-flex;
  background: ${props=>props.color};
  border-radius: 10px;
  box-shadow: 0 1.7px 1.4px rgba(0, 0, 0, 0.02), 0 4px 3.3px rgba(0, 0, 0, 0.028), 0 7.5px 6.3px rgba(0, 0, 0, 0.035), 0 13.4px 11.2px rgba(0, 0, 0, 0.042), 0 25.1px 20.9px rgba(0, 0, 0, 0.05), 0 60px 50px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  transform: rotate(0);

  & .upload-info {
  display: flex;
  align-items: center;
  padding: 16px;
  margin-right: 40px;
  fill: #fff;
  color: #fff;
}
& .upload-filename {
  padding-left: 8px;
  transition: opacity 300ms ease;
  color:${props=>props.bgColor}55;
}
& .upload-filename.inactive {
  opacity: 0.6;
}
& .upload-button {
  position: relative;
  margin: 0;
  font-size: 100%;
  padding: 0 8px;
  font-family: inherit;
  background: none;
  border: none;
  border-radius: inherit;
  outline: none;
}
& .upload-button-text {
  padding: 8px 16px;
  color: ${props=>props.buttonTextColor};
  background-color: ${props=>props.buttonBgColor};
  border-radius: inherit;
  outline: none;
  cursor: pointer;
  transition: background-color 200ms ease, box-shadow 300ms ease;
}
& .upload-button-text:hover, .upload-button-text:focus {
  background-color: ${props=>props.buttonBgColor}77;
}
& .upload-button-text.inactive {
  background-color: ${props=>props.buttonBgColor}66;
  cursor: not-allowed;
}
& .upload-hint {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
  transform: translateY(100%);
}

& .upload-progress {
  position: absolute;
  top: 90%;
  left: -100%;
  width: 100%;
  height: 100%;
  color: white;
  background: linear-gradient(270deg, #87e0c2 0%, #65cca9 50%, #26b082 100%);
  transform-origin: left;
}
& .uploading .upload-button-text {
  animation: ${FadeUpOut} 0.4s 0.4s forwards, button-pulse 500ms forwards;
}
& .uploading .upload-info > * {
  animation: ${FadeUpOut} 0.4s 0.4s forwards;
}
& .uploading .upload-hint {
  animation: ${FadeUpIn} 0.4s 0.8s forwards;
}
& .uploading .upload-progress {
  animation: ${LoadRight} 2s 1s var(--ease-in-out-quartic) forwards;
  animation-iteration-count: infinite;
}


& [type=file] {
  height: 0;
  overflow: hidden;
  width: 0;
  cursor: pointer;
}


`



// [type=file] {
//   height: 0;
//   overflow: hidden;
//   width: 0;
//   cursor: pointer;
// }

// .drop-area {
//   border: 1px solid var(--color-black-softest);
// }
// .drop-area.droppable {
//   border: 1px dashed rgba(255, 255, 255, 0.6);
// }
// .drop-area.highlight {
//   border: 1px dashed var(--color-green);
// }

// /* ------------------------------
// Styling for the rest of the page
// ------------------------------ */
// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   font-family: Roboto, sans-serif;
//   background: #2d353c;
//   color: #ccc;
// }

// .wrapper {
//   text-align: center;
// }
// .wrapper .upload-wrapper {
//   display: inline-block;
//   margin: 2rem auto 0;
// }

// h1, h2 {
//   color: #fff;
//   text-align: center;
//   letter-spacing: 0.02em;
//   margin-bottom: 0.3em;
// }