
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import RegionForm from './RegionForm';
import RegionImportForm from './RegionImportForm';
import IconButton from "@material-ui/core/IconButton";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import {useSelector,useDispatch} from 'react-redux'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";

import Autocomplete from '@mui/material/Autocomplete';
import red from '@material-ui/core/colors/red';
import Pagination from '../Share/Pagination'
import { useGlobalContext } from '../../../context'
import PointsMapDialog from '../Points/PointsMapDialog';
import Grid from '../Share/Grid'

function Regions() {
    const directions = useSelector(state => state.settingsData.directions)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)
    const FilterButtonHeight= useSelector(state=> state.settingsData.FilterButtonHeight);
    const [t, i18n] = useTranslation('common');
    
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const { loading, data, pointsStatuses, pointStatus, setPointStatus, exportToCSV, allPoints, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()
    const [rows, setRow] = useState([])
   
    const [showRegionForm, setshowRegionForm] = useState(false);
    const [showRegionImportForm, setshowRegionImportForm] = useState(false);
    const [showRegionMapDialog, setshowRegionMapDialog] = useState(false)
    const [selectedRegion, setselectedRegion] = useState(null);
    const [accExpansion, setAccExpansion] = useState(false)
    const [polygon, setPolygon] = useState({
        paths: [],
        strokeColor: "#FF0000",
        fillColor: "#FF0000"
    })
    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const handleExpansion = (expansion) => setAccExpansion(expansion)

    const advanceSearch = async (type = "BROWSING") => {
        const fetchedData = await fetchData('DATA', 'regions', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            district: district.id,
            region: region.id,
        });
        return fetchedData
    }
    const updateObject = async (object) => {
        setselectedRegion(object)
        setshowRegionForm(true)
    };

    const createObject = async (object) => {
        setselectedRegion(null)
        setshowRegionForm(true)
    };

    const importObjects = async () => {
        setselectedRegion(null)
        setshowRegionImportForm(true)
    }
    const handleSetshowRegionForm = (openStatus, needsReload) => {
        if (needsReload)
            //   fetchData();
            advanceSearch()
        setshowRegionForm(openStatus)
    };
    const handleSetshowRegionImportForm = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch()
        setshowRegionImportForm(openStatus)
    };

    
    const mapObject = async (object) => {

        setPolygon({
            ...polygon,
            paths: object?.region_geofences?.map(geofence => {
                return{
                        lat: geofence.latitude,
                        lng: geofence.longitude
                    }
            })
        });
        setselectedRegion(object)
        setshowRegionMapDialog(true)
    }
    const handleSetshowRegionMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch()
        setshowRegionMapDialog(openStatus)
    }

    useEffect(() => {
        fetchData('REGIONS', 'regions_list')
        fetchData('DISTRICTS', 'districts_list')
        advanceSearch()
    }, [pageSize, pageNumber])



    useEffect(() => {

        if (data && data.data && data.data.length > 0) {
            setRow(data.data);
            setPointsTotal(data.total)
            setPointsNumberOfPages(data.last_page)
        }
    }, [data])

    const columns = [
        { field: 'id', headerName: t('REGION.ID'), flex: .1,
        renderCell:(rows)=>(
            <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                {rows.row.id}
            </div>
        )
    },
        {
            field: 'district', headerName: t('REGION.DISTRICT'), flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.district.name}
                </div>
            )
        },

        { field: 'name', headerName: t('REGION.REGION'), flex: 3 ,renderCell:(rows)=>(
            <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                 {rows.row.name}
             </div>
        )},
        {
            field: 'actions', flex: 2,
            headerName: t('REGION.ACTIONS'),
            renderCell: (rows) => (
                <strong className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    <IconButton
                        size="small"
                        style={{ marginLeft: 0 }}
                        onClick={() => {
                            mapObject(rows.row)
                        }}>
                        <Tooltip title="Locate">
                            <LocationOnRoundedIcon style={{ fill: "#5cb85c" }} />
                        </Tooltip>
                    </IconButton>


                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="edit"
                        size="small"
                        onClick={() => {
                            updateObject(rows.row)
                        }}
                    >
                        <Tooltip title="Edit">
                            <ModeEditOutlineRoundedIcon style={{ fill: "#111827" }}  />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        style={{ marginLeft: 20 }}
                        aria-label="delete"
                        size="small"
                        onClick={async () => {
                            if ((await deleteObject('DATA', 'regions', rows.row)) === 200) {
                                advanceSearch("BROWSING")
                            }
                        }}
                    >
                        <Tooltip title="Delete">
                            <DeleteRoundedIcon sx={{ color: red[500] }} />
                        </Tooltip>
                    </IconButton>
                </strong>
            ),
        },
    ]

    return (
        <>
            {showRegionForm ?
                <RegionForm region={selectedRegion} showRegionForm={showRegionForm} setshowRegionForm={handleSetshowRegionForm} />
                : null}
            {showRegionImportForm ?

                <RegionImportForm showRegionImportForm={showRegionImportForm} setshowRegionImportForm={handleSetshowRegionImportForm} />
                : null}
            {showRegionMapDialog ?

                <PointsMapDialog title={""+selectedRegion?.name}  polygon={polygon} showPointsMapDialog={showRegionMapDialog} setshowPointsMapDialog={handleSetshowRegionMapDialog} />
                : null}

            <Box display="flex" >
                <Button display="flex"
                    startIcon={<PublishRoundedIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
                    variant="contained"
                    sx={{height:FilterButtonHeight, marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor   }}
                    onClick={async () => { importObjects() }}>
                    {t('GLOBAL.SEARCH_IMPORT_BUTTON')}
                </Button>
                <Button display="flex"
                    startIcon={<FileDownloadIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
                    variant="contained"
                    sx={{height:FilterButtonHeight, marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor   }}
                    onClick={async () => {

                        const fetchedData = await fetchData('DATA', 'regions', {
                            page_size: pointsTotal,
                            district: district.id,
                            region: region.id,
                        });

                        exportToCSV(fetchedData.data, 'regions')
                    }
                    }>
                    {t('GLOBAL.SEARCH_EXPORT_BUTTON')}

                </Button>

                <Button display="flex"
                    startIcon={<AddCircleRoundedIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }} />}
                    variant="contained"
                    sx={{ height:FilterButtonHeight,marginRight: 1, marginBottom: accExpansion ? "0" : "20px" ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
                    onClick={() => { createObject() }}>
                    {t('GLOBAL.SEARCH_CREATE_BUTTON')}

                </Button>

                <Accordion display="flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor }}
                    onChange={(event, expansion) => handleExpansion(expansion)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }}>
                            <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                <Box display="flex" sx={{ flexGrow: 2 }}>
                                    <Autocomplete
                                        sx={{ ml: 1 }}
                                        fullWidth
                                        options={districts}
                                        getOptionLabel={(option) => option.name || ""}
                                        id="disable-close-on-select"
                                        value={district}
                                        onChange={(e, v, r) => { setDistrict(v ? v : '') }}
                                        renderInput={(params) => (
                                            <TextField {...params} 
                                            label={t('GLOBAL.SEARCH_DISTRICTS')}

                                             variant="outlined" />
                                        )}
                                    />
                                    <Autocomplete
                                        sx={{ ml: 1 }}
                                        fullWidth
                                        options={regions}
                                        getOptionLabel={(option) => option.name || ""}
                                        id="disable-close-on-select"
                                        value={region}
                                        onChange={(e, v, r) => { setRegion(v ? v : '') }}

                                        renderInput={(params) => (
                                            <TextField {...params}
                                             label={t('GLOBAL.SEARCH_REGION')}
                                            variant="outlined" />
                                        )}
                                    />
                                </Box>
                                <Box display="flex" sx={{ flexGrow: 1 }}>
                                    <Button
                                        variant="contained"
                                        spacing={2} sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, height: 55 ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            }}}
                                        onClick={advanceSearch} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="contained"
                                        spacing={2}
                                        sx={{ flexGrow: 1, margin: 0, height: 55,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
                                            '&:hover':{
                                                backgroundColor:GridTheme?.secondaryColor+'88',
                                            } }}
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={pointsTotal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={false}
              createObject={null}
              title_en=""
              title_ar=""
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />  

          
        </>
    );
}

export default Regions;


