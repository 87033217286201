import Host from './Host';

const helpers = {
    getImageUrl:function getImageUrl(image_url){
        var hostURLParts=Host.split("/")
        var hostURL=hostURLParts[0]+"//"+hostURLParts[2]+"/"
        const fullImageURL=hostURL+"api/public/"+image_url
       
      
        return fullImageURL;
    },
    stringToColour: function stringToColour(str){
        var colour = '#fff';
        if(str)
        {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            colour = '#';
            for (var i = 0; i < 3; i++) {
                var value = (hash >> (i * 8)) & 0xFF;
                colour += ('00' + value.toString(16)).substr(-2);
            }
        }
        
        return colour;
    },
    recursionCategory:function recursionCategory(data){
        var parent=[];
        for (let item = 0; item < data.length; item++) {
           
         
           
            var child= {
                name: data[item].name,
                attributes: {
                    id: data[item].id,
                    color:helpers.stringToColour(data[item].condition_value),
                    default_value: data[item].default_value,
                    is_mandatory: data[item].is_mandatory,
                    is_multiple_select: data[item].is_multiple_select,
                    has_condition:data[item].has_condition,
                    condition_value:data[item].condition_value,
                    field_type_name: data[item]["field_type"].name,
                    field_type_has_list: data[item]["field_type"].has_list,
                }
            }

            
            var children=data[item].children;
            var childrenMapped=null
            if(children!=null && children.length>0){
                childrenMapped = recursionCategory(children)
            }
            parent.push({
                ...child,
                children:childrenMapped
            })
        }
        return parent;
    },
    listToTree: function listToTree(items, id = null, link = 'survey_field_id'){
        return items
        .filter(item => item[link] === id)
        .map(item => ({ ...item, children: helpers.listToTree(items, item.id) }));
    },
    checkDarknessOrLightnessForColor: function checkDarknessOrLightnessForColor(color) {
        // Variables for red, green, blue values
        var r, g, b, hsp;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {

            // If RGB --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = color[1];
            g = color[2];
            b = color[3];
        }
        else {

            // If hex --> Convert it to RGB: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace(
                color.length < 5 && /./g, '$&$&'));

            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
            0.299 * (r * r) +
            0.587 * (g * g) +
            0.114 * (b * b)
        );

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {

            return false;//light
        }
        else {

            return true;//dark
        }

    }

};
export default helpers;
