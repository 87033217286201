import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
// import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';

export default function SearchField1(props) {
  return (
    <FormControl  variant="outlined" sx={{ml:1}} fullWidth>
        <InputLabel >{props.fieldLabel}</InputLabel>
            <OutlinedInput
                id="outlined-adornment-shopname"
                type={props?.type?props?.type:'text'}
                value={props.state}
                onChange={(e) => props.setState(e.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="clear"
                            onClick={() =>  props.setState('')}
                            edge="end"
                            >        
                            <ClearIcon />          
                        </IconButton>
                    </InputAdornment>
                }
                label="Shop Name"
        />
    </FormControl>
  )
}
