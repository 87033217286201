import { createSlice } from '@reduxjs/toolkit';


const initialState={
    sideMenuMode:false,
    directions:'ltr',
    notificationBox:false,
    SideMenuOpen:false,
    MainPaletteShow:false,
    SideMenuPaletteShow:false,
    ToolBarPaletteShow:false,
    GridPaletteShow:false,
    drawerWidth:310,
    FilterButtonHeight:59,
    currentSelectedSettingPages:1,
    MainTheme:{
      primaryColor:'#111827',
      oppositePrimaryColor:'#ffffff',
      secondaryColor:'#0000ff',
      oppositeSecondaryColor:'#ffffff',
      gloablTextColor:'#ffffff',
      paperColor:'#ffffff',
      paperTextColor:'#000000',
      bgColor:'#F1F5F9',
      bgTextColor:'#000000',
      light:true,
      dark:false,
      disabledGlobalText:false,
    },
    ToolbarTheme:{
      primaryColor:'#111827',
      oppositePrimaryColor:'#ffffff',
      secondaryColor:'#0000ff',
      oppositeSecondaryColor:'#ffffff',
      gloablTextColor:'#ffffff',
      paperColor:'#ffffff',
      paperTextColor:'#000000',
      bgColor:'#F1F5F9',
      bgTextColor:'#000000',
      light:true,
      dark:false,
      disabledGlobalText:false,

    },
    SideMenuTheme:{
      primaryColor:'#ffffff',
      oppositePrimaryColor:'#ffffff',
      secondaryColor:'#5CF0AB',
      oppositeSecondaryColor:'#ffffff',
      paperColor:'#ffffff',
      gloablTextColor:'#ffffff',
      paperTextColor:'#000000',
      bgColor:'#111827',
      bgTextColor:'#ffffff',
      light:false,
      dark:true,
      disabledGlobalText:false,

    },
    GridTheme:{
      primaryColor:'#111827',
      oppositePrimaryColor:'#ffffff',
      secondaryColor:'#0000ff',
      oppositeSecondaryColor:'#ffffff',
      gloablTextColor:'#000000',
      paperColor:'#ffffff',
      paperTextColor:'#000000',
      bgColor:'#F1F5F9',
      bgTextColor:'#000000',
      light:true,
      dark:false,
      disabledGlobalText:false,
    },
}
export const settingsSlice = createSlice({
  name: 'settingsData',
  initialState:initialState,
  reducers: {
    setSideMenuMode:(state,action)=>{
        state.sideMenuMode=action.payload
    },
    setDirections:(state,action)=>{
       state.directions=action.payload
    },
    setNotificationBox:(state,action)=>{
       state.notificationBox=action.payload
    },
    setSideMenuOpen:(state,action)=>{
      state.SideMenuOpen=action.payload
    },
    setMainPaletteShow:(state,action)=>{
      state.MainPaletteShow=action.payload
    },
    setSideMenuPaletteShow:(state,action)=>{
      state.SideMenuPaletteShow=action.payload
    },
    setToolBarPaletteShow:(state,action)=>{
      state.ToolBarPaletteShow=action.payload
    },
    setGridPaletteShow:(state,action)=>{
      state.GridPaletteShow=action.payload
    },
    setMainTheme:(state,action)=>{
         state.MainTheme=action.payload
    },
    setSideMenuTheme:(state,action)=>{
       state.SideMenuTheme=action.payload
    },
    setToolbarTheme:(state,action)=>{
      state.ToolbarTheme=action.payload
    },
    setGridTheme:(state,action)=>{
      state.GridTheme=action.payload
    },
    setDisabledGlobalText:(state,action)=>{
      state.disabledGlobalText=action.payload
    },
    setCurrentSelectedSettingPages:(state,action)=>{
       state.currentSelectedSettingPages=action.payload
    }
    
  },
});

export const {
  setSideMenuMode,
  setDirections,
  setNotificationBox,
  setSideMenuOpen,
  setMainPaletteShow,
  setSideMenuPaletteShow,
  setToolBarPaletteShow,
  setGridPaletteShow,
  setMainTheme,
  setSideMenuTheme,
  setToolbarTheme,
  setGridTheme,
  setDisabledGlobalText,
  setCurrentSelectedSettingPages
} = settingsSlice.actions;

export const sideMenuMode = state => state.settingsData.sideMenuMode;
export const directions = state => state.settingsData.directions;
export const notificationBox= state => state.settingsData.notificationBox;
export const MainTheme=state => state.settingsData.MainTheme;
export const SideMenuTheme=state => state.settingsData.SideMenuTheme;


export default settingsSlice.reducer;
